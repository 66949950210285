// reducers.js

import { FETCH_EMPLOYEE_FAILURE, FETCH_EMPLOYEE_SUCCESS, FETCH_EMPLOYEE_REQUEST, UPDATE_EMPLOYEE } from "./types";



const initialState = {
  data: [],
  loading: false,
  error: ''
};

const EmployeeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };
    case UPDATE_EMPLOYEE:
      return {
        ...state,
        data: action.payload
        // data: action.payload?.merchantId ? state?.data?.map(item => item?.merchantId === action.payload?.merchantId ? action.payload : item) : [...state.data, action.payload]
      };
    case FETCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default EmployeeListReducer;
