import { Col, Row } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../Elements/Button/Button";
import { useTranslation } from "react-i18next";
import { Header } from "../../Components/Header/Header";
import { setCookie } from "../../Utils/storageVariable";
import { useNavigate } from "react-router-dom";

const Plans = () => {
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState(i18n?.language);

  const handleLanguageChange = (language) => {
    setCookie("language", language);
    i18n.changeLanguage(language);
    setLang(language);
  };
  const navigate = useNavigate()
  const [count, setCount] = useState(90);
  const [lenght, setlenght] = useState(1)

  const increment = () => {
    
    setCount(prevCount => prevCount + 70);
    setlenght(prevLenght =>  prevLenght + 1)
  };

  const decrement = () => {
    if(count <= 90) return;
    setCount(prevCount => prevCount - 70);
    setlenght(prevLenght =>  prevLenght - 1)
  };
  const [countMonth, setCountMonth] = useState(99);
  const [lenghtMonth, setlenghtMonth] = useState(1)

  const incrementMonth = () => {
    
    setCountMonth(prevCount => prevCount + 75);
    setlenghtMonth(prevLenght =>  prevLenght + 1)
  };

  const decrementMonth = () => {
    if(countMonth <= 99) return;
    setCountMonth(prevCountMonth => prevCountMonth - 75);
    setlenghtMonth(prevLenghtMonth =>  prevLenghtMonth - 1)
  };
  return (
    <Container isLanguage={i18n.language}>
      <Header
        setLang={setLang}
        handleLanguageChange={handleLanguageChange}
        lang={lang}
        t={t}
      />

      <PlanSection>
        <PlanHeading isLanguage={i18n.language}>
          {t("planContent.planHeading")}
        </PlanHeading>
        <PlanText isLanguage={i18n.language}>
          {t("planContent.planText")}
        </PlanText>
        <PlanMonth isLanguage={i18n.language}>
          {t("planContent.planMonth")}
        </PlanMonth>
        <StyledRow gutter={[16, 16]}>
          <Col xxl={12} xl={12} lg={12} md={24} xs={24} sm={24}>
            <Plan>
              <Wrapper>
                <YearPlan isLanguage={i18n.language}>
                  {t("planContent.yearlyPlanTitle")}
                </YearPlan>
                <BtnWrapperOne>
                  <Button onClick={()=> navigate("/merchant")} isLanguage={i18n.language} primary>
                    {t("planContent.yearlyPlanButton")}
                  </Button>
                </BtnWrapperOne>
              </Wrapper>
              <LargeHeading isLanguage={i18n.language}>
                {/* {t(`planContent.yearlyPlanPrice`).slice(0, 3)}{" "} */}
                {count}
                <Small isLanguage={i18n.language}>
                  {t(`planContent.yearlyPlanPrice`).slice(3)}
                </Small>
              </LargeHeading>
              <AdditionalStaff isLanguage={i18n.language}>
                <Image src="./Assets/Vector.png"  />{" "}
                {t("planContent.yearlyPlanStaff")}
                <IncreaseButtonWrapper>
                <AddButton  onClick={()=> increment()}>+</AddButton>
                <AddButton>{lenght}</AddButton>
                <AddButton isMargin  onClick={()=> decrement()}>-</AddButton>

              </IncreaseButtonWrapper>
              </AdditionalStaff>
             
              <Ul>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />
                  {t("planContent.yearlyPlanFeaturesOne")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.yearlyPlanFeaturesTwo")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.internationPaymentSupport")}
                  
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.yearlyPlanFeaturesThree")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.yearlyPlanFeaturesFour")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.yearlyPlanFeaturesFive")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />
                  {t("planContent.yearlyPlanFeaturesSix")}
                </Li>
              </Ul>
            </Plan>
          </Col>
          <Col xxl={12} xl={12} lg={12} md={24} xs={24} sm={24}>
            <Plan>
              <Wrapper>
                <YearPlan isLanguage={i18n.language}>
                  {t("planContent.monthlyPlanTitle")}
                </YearPlan>
                <BtnWrapperOne>
                  <Button onClick={()=> navigate("/merchant")} isLanguage={i18n.language} primary>
                    {t("planContent.monthlyPlanButton")}
                  </Button>
                </BtnWrapperOne>
              </Wrapper>
              <LargeHeading isLanguage={i18n.language}>
                {countMonth}
                <Small isLanguage={i18n.language}>
                  {t(`planContent.monthlyPlanPrice`).slice(3)}
                </Small>
              </LargeHeading>
              <AdditionalStaff isLanguage={i18n.language}>
                <Image src="./Assets/Vector.png" />
                {t("planContent.monthlyPlanStaff")}

                <IncreaseButtonWrapper>
                <AddButton  onClick={()=> incrementMonth()}>+</AddButton>
                <AddButton>{lenghtMonth}</AddButton>
                <AddButton isMargin  onClick={()=> decrementMonth()}>-</AddButton>

              </IncreaseButtonWrapper>
              </AdditionalStaff>

              <Ul>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />
                  {t("planContent.monthlyPlanFeaturesOne")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.monthlyPlanFeaturesTwo")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.internationPaymentSupport")}
                  
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />
                  {t("planContent.monthlyPlanFeaturesThree")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />
                  {t("planContent.monthlyPlanFeaturesFour")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />{" "}
                  {t("planContent.monthlyPlanFeaturesFive")}
                </Li>
                <Li isLanguage={i18n.language}>
                  <Image src="./Assets/tick.svg" />
                  {t("planContent.monthlyPlanFeaturesSix")}
                </Li>
              </Ul>
            </Plan>
          </Col>
        </StyledRow>
      </PlanSection>
    </Container>
  );
};

export default Plans;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  max-height: fit-content;
  background-color: #fff9f5;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
`;

const Image = styled.img`
  height: ${({ isHeight }) => isHeight && "48px"};
`;

const PlanSection = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  max-height: fit-content;
  padding-top: 8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const PlanHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #1d2939;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  
`;
const PlanText = styled.p`
  margin: 0;
  padding: 0;
  color: #475467;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const PlanMonth = styled.p`
  margin-top: 3rem;
  color: #c44851;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  line-height: 42px;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    margin-top: 1rem;
    padding: 1rem 1.5rem;
    text-align: center;
  }
`;
const Plan = styled.div`
  width: 100%;
  border: 1px solid #d0d5dd;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 16px;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const BtnWrapperOne = styled.div`
  width: 100px;
`;
const YearPlan = styled.h1`
  margin: 0;
  padding: 0;
  color: #c44851;
  font-size: 24px;
  letter-spacing: 4%;
  line-height: 32px;
  font-weight: 700;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const LargeHeading = styled.h2`
  font-size: 48px;
  color: #1d2939;
  font-weight: 700;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const Small = styled.small`
  font-size: 16px;
  color: #1d2939;
  font-weight: 400;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const Ul = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;
const Li = styled.li`
  font-size: #101828;
  align-items: center;
  display: flex;
  gap: 0.4rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;

const AdditionalStaff = styled.p`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  color: #1d2939;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  font-weight: 400;
  font-size: 16px;
  line-height: 62px;
  border-bottom: 1px solid #d0d5dd;
  @media (max-width: 500px) {
   gap: 0.5rem;
    padding-bottom: 1rem;
  }
`;
const StyledRow = styled(Row)`
  width: 80%;

  @media (max-width: 991px) {
    width: 95%;
    margin-bottom: 2rem;
  }
`;


const IncreaseButtonWrapper = styled.div`
display: flex;
gap: 1rem;
border: 1px solid  #d0d5dd;
padding: 4px 6px;
border-radius: 6px;
background-color: #F55A65;

@media (max-width:600px) {
   padding: 2px 4px;
   gap: 0.5rem;
    
  }
`

const AddButton = styled.button` 
  width :30px;
  height: 30px;
  font-size: 30px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  color: #FFFF;

  @media (max-width:600px) {
    font-size: 20px;
    width: 25px;
    height: 25px;
    
  }

  padding-bottom: ${({isMargin})=>  isMargin && "0.3rem"};
  border: none;
  outline: none;
  font-weight: 700;
  border-radius: 4px;
  background-color: transparent;

`