import React, { useCallback, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CustomInput from "../../Elements/Input/Input";
import Button from "../../Elements/Button/Button";
import { Upload, Collapse, Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { serviceDetailSchema } from "../../Schemas";
import { useDispatch, useSelector } from "react-redux";
import { addMerchant } from "../../Redux/Add-Merchant/action";
import _ from "lodash";
import Loading from "../../Components/Loader/Loading";
import { deleteProduct } from "../../Redux/Delete-Product/action";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../config";
import { useTranslation } from "react-i18next";
import { extractFilenameFromURL } from "../../Utils/constants";
import { ReactComponent as UploadIcon } from "./upload.svg";
import { ReactComponent as DeleteOutlined } from "./trash.svg";
import { addProduct } from "../../Redux/Add-Product/action";
import { useLocation } from "react-router-dom";
import SelectInput from "../../Components/SelectInput/SelectInput";
import { v4 as uuidv4 } from "uuid";
import { Error } from "../../Responses/Responses";
import { FormDataContext } from "../../App";
import { BaseService } from "../../Api/base";
import { uploadImages } from "../../controllers/ImageController";

const ServiceDetails = ({
  isUpdate = false,
  nextStep = () => {},
  prevStep = () => {},
  refetch,
  fetchServices,
}) => {
  const { t, i18n } = useTranslation();
  const [panelsWithErrors, setPanelsWithErrors] = useState();
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const location = useLocation();
  const [imagesData, setImagesData] = useState([]);
  const dispatch = useDispatch();
  //const products = useSelector((state) => state?.ProductReducer?.products);

  const { data, setData } = useContext(FormDataContext);

  useEffect(() => {
    if (
      !data.merchantAdmin ||
      (isUpdate && !data.merchant) ||
      (data &&
        data.merchantAdmin &&
        data.merchantAdmin.merchantId &&
        !data.merchant)
    )
      refetch();
    if (
      (isUpdate && !data.services) ||
      (data && data.merchantAdmin && data.merchant && !data.services)
    )
      fetchServices();
  }, [isUpdate]);

  useEffect(() => {
    if (!data.categories) {
      BaseService.get("merchantAdmins/categories").then((response) => {
        const { data } = response;
        setData((prev) => ({ ...prev, categories: data.data.categories }));
      });
    }
  }, []);

  // const getMerchantLoading = useSelector((state) => state?.GetMerchantReducer?.loading);

  // const categoriesLoading = useSelector(
  //   (state) => state?.categoryReducer?.loading
  // );
  //const data = useSelector((state) => state?.GetMerchantReducer?.data);
  const productLoading = useSelector((state) => state?.ProductReducer?.loading);
  const loading = useSelector((state) => state?.GetMerchantReducer?.loading);
  const addProductLoading = useSelector(
    (state) => state?.addProductsSReducer?.loading
  );
  const addLoading = useSelector((state) => state?.AddMerchantReducer?.loading);

  const initialValues = {
    targetCustomers: [],
    visibility: [],
    portfolioImages: [],
    productList: [
      {
        categoryId: "",
        description: "",
        descriptionAr: "",
        name: "",
        nameAr: "",
        timeInMinutes: null,
        cost: null,
        isNew: true,
        id: uuidv4(),
      },
    ],
    customerNotes: [{ body: "", title: "" }],
  };

  useEffect(() => {
    // dispatch(fetchProducts());
    // dispatch(fetchCategories());
  }, []);

  const handleUpload = async (file, type) => {
    if (!file || !file[0] || !file[0].name) {
      return;
    }

    setUploadFileLoading(true);

    try {
      const response = await uploadImages(file, type);
      setUploadFileLoading(false);
      setValues((prevValues) => ({
        ...prevValues,
        portfolioImages: Array.isArray(prevValues.portfolioImages)
          ? prevValues.portfolioImages.concat(response.records[0])
          : [response.records[0]],
      }));
     
    } catch (e) {
      Error("Error uploading file");
      setUploadFileLoading(false);
    }
  };

  const handleChangeImage = ({ fileList }) => {
    const filteredFiles = fileList.filter((file) => "lastModified" in file);
    handleUpload([filteredFiles[0].originFileObj], "IMAGE");
  };

  const handleAddPanel = () => {
    const newPanel = {
      categoryId: "",
      description: "",
      descriptionAr: "",
      name: "",
      nameAr: "",
      timeInMinutes: null,
      cost: null,
      isNew: true,
      id: uuidv4(),
    };
    setValues((prevValues) => ({
      ...prevValues,
      productList: prevValues.productList
        ? [...prevValues.productList, newPanel]
        : [newPanel],
    }));
    setPanelsWithErrors(values.productList ? values.productList.length : 0);
  };

  const handleDeletePanel = (item, index) => {
    if (item?.isNew) {
      const updatedPanels = values?.productList.filter(
        (panel, i) => i !== index
      );
      setValues((prevValues) => ({
        ...prevValues,
        productList: updatedPanels,
      }));
    } else {
      dispatch(deleteProduct(item.id));
    }
  };

  const {
    errors,
    setValues,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: serviceDetailSchema,

    onSubmit: (action) => {
      const { productList, customerNotes, ...newValues } = values;

      const path = location.pathname;

      return dispatch(
        addMerchant(
          { ...newValues, customerNotes: values?.customerNotes, productList },
          nextStep,
          path,
          refetch,
          fetchServices
        )
      );
    },
  });

  const handleChangeCheckbox = (key, value, isArray) => {
    if (!isArray)
      return setValues({
        ...values,
        [key]: value,
      });
    if (!values?.[key]?.includes(value))
      return setValues({
        ...values,
        [key]: [...values?.[key], value],
      });
    else if (values?.[key]?.includes(value))
      return setValues({
        ...values,
        [key]: values?.[key]?.filter((item) => item !== value),
      });
  };

  const handleRemoveImage = (file) => {
    if (!file || !file?.name) {
      console.error("Invalid file object or file name.");
      return;
    }

    const updatedImageUrls = values?.portfolioImages?.filter((image) => {
      const name = extractFilenameFromURL(image.url);
      return name !== file?.name;
    });

    setValues((prevValues) => ({
      ...prevValues,
      portfolioImages: updatedImageUrls || [],
    }));
  };

  const handleChangePanel = (name, value, fields) => {
    if (fields?.id) {
      setValues({
        ...values,
        productList: values?.productList?.map((item) => {
          if (fields?.id === item?.id) return { ...fields, [name]: value };
          return item;
        }),
      });
      if (!fields?.isNew) return updateProduct({ ...fields, [name]: value });
    }
  };
  useEffect(() => {
    if (values?.portfolioImages) {
      const newFileList = values?.portfolioImages?.map((image) => ({
        name: image && image.url && extractFilenameFromURL(image.url),
      }));
      setImagesData(newFileList);
    }
  }, [values?.portfolioImages]);

  useEffect(() => {
    const path = location.pathname;

    if (path.startsWith("/dashboard")) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setValues({
      targetCustomers: data.merchant?.targetCustomers || [],
      visibility: data.merchant?.visibility || [],
      portfolioImages: data.merchant?.portfolioImages || [],
      productList: data.services,
      customerNotes: data.merchant?.customerNotes?.map((item) => ({
        body: item?.body,
        title: item?.title,
      })),
    });
  }, [data]);

  const updateProduct = useCallback(
    _.debounce((fields) => {
      dispatch(addProduct(fields));
    }, 1000),
    []
  );

  const handleDeleteNotes = (item, index) => {
    if (!item.id) {
      const updatedPanels = values?.customerNotes?.filter(
        (panel, i) => i !== index
      );

      setValues((prevValues) => ({
        ...prevValues,
        customerNotes: updatedPanels,
      }));
    } else {
    }
  };

  const handleAddNotes = () => {
    const newNotes = { body: "", title: "" };
    setValues((prevValues) => ({
      ...prevValues,
      customerNotes: prevValues?.customerNotes
        ? [...prevValues?.customerNotes, newNotes]
        : [newNotes],
    }));
  };
  const handleChangeNotes = (name, value, fields, index) => {
    setValues({
      ...values,
      customerNotes: values?.customerNotes?.map((item, i) => {
        if (index === i) return { ...fields, [name]: value };
        return item;
      }),
    });
  };

  return (
    <>
      {loading || productLoading || uploadFileLoading ? <Loading /> : null}

      <Container isLanguage={i18n.language}>
        <Form isDashboard={isDashboard} onSubmit={handleSubmit}>
          <Heading isLanguage={i18n.language}>
            {t("serviceDetails.heading")}
          </Heading>
          <Text isLanguage={i18n.language}>
            {t("serviceDetails.description")}
          </Text>
          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("serviceDetails.targetedCustomersLabel")}
            </Label>
            <RadioWrapper isLanguage={i18n.language}>
              <Checkbox
                onChange={() =>
                  handleChangeCheckbox("targetCustomers", "women", true)
                }
                checked={values?.targetCustomers?.includes("women")}
                onBlur={handleBlur}
                name="women"
              >
                <CheckText isLanguage={i18n.language}>
                  {t("serviceDetails.womenOption")}
                </CheckText>
              </Checkbox>
              <Checkbox
                onChange={() =>
                  handleChangeCheckbox("targetCustomers", "men", true)
                }
                checked={values?.targetCustomers?.includes("men")}
                onBlur={handleBlur}
                name="men"
              >
                <CheckText isLanguage={i18n.language}>
                  {t("serviceDetails.menOption")}
                </CheckText>
              </Checkbox>
            </RadioWrapper>
            {touched.targetCustomers && !values.targetCustomers && (
              <Span>{errors.targetCustomers}</Span>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("serviceDetails.whereToFindLabel")}
            </Label>
            <RadioWrapper isLanguage={i18n.language}>
              <Checkbox
                onChange={() =>
                  handleChangeCheckbox("visibility", "home", true)
                }
                name="home"
                checked={values?.visibility?.includes("home")}
                onBlur={handleBlur}
              >
                <CheckText isLanguage={i18n.language}>
                  {t("serviceDetails.atHomeOption")}
                </CheckText>
              </Checkbox>
              <Checkbox
                name="store"
                onChange={() =>
                  handleChangeCheckbox("visibility", "store", true)
                }
                checked={values?.visibility?.includes("store")}
                onBlur={handleBlur}
              >
                <CheckText isLanguage={i18n.language}>
                  {t("serviceDetails.atStoreOption")}
                </CheckText>
              </Checkbox>
            </RadioWrapper>
            {touched.visibility && !values.visibility && (
              <Span>{errors.visibility}</Span>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("serviceDetails.businessImagesLabel")}
            </Label>

            <Upload
              fileList={imagesData}
              onChange={(e, value) => {
                handleChangeImage(e, value);
              }}
              style={{ width: "100%" }}
              name="portfolioImages"
              accept=".png,.jpeg"
              beforeUpload={() => false}
              onRemove={(file) => handleRemoveImage(file)}
            >
              <BtnWrapper>
                <UploadIcon />
                <Text isLanguage={i18n.language}>
                  {t("serviceDetails.selectImagesText")}
                </Text>
              </BtnWrapper>
            </Upload>
            {errors.portfolioImages && touched.portfolioImages && (
              <Span>{errors.portfolioImages}</Span>
            )}
            <InputWrapper>
              <Label isLanguage={i18n.language}>
                {t("serviceDetails.customerNotes")}
              </Label>
              <WrapperCollapse>
                <Collapse
                  bordered={false}
                  style={{ border: "1px solid #D0D5DD" }}
                  accordion
                >
                  {values?.customerNotes?.map((panel, index) => (
                    <Collapse.Panel
                      key={index}
                      header={
                        <PanelHeader>
                          <LabelOne isLanguage={i18n.language}>
                            {t("serviceDetails.notes")} {index + 1}
                          </LabelOne>{" "}
                          <DeleteOutlined
                            style={{ color: "#F55A65", fontSize: "20px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteNotes(panel, index);
                            }}
                          />
                        </PanelHeader>
                      }
                    >
                      <CustomInput
                        name={`customerNotes[${index}].title`}
                        value={panel?.title}
                        placeholder={t("serviceDetails.titlePlacholder")}
                        onChange={(e) =>
                          handleChangeNotes(
                            "title",
                            e.target.value,
                            panel,
                            index
                          )
                        }
                        onBlur={handleBlur}
                      />
                      {errors?.customerNotes?.[index]?.title &&
                        touched?.customerNotes?.[index]?.title && (
                          <Span>{errors.customerNotes[index].title}</Span>
                        )}
                      <InputWrapper>
                        <TextArea
                          name={`customerNotes[${index}].body`}
                          value={panel?.body}
                          placeholder={t("serviceDetails.bodyPlacholder")}
                          onChange={(e) =>
                            handleChangeNotes(
                              "body",
                              e.target.value,
                              panel,
                              index
                            )
                          }
                          onBlur={handleBlur}
                        ></TextArea>
                        {errors?.customerNotes?.[index]?.body &&
                          touched?.customerNotes?.[index]?.body && (
                            <Span>{errors.customerNotes[index].body}</Span>
                          )}
                      </InputWrapper>
                    </Collapse.Panel>
                  ))}
                </Collapse>

                <ButtonWrapper isPadding>
                  <CustomButton
                    isLanguage={i18n.language}
                    onClick={handleAddNotes}
                  >
                    {" "}
                    <PlusOutlined />
                    {t("serviceDetails.addAnotherNotes")}
                  </CustomButton>
                </ButtonWrapper>
              </WrapperCollapse>
            </InputWrapper>
            <InputWrapper>
              <Label isLanguage={i18n.language}>
                {t("serviceDetails.providedServicesLabel")}
              </Label>
            </InputWrapper>
            <WrapperCollapse>
              <Collapse
                bordered={false}
                style={{ border: "1px solid #D0D5DD" }}
                accordion
                activeKey={panelsWithErrors}
                onChange={(value) => setPanelsWithErrors(value)}
              >
                {values?.productList?.map((panel, index) => (
                  <Collapse.Panel
                    key={panel.key}
                    header={
                      <PanelHeader>
                        <LabelOne isLanguage={i18n.language}>
                          {t("serviceDetails.text")} {index + 1}
                        </LabelOne>{" "}
                        <DeleteOutlined
                          style={{ color: "#F55A65", fontSize: "20px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeletePanel(panel, index);
                          }}
                        />
                      </PanelHeader>
                    }
                  >
                    <InputWrapper>
                      <SelectInput
                        targetCustomer={values?.targetCustomers}
                        name={`productList[${index}].categoryId`}
                        value={data.categories?.find(
                          (cat) => cat?.id === panel?.categoryId
                        )}
                        handleCheckboxChange={(categoryId) =>
                          handleChangePanel("categoryId", categoryId, panel)
                        }
                        placeholder={t("serviceDetails.category")}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <CustomInput
                        name={`productList[${index}].name`}
                        onChange={(e) =>
                          handleChangePanel("name", e.target.value, panel)
                        }
                        onBlur={handleBlur}
                        value={panel?.name}
                        placeholder={t("serviceDetails.serviceNameEn")}
                      />
                      {errors?.productList?.[index]?.name &&
                        touched?.productList?.[index]?.name && (
                          <Span>{errors.productList[index].name}</Span>
                        )}
                    </InputWrapper>
                    <InputWrapper isDirection>
                      <CustomInput
                        name={`productList[${index}].nameAr`}
                        onChange={(e) =>
                          handleChangePanel("nameAr", e.target.value, panel)
                        }
                        onBlur={handleBlur}
                        value={panel?.nameAr}
                        placeholder={t("serviceDetails.serviceNameAr")}
                      />
                      {errors?.productList?.[index]?.nameAr &&
                        touched?.productList?.[index]?.nameAr && (
                          <Span>{errors.productList[index].nameAr}</Span>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                      <CustomInput
                        name={`productList[${index}].description`}
                        onChange={(e) =>
                          handleChangePanel(
                            "description",
                            e.target.value,
                            panel
                          )
                        }
                        onBlur={handleBlur}
                        value={panel?.description}
                        placeholder={t("serviceDetails.descriptionEn")}
                      />
                      {errors?.productList?.[index]?.description &&
                        touched?.productList?.[index]?.description && (
                          <Span>{errors.productList[index].description}</Span>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                      <CustomInput
                        name={`productList[${index}].descriptionAr`}
                        onChange={(e) =>
                          handleChangePanel(
                            "descriptionAr",
                            e.target.value,
                            panel
                          )
                        }
                        onBlur={handleBlur}
                        value={panel?.descriptionAr}
                        placeholder={t("serviceDetails.descriptionAr")}
                      />
                      {errors?.productList?.[index]?.descriptionAr &&
                        touched?.productList?.[index]?.descriptionAr && (
                          <Span>{errors.productList[index].descriptionAr}</Span>
                        )}
                    </InputWrapper>

                    <InputWrapper>
                      <CustomInput
                        type={"number"}
                        name={`productList[${index}].timeInMinutes`}
                        onChange={(e) =>
                          handleChangePanel(
                            "timeInMinutes",
                            +e.target.value,
                            panel
                          )
                        }
                        onBlur={handleBlur}
                        value={panel?.timeInMinutes}
                        placeholder={t("serviceDetails.timeInMinutes")}
                      />
                      {errors?.productList?.[index]?.timeInMinutes &&
                        touched?.productList?.[index]?.timeInMinutes && (
                          <Span>{errors.productList[index].timeInMinutes}</Span>
                        )}
                    </InputWrapper>
                    <InputWrapper>
                      <CustomInput
                        type={"number"}
                        name={`productList[${index}].cost`}
                        onChange={(e) =>
                          handleChangePanel("cost", +e.target.value, panel)
                        }
                        onBlur={handleBlur}
                        value={panel?.cost}
                        placeholder={t("serviceDetails.serviceCost")}
                      />
                      {errors?.productList?.[index]?.cost &&
                        touched?.productList?.[index]?.cost && (
                          <Span>{errors.productList[index].cost}</Span>
                        )}
                    </InputWrapper>
                  </Collapse.Panel>
                ))}
              </Collapse>

              <ButtonWrapper>
                <CustomButton
                  isLanguage={i18n.language}
                  onClick={handleAddPanel}
                >
                  {" "}
                  <PlusOutlined />
                  {t("serviceDetails.addAnotherServiceButtonText")}
                </CustomButton>
              </ButtonWrapper>
            </WrapperCollapse>
          </InputWrapper>
          {isUpdate === false ? (
            <ButtonWrapper>
              <Button isLanguage={i18n.language} onClick={prevStep}>
                {t("serviceDetails.previousButtonText")}
              </Button>
              <Button
                disabled={addProductLoading || addLoading}
                loading={addProductLoading || addLoading}
                isLanguage={i18n.language}
                primary
                type={"submit"}
              >
                {t("serviceDetails.continueButtonText")}
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper isWidth>
              <Button
                disabled={addProductLoading || addLoading}
                loading={addProductLoading || addLoading}
                isLanguage={i18n.language}
                primary
                onClick={handleSubmit}
              >
                {t("Update")}
              </Button>
            </ButtonWrapper>
          )}
        </Form>
      </Container>
    </>
  );
};

export default ServiceDetails;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  @media (max-width: 820px) {
    padding: 0;
  }
  .ant-upload-select {
    width: 100% !important;
  }
`;
const Form = styled.form`
  min-width: 524px;
  max-width: 50%;
  background-color: #ffffff;
  padding: 2rem;
  border: 1px solid hsla(220, 17%, 93%, 1);
  border-radius: 12px;
  max-height: auto;
  margin-bottom: ${({ isDashboard }) => (isDashboard ? "" : "6rem")};

  @media (max-width: 820px) {
    padding: 1rem;
    max-height: max-content;
    margin-bottom: 4rem;
  }
  @media (max-width: 600px) {
    min-width: 90%;
    padding: 1rem;
    max-height: max-content;
    margin-bottom: ${({ isDashboard }) => (isDashboard ? "1rem" : "10rem")};
    margin-top: ${({ isDashboard }) => isDashboard && "3rem"};
  }
`;
const Heading = styled.h2`
  line-height: 26px;
  font-size: 20px;
  font-weight: Weight;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const InputWrapper = styled.div`
  margin-top: 1.5rem;
  direction: ${({ isDirection }) => isDirection && "rtl"};

  .ant-upload-list-item-container {
    transition: none !important;
  }
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: hsla(216, 18%, 34%, 1);
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  line-height: 20px;
`;
const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  width: ${({ isWidth }) => (isWidth ? "40%" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 1rem;
  gap: 15rem;
  @media (max-width: 820px) {
    gap: 13rem;
  }
  @media (max-width: 600px) {
    gap: 36%;
  }
`;
const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  display: block;
`;

const RadioWrapper = styled.div`
  margin-top: 0.5rem;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "ltr"};

  .ant-checkbox-inner:after {
    border-color: #f55a65 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #f55a65 !important;
    background-color: transparent !important;
  }
  span {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
`;
const BtnWrapper = styled.div`
  width: 460px;
  height: 120px;
  border-radius: 6px;
  border: 2px dotted #98a2b3;
  background-color: #f9fafb;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.4rem;
  @media (max-width: 820px) {
    width: 460px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const CustomButton = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  color: #f55a65;
`;
const WrapperCollapse = styled.div`
  margin-top: 1rem;
`;
const CheckText = styled.p`
  font-size: 16px;
  color: #1d2939;
  font-weight: 400;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  line-height: 24px;
`;

const Span = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const LabelOne = styled.label`
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  color: #475467;
`;
const TextArea = styled.textarea`
  width: 100%;
  height: 134px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 1rem;
  border-radius: 12px;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  color: #344054;

  &::placeholder {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
    color: #bfbfbf;
  }
`;
