import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../Pages/Home/Home";
import Signup from "../Pages/Signup/Signup";
import Success from "../Pages/Success/Success";
import Plans from "../Pages/Plans/Plans";
import Verification from "../Pages/Verification/Verification";
import { useProtectedRoutes } from "../Components/ProtectedComponent/ProtectedComponent";
import Dashboard from "../Pages/Dasboard/Dashboard";
import PersonalInfo from "../Pages/Mercant/PersonalInfo";
import MerchantDetails from "../Pages/Mercant/MerchantDetails";
import ServiceDetails from "../Pages/Mercant/ServiceDetails";
import BankDetails from "../Pages/Mercant/BankDetails";
import BusinessSchedule from "../Pages/Mercant/BussinessSchedules";
import Ads from "../Pages/Dasboard/Ads/Ads";
import TeamDetail from "../Pages/Dasboard/TeamDetail/TeamDetail";
import NotFound from "../Pages/NotFound/NotFound";
import Merchant from "../Pages/Mercant/Merchant";
import { FormDataContext } from "../App";
import { BaseService } from "../Api/base";

const AppRoutes = () => {
  const { ProtectedRoute, UnprotectedRoute } = useProtectedRoutes();
  const { setData } = useContext(FormDataContext);

  const fetch = async () => {
    BaseService.get("/merchantAdmins/merchants").then((response) => {
      const { data } = response;
      const merchantAdmin = data.data.merchantAdmin;
      const merchant = data.data.merchant;
      setData((prev) => ({
        ...prev,
        merchantAdmin,
        merchant,
      }));
    });
  };

  const fetchTimeSlots = async () => {
    BaseService.get("/employees/timeSlots").then((response) => {
      const { data } = response;
      const businessHours = data.data.businessHours;
      setData((prev) => ({
        ...prev,
        businessHours,
      }));
    });
  };

  const fetchServices = async () => {
    BaseService.get("/merchantAdmins/services").then((response) => {
      const { data } = response;
      const services = data.data.services;
      setData((prev) => ({
        ...prev,
        services,
      }));
    });
  };

  const fetchEmployees = async () => {
    BaseService.get("/merchantAdmins/employees").then((response) => {
      const { data } = response;
      const employees = data.data.employees;
      setData((prev) => ({
        ...prev,
        employees,
      }));
    });
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/auth"
          element={
            <UnprotectedRoute redirectPath="/dashboard">
              <Signup />
            </UnprotectedRoute>
          }
        />

        <Route
          path="/verify"
          element={
            <UnprotectedRoute redirectPath="/auth">
              <Verification />
            </UnprotectedRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <ProtectedRoute redirectPath="/auth">
              <Plans />
            </ProtectedRoute>
          }
        />

        <Route
          path="/success"
          element={
            <ProtectedRoute redirectPath="/auth">
              <Success />
            </ProtectedRoute>
          }
        />
        <Route
          path="/merchant"
          element={
            <ProtectedRoute redirectPath="/auth">
              <Merchant
                refetch={fetch}
                fetchTimeSlots={fetchTimeSlots}
                fetchServices={fetchServices}
                fetchEmployees={fetchEmployees}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute redirectPath="/auth">
              <Dashboard />
            </ProtectedRoute>
          }
        >
          <Route
            index
            path="/dashboard/personal-Info"
            element={<PersonalInfo isUpdate={true} refetch={fetch} />}
          />
          <Route
            path="/dashboard/merchant-detail"
            element={<MerchantDetails isUpdate={true} refetch={fetch} />}
          />
          <Route
            path="/dashboard/service-detail"
            element={
              <ServiceDetails
                isUpdate={true}
                refetch={fetch}
                fetchServices={fetchServices}
              />
            }
          />
          <Route
            path="/dashboard/bank-detail"
            element={<BankDetails isUpdate={true} refetch={fetch}/>}
          />
          <Route
            path="/dashboard/team-detail"
            element={<TeamDetail isUpdate={true} fetchEmployees={fetchEmployees}/>}
          />
          <Route
            path="/dashboard/business-detail"
            element={
              <BusinessSchedule
                isUpdate={true}
                fetchTimeSlots={fetchTimeSlots}
              />
            }
          />
          <Route index path="/dashboard/ads" element={<Ads />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
