import React from "react";
import { Oval } from "react-loader-spinner";
import styled from "styled-components";

const Button = ({
  primary,
  children,
  type,
  loading = false,
  isLanguage,
  ...rest
}) => {
  return (
    <Wrapper>
      <CustomButton
        type={type}
        isLanguage={isLanguage}
        {...rest}
        primary={primary}
      >
        {loading && primary ? (
          <Oval
            secondaryColor="#00000083"
            color="#FFF"
            height={20}
            width={20}
          />
        ) : (
          children
        )}
      </CustomButton>
    </Wrapper>
  );
};

export default Button;

const Wrapper = styled.div`
  width: 100%;
`;
const CustomButton = styled.button`
  width: 100%;
  cursor: pointer;
  background-color: ${({ primary }) => (primary ? "#F55A65" : "transparent")};
  border: ${({ primary }) => (primary ? "none" : "1px solid #D0D5DD")};
  outline: none;
  padding: 12px 0;
  border-radius: 12px;
  font-size: ${({ isLanguage }) => (isLanguage ? "16px" : "16px")} !important;
  color: ${({ primary }) => (primary ? "#FFFFFF" : "#344054")};
  font-weight: 400;
  min-width: 96px;
  min-height: 48px;
  line-height: 24px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.8;
  }
`;
