import { useState, useEffect, useRef } from "react";

export const services = [
    {
        img: "./Assets/service1.png",
        disc: "Rejuvenate your mind, body, and soul with our curated wellness experiences.",
        name: "Wellness Services",
    },
    {
        img: "./Assets/service2.png",
        disc: "Step into the world of precision grooming where classic meets modern.",
        name: "Barber Services",
    },
    {
        img: "./Assets/service3.png",
        disc: "Unveil your natural beauty with our comprehensive beauty care services.",
        name: "Beauty Care",
    },
    {
        img: "./Assets/serivce4.png",
        disc: "Escape the hustle and bustle of daily life and immerse yourself in the tranquility of our spa.",
        name: "Spa Services",
    },
    {
        img: "./Assets/service1.png",
        disc: "With our extensive network of partner restaurants, you have access to a wide range of ",
        name: "Resturant Services",
    },
    {
        img: "./Assets/serivce4.png",
        disc: "Escape the hustle and bustle of daily life and immerse yourself in the tranquility of our spa.",
        name: "Spa Services",
    },
    {
        img: "./Assets/service1.png",
        disc: "With our extensive network of partner restaurants, you have access to a wide range of ",
        name: "Resturant Services",
    },
];
export const customer = [
    {
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis, magna at pulvinar bibendum, ante purus maximus mauris, non consectetur justo tellus at metus.",
        name: "Robert Martin",
    },
    {
        disc: "Pellentesque eget posuere enim. Nunc eget lectus ut massa mattis vestibulum eu vel ex. Cras iaculis lacinia lacus, ut imperdiet odio iaculis vel. Maecenas et ex eget arcu vestibulum gravida",
        name: "John Martin",
    },
    {
        disc: "Vivamus vulputate commodo ligula, non congue justo laoreet eu. Etiam finibus dui at erat aliquet iaculis. Donec laoreet vitae purus quis gravida. Quisque sollicitudin neque in nisi laoreet",
        name: "Beauty Care",
    },
    {
        disc: "Vivamus vulputate commodo ligula, non congue justo laoreet eu. Etiam finibus dui at erat aliquet iaculis. Donec laoreet vitae purus quis gravida. Quisque sollicitudin neque in nisi laoreet",
        name: "Nina Anderson",
    },
    {
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis, magna at pulvinar bibendum, ante purus maximus mauris, non consectetur justo tellus at metus. ",
        name: "Beauty Care",
    },
    {
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis, magna at pulvinar bibendum, ante purus maximus mauris, non consectetur justo tellus at metus. ",
        name: "Nina Anderson",
    },
    {
        disc: "Pellentesque eget posuere enim. Nunc eget lectus ut massa mattis vestibulum eu vel ex. Cras iaculis lacinia lacus, ut imperdiet odio iaculis vel. Maecenas et ex eget arcu vestibulum gravida",
        name: "John Martin",
    },
];

export const useCarousel = (items, visibleItems = 1) => {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const scrollRef = useRef(null);
  let intervalId;

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollIndex * scrollRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
  }, [scrollIndex]);

  

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const scrollLeft = scrollRef.current.scrollLeft;
        const itemWidth = scrollRef.current.offsetWidth / visibleItems;
        const newScrollIndex = Math.round(scrollLeft / itemWidth);
        setActivePageIndex(newScrollIndex);
      }
    };
  
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }
  
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [scrollRef, visibleItems]);

  const next = () => {
    const totalItems = items.length;
    if (scrollIndex < totalItems - visibleItems) {
      setScrollIndex(scrollIndex + 1);
    } else {
      setScrollIndex(0);
    }
  };
 

  useEffect(() => {
    intervalId = setInterval(() => {
      next();
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [scrollIndex]);
  
  return { scrollRef, activePageIndex };
};

export const extractFilenameFromURL = (url) => {
  const parts = url?.split('/');
  const filenameWithToken = parts[parts?.length - 1];
  const decodedFilename = decodeURIComponent(filenameWithToken);
  const filenameParts = decodedFilename?.split('?');
  return filenameParts[0].split('/').pop();
}
export const REACT_APP_GOOGLE_MAPS_KEY = "  https://www.google.com/maps/embed/v1/MAP_MODE?key=AIzaSyDNvS3kyN5fCVCHeNJ_qrtq6opceWCtAKE&parameters";


  
