// reducers.js

import { ADD_PRODUCT_FAILURE, ADD_PRODUCT_SUCCESS, ADD_PRODUCT_REQUEST } from "./types";



const initialState = {
  data: null,
  loading: false,
  error: ''
};

const addProductSReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default addProductSReducer;
