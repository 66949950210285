import { BaseService } from '../../Api/base';
import { Error } from '../../Responses/Responses';
import { FETCH_MERCHANT_FAILURE, FETCH_MERCHANT_REQUEST, FETCH_MERCHANT_SUCCESS, UPDATE_MERCHANT } from './types';

export const fetchMerchant = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_MERCHANT_REQUEST });
      const { data } = await BaseService.get("/merchants");

      dispatch({ type: FETCH_MERCHANT_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message)
      dispatch({ type: FETCH_MERCHANT_FAILURE, payload: error.message });
    }
  };
};

export const updateMerchant = (fields) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_MERCHANT, payload: fields });
  };
};