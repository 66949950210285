import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const CustomInput = ({
  onChange,
  placeholder,
  type,
  label,
  error,
  onBlur,
  name,
  value,
  disabled,
  isDirection,
  isArrow,
  handleArrowClick,
  ...rest
}) => {
  const { i18n } = useTranslation();

  return (
    <InputWrapper>
      <Label isLanguage={i18n.language}>{label}</Label>
      {!isArrow ? (
        <StyledInput
          isDirection={isDirection}
          isLanguage={i18n.language}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          allowClear
          name={name}
          isBorder={error}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
      ) : (
        ""
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

export default CustomInput;

const InputWrapper = styled.div`
  width: 100%;
`;

const StyledInput = styled(Input)`
  direction: ${({ isLanguage, isDirection }) =>
    isLanguage === "ar" || (isDirection && "rtl")};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 14px;
  outline: none;
  border: 1px solid ${(props) => (props.isBorder ? "red" : "#D0D5DD")};
  font-family: ${({ isLanguage, isDirection }) =>
    isLanguage === "ar" || isDirection
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  border-radius: 8px;
  margin-top: 0.4rem;
  color: #344054;
  width: 100%;
  .ant-input {
    font-family: ${({ isLanguage, isDirection }) =>
      isLanguage === "ar" || isDirection
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"};
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-input-error-icon {
    display: none;
  }

  &.ant-input-affix-wrapper .ant-input-suffix {
    right: 10px;
  }

  &::placeholder {
    color: #667085 !important;
  }
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: ${({ isLanguage }) => (isLanguage === "ar" ? "400" : "400")};
  line-height: 24px;
  color: #344054;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  display: block;
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
  margin-bottom: 0;
  font-family: "Outfit", sans-serif;
`;
