import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Menu } from "antd";
import { ReactComponent as DashboardIcon } from "./dasboard.svg";
import { ReactComponent as PersonalInfoIcon } from "./perosonalInfo.svg";
import { ReactComponent as MerchantDetailIcon } from "./merchantDetail.svg";
import { ReactComponent as ServiceDetailIcon } from "./serviceDetail.svg";
import { ReactComponent as BusinessScheduleIcon } from "./businessSchedule.svg";
import { ReactComponent as TeamDetailIcon } from "./teamDetail.svg";
import { ReactComponent as BankDetailIcon } from "./bankDetail.svg";
import { ReactComponent as AdsIcon } from "./ads.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { removeAllCookie } from "../../Utils/storageVariable";
import { Success } from "../../Responses/Responses";
import { useLocation } from "react-router-dom";
import { BaseService } from "../../Api/base";

const SideNav = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  function getItem(label, key, icon, path) {
    return {
      key,
      icon,
      path,
      label,
    };
  }

  const items = [
    getItem(
      `${t("sideNavContent.dashboard")}`,
      "0",
      <DashboardIcon />,
      "/dashboard"
    ),

    getItem(
      `${t("sideNavContent.personalInfo")}`,
      "1",
      <PersonalInfoIcon />,
      "/dashboard/personal-info"
    ),
    getItem(
      `${t("sideNavContent.merchantDetail")}`,
      "2",
      <MerchantDetailIcon />,
      "/dashboard/merchant-detail"
    ),
    getItem(
      `${t("sideNavContent.serviceDetail")}`,
      "3",
      <ServiceDetailIcon />,
      "/dashboard/service-detail"
    ),
    getItem(
      `${t("sideNavContent.BusinessDetail")}`,
      "4",
      <BusinessScheduleIcon />,
      "/dashboard/business-detail"
    ),
    getItem(
      `${t("sideNavContent.teamDetails")}`,
      "5",
      <TeamDetailIcon />,
      "/dashboard/team-detail"
    ),
    getItem(
      `${t("sideNavContent.bankDetails")}`,
      "6",
      <BankDetailIcon />,
      "/dashboard/bank-detail"
    ),
    // getItem(`${t("sideNavContent.ads")}`, "7", <AdsIcon />, "/dashboard/ads"),
  ];
  const [selected, setselected] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = (item) => {
    navigate(item?.path);
  };
  const handleLogout = async () => {
    try {
      await BaseService.remove("/employeeAuth/logout");
      Success("Logout Successfully");
      navigate("/auth");
      removeAllCookie();
    } catch (e) {
      removeAllCookie();
      navigate("/auth");
    }
  };
  useEffect(() => {
    items?.map((item) => {
      if (item?.path === location.pathname) {
        setselected(item);
      }
    });
  }, [location.pathname]);
  return (
    <Container isLanguage={i18n.language}>
      <Menu
        defaultSelectedKeys={selected?.key}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        selectedKeys={selected?.key}
      >
        {items?.map((item) => (
          <Menu.Item
            key={item.key}
            icon={item.icon}
            onClick={() => handleNavigate(item)}
          >
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
      <BtnWrapper>
        <Button isLanguage={i18n.language} onClick={handleLogout}>
          {t("sideNavContent.logout")}
        </Button>
      </BtnWrapper>
    </Container>
  );
};

export default SideNav;

const Container = styled.div`
  background-color: #060a32;
  height: calc(100vh - 80px);
  width: 100%;
  .ant-menu-item:nth-child(1) {
    margin-top: 1.5rem;
  }
  .ant-menu {
    background-color: transparent !important;
  }
  .ant-menu-item {
    color: #ffff !important;
    border-radius: 0;
    padding: ${({ isLanguage }) => isLanguage === "en" && 0};
    padding-right: ${({ isLanguage }) => isLanguage === "ar" && "24px"};

    margin: 0;
    width: 100%;
  }
  .ant-menu-item-active {
    background-color: #2e3153;
    margin: 0;
    width: 100%;
  }
  .ant-menu-item-selected {
    background-color: #2e3153;
  }
  .ant-menu-title-content {
    font-weight: 200;
    font-size: 16px;
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const BtnWrapper = styled.div`
  width: 80%;
  margin: 2rem auto;
`;

const Button = styled.button`
  cursor: pointer;
  padding: 12px 0 12px 0;
  background-color: #f55a65;
  border: none;
  outline: none;
  border-radius: 6px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  color: #ffff;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
