// reducers.js

import { FETCH_CATEGORY_FAILURE, FETCH_CATEGORY_SUCCESS, FETCH_CATEGORY_REQUEST, UPDATE_CATEGORY } from "./types";



const initialState = {
  data: [],
  loading: false,
  error: ''
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATEGORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CATEGORY_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        data: action.payload
        // data: action.payload?.merchantId ? state?.data?.map(item => item?.merchantId === action.payload?.merchantId ? action.payload : item) : [...state.data, action.payload]
      };
    case FETCH_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default categoryReducer;
