import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Button from "../../Elements/Button/Button";
import { Checkbox, Col, Row } from "antd";
import TimeInput from "../../Elements/TImeInput/TimeInput";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addBusinessHours } from "../../Redux/Add-Merchant/action";
import Loading from "../../Components/Loader/Loading";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FormDataContext } from "../../App";
import {
  convertMinutesToTimeFormat,
  convertTimeFormat,
} from "../../Utils/dateUtils";
import dayjs from "dayjs";

const BusinessSchedule = ({
  isUpdate = false,
  nextStep = () => {},
  prevStep = () => {},
  fetchTimeSlots,
}) => {
  const { t, i18n } = useTranslation();
  // const data = useSelector((state) => state?.GetMerchantReducer?.data);
  const loading = useSelector((state) => state?.GetMerchantReducer?.loading);
  const AddLoading = useSelector((state) => state?.AddMerchantReducer?.loading);
  const [isDashboard, setIsDashboard] = useState(false);
  const location = useLocation();
  const [selectedDays, setSelectedDays] = useState([]);
  const [businessHours, setBusinessHours] = useState([]);
  const dispatch = useDispatch();
  const { data } = useContext(FormDataContext);

  useEffect(() => {
    if (!data.businessHours) fetchTimeSlots();
  }, [isUpdate]);

  useEffect(() => {
    if (!data || !data.businessHours) return;
    setBusinessHours(
      data?.businessHours.map((day) => {
        return {
          ...day,
          timeRanges: day.timeRanges.map((range) => {
            return {
              ...range,
              from: convertMinutesToTimeFormat(range.from),
              to: convertMinutesToTimeFormat(range.to),
            };
          }),
        };
      })
    );
  }, [data]);

  useEffect(() => {
    setSelectedDays(data?.businessHours || []);
  }, [data]);

  const handleDayChange = (day) => {
    const isSelected = data
      ? selectedDays?.some(
          (item) => item?.timeRanges?.length != 0 && item.day === day
        )
      : false;
    setSelectedDays((prevSelectedDays) => {
      if (!isSelected) {
        return [
          ...(prevSelectedDays || []),
          {
            day: day,
            timeRanges: [{ from: "", to: "" }],
          },
        ];
      } else {
        return prevSelectedDays?.filter((item) => item.day !== day);
      }
    });
  };

  useEffect(() => {
    setSelectedDays(businessHours);
  }, [businessHours]);

  const handleAddSlot = (day) => {
    const dayIndex = selectedDays?.findIndex((item) => item?.day === day);
    if (dayIndex !== -1) {
      const newTimeSlot = { from: "", to: "" };
      const updatedSelectedDays = selectedDays?.map((item, index) => {
        if (index === dayIndex) {
          return {
            ...item,
            timeRanges: [...item?.timeRanges, newTimeSlot],
          };
        }
        return item;
      });
      setSelectedDays(updatedSelectedDays);
    }
  };
  const [error, setError] = useState(false);

  const handleTimeChange = (timeString, day, index, field) => {
    const dayIndex = selectedDays?.findIndex((item) => item.day === day);

    if (dayIndex !== -1) {
      const updatedSelectedDays = selectedDays?.map((item, i) => {
        if (i === dayIndex) {
          const updatedTimeRanges = item.timeRanges?.map(
            (range, rangeIndex) => {
              if (rangeIndex === index) {
                let error = false;

                return {
                  ...range,
                  [field]: convertTimeFormat(timeString),
                  error: error,
                };
              }
              return range;
            }
          );

          const hasError = updatedTimeRanges.some((range) => range.error);
          setError(hasError);

          return { ...item, timeRanges: updatedTimeRanges };
        }
        return item;
      });

      setSelectedDays(updatedSelectedDays);
    }
  };

  const handleSubmit = () => {
    const filteredFormData = selectedDays?.filter((day) => {
      return (
        day.timeRanges &&
        day.timeRanges?.some((range) => range.from && range.to)
      );
    });
    const formData = filteredFormData?.map((day) => {
      return {
        day: day.day,
        timeRanges: day.timeRanges?.map((range) => {
          const { error, ...data } = range;
          return {
            from:
              typeof data.from === "string"
                ? convertTimeFormat(data.from)
                : data.from,
            to:
              typeof data.to === "string"
                ? convertTimeFormat(data.to)
                : data.to,
          };
        }),
        isAvailable: true,
      };
    });
    const path = location.pathname;
    if (formData)
      dispatch(
        addBusinessHours(
          { businessHours: formData },
          nextStep,
          path,
          fetchTimeSlots
        )
      );
  };

  const deleteSlot = (slot, timeRange) => {
    const updatedSelectedDays = selectedDays?.map((day) => {
      if (day.day === slot.day) {
        const updatedTimeRanges = day.timeRanges?.filter(
          (range) => range.from !== timeRange.from || range.to !== timeRange.to
        );
        if (updatedTimeRanges.length === 0) {
          return null;
        }
        return { ...day, timeRanges: updatedTimeRanges };
      }
      return day;
    });
    const filteredDays = updatedSelectedDays?.filter((day) => day !== null);
    setSelectedDays(filteredDays);
  };
  useEffect(() => {
    const path = location.pathname;

    if (path.startsWith("/dashboard")) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [location.pathname]);

  return (
    <>
      {loading && <Loading />}

      <Container isDashboard={isDashboard} isLanguage={i18n.language}>
        <Form isDashboard={isDashboard}>
          <Heading isLanguage={i18n.language}>
            {t("businessSchedule.heading")}
          </Heading>
          <Text isLanguage={i18n.language}>
            {t("businessSchedule.description")}
          </Text>
          <List>
            <Label isLanguage={i18n.language}>
              {t("businessSchedule.businessDaysLabel")}
            </Label>
            <CheckBoxWrapper isLanguage={i18n.language}>
              <Checkbox
                checked={selectedDays?.some(
                  (item) =>
                    item?.timeRanges.length != 0 && item?.day === "Sunday"
                )}
                onChange={() => handleDayChange("Sunday")}
              />
              <CheckText isLanguage={i18n.language}>
                {t(`businessSchedule.sundayOption`)}
              </CheckText>
            </CheckBoxWrapper>
            <CheckBoxWrapper isLanguage={i18n.language}>
              <Checkbox
                checked={selectedDays?.some(
                  (item) =>
                    item?.timeRanges.length != 0 && item?.day === "Monday"
                )}
                onChange={() => handleDayChange("Monday")}
              />
              <CheckText isLanguage={i18n.language}>
                {t(`businessSchedule.mondayOption`)}
              </CheckText>
            </CheckBoxWrapper>
            <CheckBoxWrapper isLanguage={i18n.language}>
              <Checkbox
                checked={selectedDays?.some(
                  (item) =>
                    item?.timeRanges.length != 0 && item?.day === "Tuesday"
                )}
                onChange={() => handleDayChange("Tuesday")}
              />
              <CheckText isLanguage={i18n.language}>
                {t(`businessSchedule.tuesdayOption`)}
              </CheckText>
            </CheckBoxWrapper>
            <CheckBoxWrapper isLanguage={i18n.language}>
              <Checkbox
                checked={selectedDays?.some(
                  (item) =>
                    item?.timeRanges.length != 0 && item?.day === "Wednesday"
                )}
                onChange={() => handleDayChange("Wednesday")}
              />
              <CheckText isLanguage={i18n.language}>
                {t(`businessSchedule.wednesdayOption`)}
              </CheckText>
            </CheckBoxWrapper>
            <CheckBoxWrapper isLanguage={i18n.language}>
              <Checkbox
                checked={selectedDays?.some(
                  (item) =>
                    item?.timeRanges.length != 0 && item?.day === "Thursday"
                )}
                onChange={() => handleDayChange("Thursday")}
              />
              <CheckText isLanguage={i18n.language}>
                {t(`businessSchedule.thursdayOption`)}
              </CheckText>
            </CheckBoxWrapper>
            <CheckBoxWrapper isLanguage={i18n.language}>
              <Checkbox
                checked={selectedDays?.some(
                  (item) =>
                    item?.timeRanges.length != 0 && item?.day === "Friday"
                )}
                onChange={() => handleDayChange("Friday")}
              />
              <CheckText isLanguage={i18n.language}>
                {t(`businessSchedule.fridayOption`)}
              </CheckText>
            </CheckBoxWrapper>
            <CheckBoxWrapper isLanguage={i18n.language}>
              <Checkbox
                checked={selectedDays?.some(
                  (item) =>
                    item?.timeRanges.length != 0 && item?.day === "Saturday"
                )}
                onChange={() => handleDayChange("Saturday")}
              />
              <CheckText isLanguage={i18n.language}>
                {t(`businessSchedule.saturdayOption`)}
              </CheckText>
            </CheckBoxWrapper>
          </List>
          <Label isLanguage={i18n.language}>
            {t("businessSchedule.businessHoursLabel")}
          </Label>
          <TimesWrapper>
            <Row gutter={{ xs: 4, sm: 8, md: 8, lg: 16, xl: 16 }}>
              <Col xl={6} lg={6} sm={8} xs={8}>
                <Text isLanguage={i18n.language}>
                  {t("businessSchedule.dayLabel")}
                </Text>
              </Col>
              <Col xl={8} lg={8} sm={7} xs={7}>
                <Text isLanguage={i18n.language}>
                  {t("businessSchedule.openTimeLabel")}
                </Text>
              </Col>
              <Col xl={8} lg={8} sm={7} xs={7}>
                <Text isLanguage={i18n.language}>
                  {t("businessSchedule.closeTimeLabel")}
                </Text>
              </Col>
            </Row>
          </TimesWrapper>
          {selectedDays?.map((day, i) => (
            <div key={i}>
              <TimesWrapper>
                {day?.timeRanges.length != 0 &&
                  day?.timeRanges?.map((item, index) => (
                    <Row
                      gutter={{ xs: 4, sm: 8, md: 8, lg: 16, xl: 16 }}
                      key={index}
                      style={{ marginBottom: "1rem" }}
                    >
                      <Col xl={6} lg={6} sm={8} xs={8}>
                        <Wrap>
                          <CheckText isLanguage={i18n.language}>
                            {day?.day === "Sunday" &&
                              t(`businessSchedule.sundayOption`)}
                            {day?.day === "Monday" &&
                              t(`businessSchedule.mondayOption`)}
                            {day?.day === "Tuesday" &&
                              t(`businessSchedule.tuesdayOption`)}
                            {day?.day === "Wednesday" &&
                              t(`businessSchedule.wednesdayOption`)}
                            {day?.day === "Thursday" &&
                              t(`businessSchedule.thursdayOption`)}
                            {day?.day === "Friday" &&
                              t(`businessSchedule.fridayOption`)}
                            {day?.day === "Saturday" &&
                              t(`businessSchedule.saturdayOption`)}
                          </CheckText>
                        </Wrap>
                      </Col>
                      <Col xl={8} lg={8} sm={7} xs={7}>
                        <TimeInput
                          name={"from"}
                          defaultValue={item?.from}
                          onChange={(timeString) =>
                            handleTimeChange(
                              timeString,
                              day?.day,
                              index,
                              "from"
                            )
                          }
                          error={item.error}
                        />
                      </Col>
                      <Col xl={8} lg={8} sm={7} xs={7}>
                        <TimeInput
                          name={"to"}
                          defaultValue={item?.to}
                          onChange={(timeString) =>
                            handleTimeChange(timeString, day?.day, index, "to")
                          }
                          error={item.error}
                        />
                      </Col>
                      <Col span={2}>
                        <IconWrapper>
                          <DeleteOutlined
                            style={{ color: "#F55A65", fontSize: "20px" }}
                            onClick={() => deleteSlot(day, item)}
                          />
                        </IconWrapper>
                      </Col>
                    </Row>
                  ))}
              </TimesWrapper>
              {day?.timeRanges.length !== 0 && (
                <WrapBtn>
                  <CustomButton
                    isLanguage={i18n.language}
                    onClick={() => handleAddSlot(day?.day)}
                  >
                    <PlusOutlined
                      style={{ fontSize: "10px", fontWeight: "900" }}
                    />
                    {t("businessSchedule.addAnotherSlotsButtonText")}
                  </CustomButton>
                </WrapBtn>
              )}
            </div>
          ))}
          {isUpdate === false ? (
            <ButtonWrapper>
              <Button isLanguage={i18n.language} onClick={prevStep}>
                {t("businessSchedule.previousButtonText")}
              </Button>
              <Button
                disabled={AddLoading}
                loading={AddLoading}
                isLanguage={i18n.language}
                primary
                onClick={handleSubmit}
              >
                {t("businessSchedule.continueButtonText")}
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper isWidth>
              <Button
                disabled={AddLoading}
                loading={AddLoading}
                isLanguage={i18n.language}
                primary
                onClick={handleSubmit}
              >
                {t("Update")}
              </Button>
            </ButtonWrapper>
          )}
        </Form>
      </Container>
    </>
  );
};

export default BusinessSchedule;

const Container = styled.div`
  width: 100%;

  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  @media (max-width: 600px) {
    min-width: 100%;
    padding: 0;
    max-height: max-content;
  }
`;

const Form = styled.div`
  min-width: 524px;
  min-height: 508px;
  max-width: 50%;
  background-color: #ffffff;
  max-height: auto;

  padding: 2rem;
  border: 1px solid hsla(220, 17%, 93%, 1);
  border-radius: 12px;
  margin-bottom: ${({ isDashboard }) => (isDashboard ? "" : "6rem")};

  @media (max-width: 820px) {
    padding: 1rem;
    max-height: max-content;
    margin-bottom: 4rem;
  }
  @media (max-width: 600px) {
    min-width: 90%;
    max-width: 90%;
    padding: 0.5rem;
    max-height: max-content;
    margin-bottom: ${({ isDashboard }) => (isDashboard ? "1rem" : "10rem")};
    margin-top: ${({ isDashboard }) => isDashboard && "3rem"};
  }
`;

const Heading = styled.h2`
  line-height: 26px;
  font-size: 20px;
  font-weight: Weight;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #1d2939;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  line-height: 20px;
`;

const ButtonWrapper = styled.div`
  width: ${({ isWidth }) => (isWidth ? "40%" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
  gap: 15rem;

  @media (max-width: 820px) {
    gap: 13rem;
  }
  @media (max-width: 600px) {
    gap: 36%;
  }
`;

const List = styled.div`
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Label = styled.label`
  display: block;
  color: #344054;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  line-height: 24px;
`;

const CheckText = styled.p`
  color: #1d2939;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  line-height: 24px;
`;

const TimesWrapper = styled.div`
  margin-top: 1rem;
`;

const Wrap = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const WrapBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 0.6rem;
  cursor: pointer;
  margin-top: 0.6rem;
`;

const CustomButton = styled.button`
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  border: none;
  outline: none;
  background-color: transparent;
  color: #1d2939;
  cursor: pointer;
`;
const CheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
  gap: 0.5rem;
  .ant-checkbox-inner:after {
    border-color: #f55a65 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #f55a65 !important;
    background-color: #feefef !important;
  }
`;
const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
