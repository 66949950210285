import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CustomInput from "../../Elements/Input/Input";
import Button from "../../Elements/Button/Button";
import { Collapse } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { teamDetailSchema } from "../../Schemas";
import { useDispatch, useSelector } from "react-redux";
import { addEmployee } from "../../Redux/Add-Employee/action";
import Loading from "../../Components/Loader/Loading";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { arraysEqual } from "../../Utils/common";
import { ReactComponent as DeleteOutlined } from "./trash.svg";
import { FormDataContext } from "../../App";

const TeamDetails = ({
  isUpdate = false,
  nextStep = () => {},
  prevStep = () => {},
  fetchEmployees,
}) => {
  const { t, i18n } = useTranslation();
  const [panelsWithErrors, setPanelsWithErrors] = useState();
  const { data } = useContext(FormDataContext);

  useEffect(() => {
    if (!data.employees) fetchEmployees();
  }, [isUpdate]);

  //const data = useSelector((state) => state?.EmployeeListReducer?.data);
  const loading = useSelector((state) => state?.EmployeeListReducer?.loading);
  const addEmployeLoading = useSelector(
    (state) => state?.addEmployeeReducer?.loading
  );

  const location = useLocation();

  const dispatch = useDispatch();

  const initialValues = {
    memberList: [{ name: "", email: "", phoneNumber: "" }],
  };

  const {
    errors,
    setValues,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: teamDetailSchema,
    onSubmit: (values, action, errors) => {
      const path = location.pathname;

      if (arraysEqual(values?.memberList, data)) return nextStep();
      return dispatch(
        addEmployee(values?.memberList, nextStep, path, fetchEmployees)
      );
    },
  });
  useEffect(() => {
    if (data && data.employees) {
      setValues({
        ...values,
        memberList: data.employees?.map((item) => ({
          name: item.name || "",
          email: item.email || "",
          phoneNumber: item.phoneNumber || "",
          merchantId: item.merchantId || "",
        })),
      });
    }
  }, [data]);

  const handleAddPanel = (i) => {
    const newPanel = { name: "", email: "", phoneNumber: "" };
    setValues((prevValues) => ({
      ...prevValues,
      memberList: [...prevValues.memberList, newPanel],
    }));
    setPanelsWithErrors(values.memberList.length);
  };

  const handleDeletePanel = (item, index) => {
    const updatedPanels = values?.memberList.filter((panel, i) => i !== index);

    setValues((prevValues) => ({
      ...prevValues,
      memberList: updatedPanels,
    }));
  };

  return (
    <>
      {loading && <Loading />}
      <Container isLanguage={i18n.language}>
        <Form>
          <Heading isLanguage={i18n.language}>
            {t("teamDetails.heading")}
          </Heading>
          <Text isLanguage={i18n.language}>{t("teamDetails.description")}</Text>
          <Collapse
            bordered={false}
            style={{ border: "1px solid #D0D5DD" }}
            accordion
            activeKey={panelsWithErrors}
            onChange={(value) => setPanelsWithErrors(value)}
          >
            {values?.memberList?.map((panel, index) => (
              <Collapse.Panel
                key={panel.key}
                header={
                  <PanelHeader>
                    <Label isLanguage={i18n.language}>
                      {t("teamDetails.text")} {index + 1}
                    </Label>{" "}
                    <DeleteOutlined
                      style={{ color: "#F55A65", fontSize: "20px" }}
                      onClick={() => handleDeletePanel(panel, index)}
                    />
                  </PanelHeader>
                }
              >
                <InputWrapper>
                  <CustomInput
                    name={`memberList[${index}].name`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={panel.name}
                    placeholder={t("teamDetails.memberFirstNamePlaceholder")}
                  />
                  {errors.memberList?.[index]?.name &&
                  touched.memberList?.[index]?.name ? (
                    <Span>{errors.memberList[index].name}</Span>
                  ) : null}
                </InputWrapper>
                {/* <InputWrapper>
                  <CustomInput
                    name={`memberList[${index}].`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={panel.lastName}
                    placeholder={t("teamDetails.memberLastNamePlaceholder")}
                  />
                  {errors.memberList?.[index]?.lastName &&
                  touched.memberList?.[index]?.lastName ? (
                    <Span>{errors.memberList[index].lastName}</Span>
                  ) : null}
                </InputWrapper> */}
                <InputWrapper isRight>
                  <Input
                    isLanguage={i18n.language}
                    label={t("signupContent.rightSection.label")}
                    value={panel?.phoneNumber}
                    onBlur={handleBlur}
                    placeholder={t("teamDetails.memberPhoneNumberPlaceholder")}
                    onChange={(value) =>
                      handleChange({
                        target: {
                          name: `memberList[${index}].phoneNumber`,
                          value,
                        },
                      })
                    }
                    defaultCountry={"SA"}
                  />
                  {errors.memberList?.[index]?.phoneNumber &&
                  touched.memberList?.[index]?.phoneNumber ? (
                    <Span>{errors.memberList[index].phoneNumber}</Span>
                  ) : null}
                </InputWrapper>
              </Collapse.Panel>
            ))}
          </Collapse>
          <ButtonWrapper>
            <CustomButton isLanguage={i18n.language} onClick={handleAddPanel}>
              <PlusOutlined />
              {t("teamDetails.addMemberButtonText")}
            </CustomButton>
          </ButtonWrapper>
          {isUpdate === false ? (
            <ButtonWrapper gap>
              <Button isLanguage={i18n.language} onClick={prevStep}>
                {t("teamDetails.previousButtonText")}
              </Button>
              <Button
                loading={addEmployeLoading}
                disabled={addEmployeLoading}
                isLanguage={i18n.language}
                primary
                type="submit"
                onClick={() => handleSubmit(values)}
              >
                {t("teamDetails.continueButtonText")}
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper isWidth>
              <Button
                disabled={addEmployeLoading}
                loading={addEmployeLoading}
                isLanguage={i18n.language}
                primary
                type="submit"
              >
                {t("Update")}
              </Button>
            </ButtonWrapper>
          )}
        </Form>
      </Container>
    </>
  );
};

export default TeamDetails;

const Container = styled.div`
  width: 100%;

  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  @media (max-width: 600px) {
    min-width: 100%;
    padding: 0;
    max-height: max-content;
  }
  .ant-upload-select {
    width: 100% !important;
  }
`;
const Form = styled.div`
  min-width: 524px;
  min-height: 508px;
  max-width: 50%;
  background-color: #ffffff;
  max-height: auto;

  padding: 2rem;
  border: 1px solid hsla(220, 17%, 93%, 1);
  border-radius: 12px;
  margin-bottom: ${({ isDashboard }) => (isDashboard ? "" : "6rem")};
  @media (max-width: 820px) {
    padding: 1rem;
    max-height: max-content;
    margin-bottom: 4rem;
  }
  @media (max-width: 600px) {
    min-width: 90%;
    max-width: 90%;
    padding: 1rem;
    max-height: max-content;
    margin-bottom: ${({ isDashboard }) => (isDashboard ? "1rem" : "10rem")};
    margin-top: ${({ isDashboard }) => isDashboard && "3rem"};
  }
`;
const Heading = styled.h2`
  line-height: 26px;
  font-size: 20px;
  font-weight: bold;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;

const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  margin-top: 1rem;
  /* background: #ffff; */

  direction: ${({ isRight }) => isRight && "ltr"};
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: hsla(216, 18%, 34%, 1);
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  line-height: 20px;
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  width: ${({ isWidth }) => (isWidth ? "40%" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  gap: ${({ gap }) => gap && "15rem"};

  @media (max-width: 820px) {
    gap: 13rem;
  }
  @media (max-width: 600px) {
    gap: 36%;
  }
`;

const CustomButton = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  border: none;
  outline: none;
  background-color: transparent;
  color: #f55a65;
  margin: 0 auto;
  cursor: pointer;
`;

const Label = styled.p`
  color: #475467;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;

const Span = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;
const Input = styled(PhoneInput)`
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 14px;
  background-color: #ffff;
  outline: none;
  border: 1px solid ${(props) => (props.isBorder ? "red" : "#D0D5DD")}; // Check if this logic is correct
  font-family: "Outfit", sans-serif !important;
  border-radius: 8px;
  margin-top: 1.4rem;
  color: #344054;
  width: 100%;
  height: 55px;
  .PhoneInputInput {
    border: none;
    font-size: 16px;
    background-color: #ffff;
    font-family: "Outfit", sans-serif !important;
    outline: none;

    font-weight: 400;

    &::placeholder {
      color: #667085;
    }
  }
`;
