import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "./downIcon.svg";
import { Dropdown, Menu } from "antd";
import moment from "moment";

const CustomInputWrapper = styled.label`
  display: flex;
  align-items: center;
  direction: ltr;

  .ant-dropdown-trigger {
    padding: 12px 14px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;

    input {
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 400;
      font-family: "Outfit", sans-serif;
      color: #667085;
      width: 100%;
    }

    .icon {
      margin-left: auto;
      pointer-events: none;
    }
  }

  @media (max-width: 600px) {
    .ant-dropdown-trigger {
      padding: 10px 3px;

      input {
        font-size: 11px;
      }
    }
  }

  .ant-dropdown-menu-item {
    padding: 8px 14px;
    font-size: 16px;
    font-family: "Outfit", sans-serif;
  }
`;

const generateTimeList = () => {
  const times = [];
  const start = moment().startOf("day");
  const end = moment().endOf("day");

  while (start.isBefore(end)) {
    times.push(start.format("hh:mm A"));
    start.add(30, "minutes");
  }

  return times;
};

const TimeInput = ({ value, onChange, name, error, ...rest }) => {
  const [selectedTime, setSelectedTime] = useState(
    value || rest?.defaultValue || ""
  );
  const times = generateTimeList();

  const handleMenuClick = (e) => {
    const time = e.key;
    setSelectedTime(time);
    onChange(time);
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ maxHeight: "300px", overflowY: "auto" }}
    >
      {times.map((time) => (
        <Menu.Item key={time}>{time}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <CustomInputWrapper>
      <Dropdown overlay={menu} trigger={["click"]}>
        <div className="ant-dropdown-trigger">
          <input
            type="text"
            readOnly
            placeholder="Select time"
            value={selectedTime}
            {...rest}
          />
          <Icon className="icon" />
        </div>
      </Dropdown>
    </CustomInputWrapper>
  );
};

export default TimeInput;
