import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CustomInput from "../../Elements/Input/Input";
import Button from "../../Elements/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { InformationSchema } from "../../Schemas";
import { useDispatch, useSelector } from "react-redux";
import {
  addMerchant,
  updateEmployeeProfile,
} from "../../Redux/Add-Merchant/action";
import Loading from "../../Components/Loader/Loading";
import { setCookie } from "../../Utils/storageVariable";
import { useTranslation } from "react-i18next";
import { isObjectChanged } from "../../Utils/common";
import { FormDataContext } from "../../App";
import { BaseService } from "../../Api/base";

const PersonalInfo = ({
  isUpdate = false,
  nextStep = () => {},
  prevStep = () => {},
  refetch,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, setData } = useContext(FormDataContext);

  // const data = useSelector((state) => state?.GetMerchantReducer?.data);
  const loading = useSelector((state) => state?.GetMerchantReducer?.loading);
  const addLoading = useSelector((state) => state?.AddMerchantReducer?.loading);

  useEffect(() => {
    if (!data.merchantAdmin) refetch();
  }, [isUpdate]);
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    email: "",
  };

  useEffect(() => {
    if (!data) return;
    setValues({
      name: data.merchantAdmin?.name,
      email: data.merchantAdmin?.email,
    });
  }, [data]);

  const {
    errors,
    values,
    setValues,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: InformationSchema,
    onSubmit: (values, action) => {
      const path = location.pathname;
      if (
        !isObjectChanged(values, {
          name: data.merchantAdmin?.name,
          email: data.merchantAdmin?.email,
        })
      )
        return nextStep();
      return dispatch(updateEmployeeProfile(values, nextStep, path, refetch));
    },
  });
  useEffect(() => {
    setCookie("name", data?.name);
  }, [data]);

  const [isDashboard, setIsDashboard] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/dashboard")) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [location.pathname]);
  return (
    <>
      {loading && <Loading />}
      <Container
        isDashboard={isDashboard}
        isLanguage={i18n?.language}
        style={{ marginBottom: "150px" }}
      >
        <Form isDashboard={isDashboard}>
          <Heading isLanguage={i18n?.language}>{t("merchant.heading")}</Heading>
          <Text isLanguage={i18n?.language}> {t("merchant.description")}</Text>
          <InputWrapper>
            <CustomInput
              type="text"
              placeholder={t("merchant.nameInputPlaceholeder")}
              label={t("merchant.inputName")}
              name="name"
              value={values.name}
              onBlur={handleBlur}
              onChange={handleChange}
              Error={errors.name && touched.name}
            />
            {errors.name && touched.name ? <Span>{errors.name}</Span> : null}
          </InputWrapper>
          {/* <InputWrapper>
            <CustomInput
              type="text"
              placeholder={t("merchant.lastNameInputPlaceholeder")}
              label={t("merchant.inputLastName")}
              name="lastName"
              value={values.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
              Error={errors.lastName && touched.lastName}
            />
            {errors.lastName && touched.lastName ? (
              <Span>{errors.lastName}</Span>
            ) : null}
          </InputWrapper> */}
          <InputWrapper>
            <CustomInput
              type="text"
              placeholder={t("merchant.emailInputPlaceholder")}
              label={t("merchant.inputEmail")}
              name="email"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              Error={errors.email && touched.email}
            />
            {errors.email && touched.email ? <Span>{errors.email}</Span> : null}
          </InputWrapper>
          {isUpdate === false ? (
            <ButtonWrapper isLanguage={i18n.language}>
              <Button isLanguage={i18n.language} onClick={() => navigate("/")}>
                {t("merchant.cancelButtonText")}
              </Button>
              <Button
                loading={addLoading}
                disabled={addLoading}
                isLanguage={i18n.language}
                primary
                onClick={handleSubmit}
                type={"submit"}
              >
                {t("merchant.buttonText")}
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper isWidth>
              <Button
                disabled={
                  addLoading ||
                  !isObjectChanged(values, {
                    name: data?.name,
                    email: data?.email,
                  })
                }
                loading={addLoading}
                isLanguage={i18n.language}
                primary
                onClick={handleSubmit}
                type={"submit"}
              >
                {t("Update")}
              </Button>
            </ButtonWrapper>
          )}
        </Form>
      </Container>
    </>
  );
};

export default PersonalInfo;
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  margin-top: ${({ isDashboard }) => (isDashboard ? "0" : "2rem")};

  @media (max-width: 820px) {
    height: 100%;
    margin-top: ${({ isDashboard }) => (isDashboard ? "0" : "2rem")};
  }
  @media (max-width: 600px) {
    height: 100%;
    margin-top: 0;
  }
  @media (max-height: 700px) {
    margin-top: 2rem;
  }
`;
const Form = styled.div`
  min-width: 524px;
  min-height: 400px;
  max-width: 50%;
  background-color: #ffffff;

  padding: 2rem;
  border: 1px solid hsla(220, 17%, 93%, 1);
  border-radius: 12px;

  @media (max-width: 820px) {
    padding: 1rem;
    max-height: max-content;
  }
  @media (max-width: 600px) {
    min-width: 90%;
    padding: 1rem;
    padding-bottom: 0;
    margin-bottom: ${({ isDashboard }) => (isDashboard ? "1rem" : "10rem")};
  }
`;
const Heading = styled.h2`
  line-height: 26px;
  font-size: 20px;
  font-weight: Weight;

  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
`;
const InputWrapper = styled.div`
  margin-top: 1.5rem;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: hsla(216, 18%, 34%, 1);
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  line-height: 20px;
`;
const ButtonWrapper = styled.div`
  width: ${({ isWidth }) => (isWidth ? "40%" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};

  gap: 15rem;
  @media (max-width: 820px) {
    gap: 13rem;
  }
  @media (max-width: 600px) {
    gap: 36%;
  }
`;
const Span = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;
