import React, { useEffect, useContext } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import CustomInput from "../../Elements/Input/Input";
import PhoneInput from "react-phone-number-input";
import Button from "../../Elements/Button/Button";
import { useFormik } from "formik";
import { employDetailSchema } from "../../Schemas";
import { useDispatch, useSelector } from "react-redux";
import { addEmployee } from "../../Redux/Add-Employee/action";
import { useLocation } from "react-router-dom";
import { FormDataContext } from "../../App";


const EditModal = ({ isOpen, setIsOpen, editData, setEditData = () => {}, fetchEmployees }) => {

  const { data } = useContext(FormDataContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const initialValues = {
    name: "",
    phoneNumber: "",
  };
  const {
    errors,
    setValues,
    values,
    setErrors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: employDetailSchema,
    onSubmit: (values, action) => {
      const path = location.pathname;
      dispatch(
        addEmployee(
          editData
            ? [
                ...data.employees?.map((item) =>
                  item?.id === values?.id ? values : item
                ),
              ]
            : [...data.employees, { ...values }],
          () => {},
          path,
          fetchEmployees
        )
      );

      setIsOpen(false);
      setEditData(null);
      setValues(initialValues);
    },
  });
  useEffect(() => {
    if (editData == null) return;
    setValues({
      name: editData?.name || "",
      phoneNumber: editData?.phoneNumber || "",
      merchantId: editData?.merchantId || "",
      id: editData?.id
    });
  }, [editData]);

  useEffect(() => {
    if (isOpen) {
      setErrors({
        name: "",
        phoneNumber: "",
      });
    }
  }, [isOpen]);
  return (
    <Modal
      footer={false}
      centered={"center"}
      open={isOpen}
      onCancel={() => {
        setIsOpen(false);
        setEditData(null);
      }}
    >
      <Form onSubmit={handleSubmit}>
        <InputWrapper>
          <CustomInput
            placeholder="Enter First Name"
            label="Name"
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.name}
          />
          {errors.name || touched.name ? (
            <Span>{errors.name}</Span>
          ) : null}
        </InputWrapper>
        {/* <InputWrapper>
          <CustomInput
            placeholder="Enter Last Name"
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.lastName}
          />
          {errors.lastName || touched.lastName ? (
            <Span>{errors.lastName}</Span>
          ) : null}
        </InputWrapper> */}
        <InputWrapper>
          <Label>Phone Number</Label>
          <Input
            placeholder="Enter Phone Number"
            name="phoneNumber"
            value={values?.phoneNumber}
            onChange={(value) => setValues({ ...values, phoneNumber: value })}
          />
          {errors.phoneNumber || touched.phoneNumber ? <Span>{errors.phoneNumber}</Span> : null}
        </InputWrapper>

        <BtnWrapper>
          <Button primary type="submit">
            Save
          </Button>
        </BtnWrapper>
      </Form>
    </Modal>
  );
};

export default EditModal;

const Form = styled.form``;
const InputWrapper = styled.div`
  margin-top: 1rem;
`;
const Input = styled(PhoneInput)`
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 14px;
  outline: none;
  border: 1px solid ${(props) => (props.isBorder ? "red" : "#D0D5DD")};
  font-family: "Outfit", sans-serif !important;
  border-radius: 8px;
  margin-top: 0.4rem;
  color: #344054;
  width: 100%;
  .PhoneInputInput {
    border: none;
    font-size: 16px;
    font-family: "Outfit", sans-serif !important;
    outline: none;
    font-weight: 400;
  }
  .PhoneInputInput::placeholder {
    color: #667085 !important;
  }
`;
const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #344054;
  font-family: "Outfit", sans-serif;
  display: block;
`;
const BtnWrapper = styled.div`
  margin-top: 1.5rem;
  width: 20%;
`;
const Span = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;
