import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Header } from "../../Components/Header/Header";
import { setCookie } from "../../Utils/storageVariable";

const Success = () => {
  const { t, i18n } = useTranslation();

  const [lang, setLang] = useState(i18n?.language);
  const handleLanguageChange = (language) => {
    setCookie("language", language);
    i18n.changeLanguage(language);
    setLang(language);
  };
  return (
    <Container isLanguage={lang}>
      <Header
        setLang={setLang}
        handleLanguageChange={handleLanguageChange}
        lang={lang}
        t={t}
      />
      <PlanSection>
        <Card>
          <Image isWidth={"100px"} src="./Assets/tick1.png" />
          <MediumHeading isLanguage={i18n.language}>
            {t("Success.successMessage")}
          </MediumHeading>
          <Disc isLanguage={i18n.language}>{t("Success.description")}</Disc>
          <ImageWrapper>
            <Image src="./Assets/googlePlay.png" />
            <Image src="./Assets/AppStore.png" />

            <Image />
          </ImageWrapper>

          <Discription>
            <Circle>i</Circle>
            <Para isLanguage={i18n.language}> {t("Success.infoMessage")}</Para>
          </Discription>
        </Card>
      </PlanSection>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff9f5;

  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
`;

const Image = styled.img`
  height: ${({ isHeight }) => isHeight && "48px"};
  width: ${({ isWidth }) => isWidth && isWidth};
`;

const PlanSection = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  padding-top: 10rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  
`;
const Card = styled.div`
  width: 524px;
  height: 422px;
  background-color: #ffff;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 991px) {
    width: 90%;
  }
`;

const MediumHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  margin-top: 1.4rem;
  color: #1d2939;
`;

const Disc = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: center;
`;
const ImageWrapper = styled.div`
  padding: 2rem 0;
  display: flex;
  gap: 1rem;

  @media (max-width: 600px) {
    gap: 0.4rem;
  }
`;
const Discription = styled.div`
  display: flex;
  gap: 1rem;
  padding: 9px 9px;
  background-color: #eef3f9;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;

  
`;
const Para = styled.p`
  width: 96%;
  font-size: 14px;
  color: #344054;
  line-height: 20px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  font-weight: 400;
`;
const Circle = styled.div`
  flex-wrap: nowrap;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #0a61cc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 10px;
  margin-top: 0.2rem;
`;

export default Success;
