import { BaseService } from "../../Api/base";
import { Error } from "../../Responses/Responses";
import { updateEmployee } from "../Employee-List/action";
import {
  ADD_EMPLOYEE_FAILURE,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_REQUEST,
} from "./types";
export const addEmployee = (
  fields,
  nextStep = () => {},
  path,
  fetchEmployees
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_EMPLOYEE_REQUEST });
      const { data, status } = await BaseService.post(
        "/merchantAdmins/employees",
        { employees: fields }
      );
      if (status === 200) {
        if (fetchEmployees) fetchEmployees();
        if (path.startsWith("/dashboard")) {
          dispatch(updateEmployee(fields));
        } else {
          nextStep();
          dispatch(updateEmployee(fields));
        }
      }
      dispatch({ type: ADD_EMPLOYEE_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message);
      dispatch({ type: ADD_EMPLOYEE_FAILURE, payload: error.message });
    }
  };
};
