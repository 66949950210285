import React, { useEffect, useState, useContext } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import Button from "../../Elements/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OTPInput from "react-otp-input";
import { getCookie, removeCookie, setCookie } from "../../Utils/storageVariable";
import { Error } from "../../Responses/Responses";
import Loading from "../../Components/Loader/Loading";
import { Header } from "../../Components/Header/Header";
import { ReactComponent as LogoAr } from "./arLogo.svg";
import { ReactComponent as SignUpImage } from "./signUpImage.svg";
import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
} from "firebase/auth";
import { app } from "../../config";
import axios from "axios";
import { getDeviceId } from "../../Utils/deviceId";
import { FormDataContext } from "../../App";

const Verification = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setData } = useContext(FormDataContext);


  const { t, i18n } = useTranslation();
  const [otp, setOtp] = useState("");
  const [lang, setLang] = useState(i18n?.language);

  const handleLanguageChange = (language) => {
    setCookie("language", language);
    i18n.changeLanguage(language);
    setLang(language);
  };
  const auth = getAuth(app);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptca-container",
      {
        size: "invisible",
        callback: (response) => {},
        "expired-callback": (response) => {},
      }
    );
  }, [auth]);

  const handleSendOtp = async () => {
    try {
      setLoading(true);
      const formattedPhoneNumber = getCookie("phoneNumber");
      axios
        .post(process.env.REACT_APP_BASE_URL + `/employeeAuth/sendOtp`, {
          phoneNumber: formattedPhoneNumber,
          scope: "ALL",
        })
        .then((res) => {
          setLoading(false);
          navigate("/verify");
        })
        .catch((error) => {
          setLoading(false);
          Error(error?.message);
          if (
            error.code === "auth/code-expired" ||
            error.code === "auth/timeout"
          ) {
          } else {
          }
        });
    } catch (error) {
      setLoading(false);
      Error(error?.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const formattedPhoneNumber = getCookie("phoneNumber");
      axios
        .post(process.env.REACT_APP_BASE_URL + `/employeeAuth/verifyOtp`, {
          user: {
            phoneNumber: formattedPhoneNumber,
            otp,
          },
          device: {
            deviceId: getDeviceId(),
            os: "WEB",
          },
        })
        .then((res) => {
          setLoading(false);
          const response = res.data.data;
          const { user, device } = response;
          setCookie("token", device.accessToken);
          setCookie("refreshToken", device.refreshToken);
          setData({
            merchant: undefined,
            merchantAdmin: undefined,
            businessHours: undefined,
            categories: undefined,
            services: undefined,
          });
          if (user.isFirstTimeRegister) {
            navigate("/plans");
            setOtp("");
          } else {
            navigate("/dashboard");
            setOtp("");
          }
        })
        .catch((error) => {
          setLoading(false);
          Error(error?.message);
          if (
            error.code === "auth/code-expired" ||
            error.code === "auth/timeout"
          ) {
          } else {
          }
        });
    } catch (error) {
      Error("An unexpected error occurred:", error);
    }
  };
  const [timeLeft, setTimeLeft] = useState(60);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    if (timeLeft === 0) {
      setIsButtonDisabled(false);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <>
      {" "}
      {loading && <Loading />}
      <Container isLanguage={lang}>
        <Row style={lang === "ar" && { direction: "rtl" }}>
          <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
            <HeaderWrapper>
              {" "}
              <Header isTranparent={true} lang={lang} setLang={setLang} />
            </HeaderWrapper>

            <LeftSection isLanguage={lang}>
              <Section isMobile>
                <SignUpImage />
                <LeftHeading isLanguage={lang}>
                  {t("signupContent.leftSection.heading")}
                </LeftHeading>
                <Disc isLanguage={lang}>
                  {t("signupContent.leftSection.description")}
                </Disc>
              </Section>
            </LeftSection>
          </Col>
          <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
            <RightSecton>
              <HeaderDesktop>
                <BtnWrapper isLanguage={lang}>
                  <LangWrapper>
                    <ButtonOne
                      isLanguage={lang}
                      onClick={() => handleLanguageChange("en")}
                    >
                      EN
                    </ButtonOne>
                    <ButtonTwo
                      isLanguage={lang}
                      onClick={() => handleLanguageChange("ar")}
                    >
                      AR
                    </ButtonTwo>
                  </LangWrapper>
                  <HeaderText isLanguage={lang}>
                    {t("signupContent.rightSection.englishText")}
                  </HeaderText>
                </BtnWrapper>
              </HeaderDesktop>
              <FormWrapper isLanguage={lang}>
                <Form isLanguage={lang}>
                  <FormInput>
                    <Heading onClick={() => navigate("/")}>
                      {lang === "ar" && <LogoAr />}
                      {lang === "en" && (
                        <Image src="./Assets/logo.svg" alt="Logo" />
                      )}{" "}
                      {lang === "en" && "beautisry"}
                    </Heading>

                    <InputsWrapper isRight>
                      <CustomOtp
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderInput={(props) => <CustomInputOtp {...props} />}
                      />
                    </InputsWrapper>

                    <InputsWrapper>
                      <Button
                        isLanguage={lang}
                        primary
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {t("signupContent.rightSection.buttonText")}
                      </Button>
                    </InputsWrapper>
                    <ResendOtpWrapper>
                      {isButtonDisabled ? (
                        <WaitingText isLanguage={i18n.language}>
                          {t("signupContent.waitingText")}{" "}
                          <Span>{timeLeft}</Span> {t("signupContent.seconds")}
                        </WaitingText>
                      ) : (
                        <>
                          <ResendText isLanguage={i18n.language}>
                            {t("signupContent.resendText")}
                          </ResendText>
                          <ResendButton
                            onClick={handleSendOtp}
                            isLanguage={i18n.language}
                          >
                            {t("signupContent.resend")}
                          </ResendButton>
                        </>
                      )}
                    </ResendOtpWrapper>
                  </FormInput>
                </Form>
                <div id="recaptca-container"></div>
              </FormWrapper>
            </RightSecton>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Verification;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};

  @media (max-width: 991px) {
    height: 100%;
  }
`;
const HeaderWrapper = styled.div`
  display: none;

  @media (max-width: 991px) {
    display: block;
    position: fixed;
    width: 100%;
  }
`;
const LeftSection = styled.div`
  background-color: #feefef;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: ${({ isLanguage }) =>
    isLanguage === "ar" ? "center" : "flex-end"};
  align-items: center;

  @media (max-width: 991px) {
    background-color: transparent;
    height: 100%;
    margin-top: 5rem;
  }
`;
const Section = styled.div`
  height: auto;
  width: 64%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  margin-right: 2rem;
  @media (max-width: 1180px) {
    width: 70%;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    gap: 1rem;
    svg {
      width: ${({ isMobile }) => isMobile && "300px"};
    }
  }
`;
const LeftHeading = styled.h2`
  font-size: 24px;
  line-height: ${({ isLanguage }) => (isLanguage === "ar" ? "45px" : "34px")};
  font-weight: 700;
  text-align: center;
  color: #060a32;
  padding: 0 1rem;
  margin-top: 2rem;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  @media (max-width: 991px) {
    margin: 0;
  }
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;
const Disc = styled.p`
  font-size: 18px;
  line-height: ${({ isLanguage }) => (isLanguage === "ar" ? "36px" : "26px")};
  font-weight: 500;
  text-align: center;
  color: #3b4c66;
  margin-top: 1rem;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    gap: 1rem;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    gap: 0.5rem;
  }
`;

const Image = styled.img`
  height: ${({ isHeight }) => isHeight && "60px"};

  @media (max-width: 991px) {
    width: ${({ isMobile }) => isMobile && "230px"};
  }
`;

const RightSecton = styled.div`
  width: 100%;
  height: 100vh;

  @media (max-width: 991px) {
    height: 100%;
  }
`;
const HeaderDesktop = styled.div`
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

const BtnWrapper = styled.div`
  width: ${({ isLanguag }) => (isLanguag === "ar" ? "80%" : "50%")};
  height: auto;
  display: flex;
  justify-content: ${({ isLanguag }) =>
    isLanguag === "ar" ? "flex-start" : "flex-end"};
  align-items: flex-end;
  gap: 0.8rem;
  align-items: center;
`;
const LangWrapper = styled.div`
  height: 23px;
  width: 72px;
  display: flex;
  border-radius: 900px;
  border: 1px solid #f55a65;
  overflow: hidden;
`;

const HeaderText = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  font-size: 16px;
  font-weight: 500;
  color: #f55a65;

  line-height: 20.16px;
  margin-bottom: 0.2rem;
`;
const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-top: ${({ isLanguage }) => (isLanguage === "ar" ? "13rem" : "4rem")};

  padding-right: ${({ isLanguage }) => isLanguage === "ar" && "4rem"};

  @media (max-width: 991px) {
    padding: 0;
    padding-top: ${({ isLanguage }) => (isLanguage === "ar" ? "3rem" : "3rem")};
  }
`;
const Form = styled.div`
  width: 100%;
`;
const Heading = styled.h2`
  cursor: pointer;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  letter-spacing: 1px;
  color: #060a32;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;

  @media (max-width: 991px) {
    display: none;
  }
`;
const FormInput = styled.div`
  width: 80%;
  margin-left: 2rem;

  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
    padding-top: 0;
  }
`;
const InputsWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
  direction: ${({ isRight }) => isRight && "ltr"};
`;

const ButtonOne = styled.button`
  background-color: ${({ isLanguage }) =>
    isLanguage === "en" ? "#f55a65" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "en" ? "#FFFFFF" : "#f55a65")};
  border: none;
  outline: none;
  cursor: pointer;
  width: 36px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
`;

const ButtonTwo = styled.button`
  background-color: ${({ isLanguage }) =>
    isLanguage === "ar" ? "#f55a65" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "ar" ? "#FFFFFF" : "#f55a65")};
  border: none;
  outline: none;
  cursor: pointer;
  width: 36px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
`;

const CustomOtp = styled(OTPInput)`
  width: 100%;
  direction: ltr;
`;
const CustomInputOtp = styled.input`
  width: 50px !important;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 600;
`;
const ResendOtpWrapper = styled.div`
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
const ResendButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  color: #f55a65;
  font-size: 16px;
  font-weight: 500;
`;
const ResendText = styled.p`
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  font-size: 16px;
  font-weight: 300;
`;
const WaitingText = styled.p`
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  font-size: 16px;
  font-weight: 400;
`;

const Span = styled.span`
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  font-size: 16px;
  font-weight: 500;
  color: #f55a65;
`;
