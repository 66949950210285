// reducers.js

import { FETCH_BANK_DETAIL_REQUEST, FETCH_BANK_DETAIL_SUCCESS, FETCH_BANK_DETAIL_FAILURE } from "./types";



const initialState = {
  data: null,
  loading: false,
  error: ''
};

const bankDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BANK_DETAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_BANK_DETAIL_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };
    case FETCH_BANK_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default bankDetailReducer;
