import React, { useContext } from "react";
import { Success } from "../../Responses/Responses";
import styled from "styled-components";
import { getCookie, removeAllCookie } from "../../Utils/storageVariable";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BaseService } from "../../Api/base";
import { FormDataContext } from "../../App";

const Logout = ({ background }) => {
  const { t, i18n } = useTranslation();
  const user = getCookie("token");
  const navigate = useNavigate();
  const { setData } = useContext(FormDataContext);

  const confirm = async () => {
    setData({
      merchant: undefined,
      merchantAdmin: undefined,
      businessHours: undefined,
      categories: undefined,
      services: undefined,
    });

    try {
      await BaseService.remove("/employeeAuth/logout");
      Success("Logout Successfully");
      navigate("/auth");
      removeAllCookie();
    } catch (e) {
      removeAllCookie();
      navigate("/auth");
    }
  };

  return (
    <>
      {user && (
        <Container>
          <Badge isBackground={background} onClick={confirm}>
            {t("signOutText")}
          </Badge>
        </Container>
      )}
    </>
  );
};

export default Logout;
const Container = styled.div``;
const Badge = styled.div`
  width: ${({ isBackground }) => (isBackground ? "max-content" : "130px")};
  padding: 0.4rem 1rem;
  background-color: ${({ isBackground }) =>
    isBackground ? isBackground : "#F3967D"};
  border-radius: ${({ isBackground }) => (isBackground ? "25px" : "25px")};
  padding-top: ${({ isBackground }) => (isBackground ? "0.4rem" : "1rem")};
  padding-bottom: ${({ isBackground }) => (isBackground ? "0.4rem" : "1rem")};

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: mont-Regular;
  color: #ffff;
  cursor: pointer;

  @media (max-width: 400px) {
    font-size: 11px;
    margin-right: 0.5rem;
  }
`;
