import { Navigate } from "react-router-dom";
import { getCookie } from "../../Utils/storageVariable";

export const useProtectedRoutes = () => {

  const ProtectedRoute = ({ redirectPath = "/auth", children }) => {
    if (!getCookie("token")) {
      return <Navigate to={redirectPath} />;
    }

    return children;
  };

  const UnprotectedRoute = ({ redirectPath = "/dashboard", children }) => {
    if (getCookie("token")) {
      return <Navigate to={redirectPath} />;
    }

    return children;
  };

  return { ProtectedRoute, UnprotectedRoute };
};
