// reducers.js

import { ADD_PRODUCTS_FAILURE, ADD_PRODUCTS_SUCCESS, ADD_PRODUCTS_REQUEST } from "./types";



const initialState = {
  data: null,
  loading: false,
  error: ''
};

const addProductsSReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_PRODUCTS_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };
    case ADD_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default addProductsSReducer;
