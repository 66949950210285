import * as Yup from "yup";

export const InformationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(30, "Maximum 30 characters"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Work Email is required"),
});

export const MerchandDetailSchema = Yup.object().shape({
  type: Yup.string().required("Merchant Type is required"),
  businessName: Yup.string().required("Business Name is required"),
  businessNameAr: Yup.string().required("Business Name (Arabic) is required"),
  nationalAddress: Yup.string().required("National Address is required"),
  latitude: Yup.string(),
  registrationDocument: Yup.mixed().required(
    "Business Registration Document is required"
  ),
  longitude: Yup.string(),
  logo: Yup.mixed().required("Business Logo is required"),
});

export const serviceDetailSchema = Yup.object().shape({
  //portfolioImages: Yup.mixed().required("Portofolio images are required"),
  // visbility: Yup.string().required("Visbility is required"),
  // targetCustomers: Yup.array(),
  // panelScehama: Yup.array().of(
  //   Yup.object().shape({
  //     service: Yup.string().required("Service name is required"),
  //     cost: Yup.string().required("Cost is required"),
  //     description: Yup.string().required("Description is required"),
  //   })
  // ),
  // Add validation rules for other fields
  productList: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Service Name (English) is required"),
      description: Yup.string().required("description is required"),
      descriptionAr: Yup.string().required("descriptionAr is required"),
      nameAr: Yup.string().required("Service Name (Arabic) is required"),
      // subcategory: Yup.string().required("Subcategory (English) is required"),
      // subcategoryAr: Yup.string().required("Subcategory (Arabic) is required"),
      timeInMinutes: Yup.number().required("Time in Minutes is required"),
      cost: Yup.number().required("Service Cost is required"),
      // Add validation rules for other fields in panelScehama
    })
  ),
  customerNotes: Yup.array().of(
    Yup.object().shape({
      body: Yup.string().required("remarks is Required"),
      title: Yup.string().required("title is Required"),

      // Add validation rules for other fields in panelScehama
    })
  ),
});

export const teamDetailSchema = Yup.object().shape({
  memberList: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid email format").optional(),
      phoneNumber: Yup.string().required("Phone number is required"),
    })
  ),
  // Add validation rules for other fields
});
export const employDetailSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"), // Ensure first name is not empty
  email: Yup.string().email("Invalid email format").optional(),
  phoneNumber: Yup.string().required("Phone number is required"),
});
export const bankDetailSchema = Yup.object().shape({
  // name: Yup.string().required("bank name is required"),
  // accountNumber: Yup.string().required("account number is required"),
  // accountName: Yup.string().required("account name is required"),
  // consent: Yup.boolean(),
});

export const signUpSchema = Yup.object().shape({
  // Define validation rules for your form fields
  // For example:
  phoneNumber: Yup.string().required("Phone number is required"),
  // Add more validation rules as needed
});

export const adsSchema = Yup.object().shape({
  dashboard: Yup.boolean(),
  explore: Yup.boolean(),
  ranking: Yup.boolean(),
  date: Yup.date().required("date is required"),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date must be later than start date"),
});
