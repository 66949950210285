export const getCookie = key => {
  return localStorage.getItem(key)
}
export const setCookie = (key, value) => {
  return localStorage.setItem(key, value)
}

export const removeCookie = key => {
  return localStorage.removeItem(key)
}

export const removeAllCookie = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("refreshToken")
  localStorage.removeItem("name")
  localStorage.removeItem("currentStep")



  return
}