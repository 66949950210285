import React, { useState } from "react";
import { Header } from "../../Components/Header/Header";
import styled from "styled-components";
import { Steps } from "antd";
import PersonalInfo from "../../Pages/Mercant/PersonalInfo";
import MerchantDetails from "../../Pages/Mercant/MerchantDetails";
import BussinessSchedules from "../../Pages/Mercant/BussinessSchedules";
import TeamDetails from "../../Pages/Mercant/TeamDetails";
import BankDetails from "../../Pages/Mercant/BankDetails";
import ServiceDetails from "../../Pages/Mercant/ServiceDetails";
import { ReactComponent as ProfileIcon } from "./Profile.svg";
import { ReactComponent as MerchantDetail } from "./merchantdetail.svg";
import { ReactComponent as ServiceDetail } from "./servicedetail.svg";
import { ReactComponent as BusinessDetail } from "./businessdetail.svg";
import { ReactComponent as TeamDetailOne } from "./teamdetail.svg";
import { ReactComponent as BankDetailOne } from "./bankdetail.svg";
import { useTranslation } from "react-i18next";
import { getCookie, setCookie } from "../../Utils/storageVariable";

const Merchant = ({
  refetch,
  fetchTimeSlots,
  fetchServices,
  fetchEmployees,
}) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n?.language);
  const currentStep = getCookie("currentStep");
  const [current, setCurrent] = useState(currentStep ? +currentStep : 0);

  const next = () => {
    setCurrent(+current + 1);
    setCookie("currentStep", current + 1);
  };
  const prev = () => {
    setCurrent(+current - 1);
    setCookie("currentStep", current - 1);
  };

  const renderWordsOnNewLines = (text) => {
    const words = text.split(" ");
    return words.map((word, index) => (
      <span key={index}>
        {word}
        <br />
      </span>
    ));
  };

  const handleLanguageChange = (language) => {
    setCookie("language", language);
    i18n.changeLanguage(language);
    setLang(language);
  };

  const steps = [
    {
      title: "",
      name: ` ${t("merchant.nameOne")}`,
      img: <ProfileIcon />,
      content: (
        <PersonalInfo
          isUpdate={false}
          nextStep={next}
          prevStep={prev}
          refetch={refetch}
        />
      ),
    },
    {
      title: "",
      name: ` ${t("merchant.nameTwo")}`,
      img: <MerchantDetail />,
      content: (
        <MerchantDetails
          isUpdate={false}
          nextStep={next}
          prevStep={prev}
          refetch={refetch}
        />
      ),
    },
    {
      title: "",
      name: ` ${t("merchant.nameThree")}`,
      img: <ServiceDetail />,
      content: (
        <ServiceDetails
          isUpdate={false}
          nextStep={next}
          prevStep={prev}
          refetch={refetch}
          fetchServices={fetchServices}
        />
      ),
    },
    {
      title: "",
      name: ` ${t("merchant.nameFour")}`,
      img: <BusinessDetail />,
      content: (
        <BussinessSchedules
          isUpadate={false}
          nextStep={next}
          prevStep={prev}
          fetchTimeSlots={fetchTimeSlots}
        />
      ),
    },
    {
      title: "",
      name: ` ${t("merchant.nameFive")}`,
      img: <TeamDetailOne />,
      content: (
        <TeamDetails
          isUpadate={false}
          nextStep={next}
          prevStep={prev}
          fetchEmployees={fetchEmployees}
        />
      ),
    },
    {
      title: "",
      name: ` ${t("merchant.nameSix")}`,
      img: <BankDetailOne />,
      content: (
        <BankDetails isUpadate={false} nextStep={next} prevStep={prev} refetch={refetch}/>
      ),
    },
  ];
  const items = steps?.map((item, index) => ({
    key: index,
    title: (
      <div>
        <Text>
          <IconWrapper isActive={current === index}>{item?.img}</IconWrapper>
          <SmallHeading isLanguage={i18n.language} isActive={current === index}>
            {renderWordsOnNewLines(item?.name)}
          </SmallHeading>
        </Text>
      </div>
    ),
  }));

  return (
    <MainContainer isLanguage={lang}>
      <Header
        setLang={setLang}
        handleLanguageChange={handleLanguageChange}
        lang={lang}
        t={t}
      />

      <Container>
        <StepWrapper isLanguage={i18n.language}>
          <Steps current={current} items={items} />
        </StepWrapper>

        <Wrapper>
          <div>{steps[current].content}</div>
        </Wrapper>
      </Container>
    </MainContainer>
  );
};
export default Merchant;
const MainContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff9f5;
  overflow: hidden;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
`;

const Container = styled.div`
  width: 100%;
  margin: 7rem auto;

  .ant-steps-item-icon {
    display: none !important;
  }
  .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #d0d5dd !important;
    height: 1px;
    margin-top: 1rem;
  }
`;

const Text = styled.p`
  height: 131px;
`;
const IconWrapper = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${({ isActive }) => (isActive ? "#f55a65" : "#EAECF0")};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 auto;

  svg {
    path {
      stroke: ${({ isActive }) => (isActive ? "#FFFF" : "#344054")} !important;
    }
  }
`;
const SmallHeading = styled.p`
  color: ${({ isActive }) => (isActive ? "#f55a65" : "#344054")};
  font-weight: 500;
  font-size: 14px;
  line-height: ${({ isLanguage }) => (isLanguage === "ar" ? "25px" : "20px")};
  text-align: center;
  span {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 78vh;
  overflow: auto;
  margin-top: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const StepWrapper = styled.div`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 820px) {
    width: 90%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0px;
    }

    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: green;
    }
    .ant-steps {
      width: 130vw !important;
    }
    .ant-steps.ant-steps-vertical {
      display: flex;
      flex-direction: row;
    }
    .ant-steps-item {
      width: 33vw;
    }
    .ant-steps-item-container {
    }
    .ant-steps.ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      height: 1px;
      background-color: #d0d5dd !important;
      margin-left: ${({ isLanguage }) => isLanguage === "en" && "3.5rem"};
      position: absolute;
      top: 2rem;
      margin-right: ${({ isLanguage }) => isLanguage === "ar" && "2.5rem"};
      width: 15vw !important;
    }
  }
`;
