import { uploadFiles } from "../Utils/AWSS3/AwsConfig";
import { BaseService } from "../Api/base";


export const uploadImages = async (images, type) => {
  await uploadFiles(images, type);
  const keys = images.map((image) => image.name);

  const response = await BaseService.post("/merchantAdmins/images", {
    images: keys.map((key) => ({
      url: process.env.REACT_APP_S3_BASE_URL + key,
      title: key.split(".")[0],
      type,
    })),
  });

  return response.data.data;
};
