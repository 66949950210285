import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { fetchMerchant } from "../../Redux/Merchant/action";
import { useDispatch } from "react-redux";
import { Header } from "../../Components/Header/Header";
import styled from "styled-components";
import { Col, Drawer, Row } from "antd";
import SideNav from "../../Components/SideNav/SideNav";
import { useTranslation } from "react-i18next";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { removeAllCookie, setCookie } from "../../Utils/storageVariable";
import { app } from "../../config";
import { CloseCircleOutlined } from "@ant-design/icons";
import Button from "../../Elements/Button/Button";
import { ReactComponent as LogoIcon } from "./logo.svg";
import AppointmentChart from "../../Components/AppointmentChart/AppointmentChart";
import ChurnRateChart from "../../Components/ChurnRate/ChurnRate";
import RevenueChart from "../../Components/RevenueGenerated/RevenueGenerated";
import NewVsRegularChart from "../../Components/NewVsRegularChart/NewVsRegularChart";
import CustomerRetentionChart from "../../Components/CustomerRetention/CustomerRetention";
import CustomerSatisfactionChart from "../../Components/CustomerSatisfactionChart/CustomerSatisfactionChart";
import CustomerSatisfactionPerEmployeeChart from "../../Components/CustomerSatisfactionPerEmployee/CustomerSatisfactionPerEmployee";
import CustomerGrowthChart from "../../Components/CustomerGrowthChart/CustomerGrowthChart";
import { Success } from "../../Responses/Responses";
import { BaseService } from "../../Api/base";

const Dashboard = () => {
  const [name, setName] = useState("");
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [lang, setLang] = useState(i18n?.language);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(fetchMerchant());
  // }, []);

  // useEffect(() => {
  //   const auth = getAuth(app);
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       setCookie("token", user?.accessToken);
  //     } else {
  //     }
  //   });

  //   return () => unsubscribe();
  // }, []);
  const menuItems = [
    {
      name: `${t("sideNavContent.dashboard")}`,
      path: "/dashboard",
      key: "dashboard",
    },
    {
      name: `${t("sideNavContent.personalInfo")}`,
      path: "/dashboard/personal-info",
      key: "personal-info",
    },
    {
      name: `${t("sideNavContent.merchantDetail")}`,
      path: "/dashboard/merchant-detail",
      key: "merchant-detail",
    },
    {
      name: `${t("sideNavContent.serviceDetail")}`,
      path: "/dashboard/service-detail",
      key: "service-detail",
    },
    {
      name: `${t("sideNavContent.BusinessDetail")}`,
      path: "/dashboard/business-detail",
      key: "business-schedules",
    },
    {
      name: `${t("sideNavContent.teamDetails")}`,
      path: "/dashboard/team-detail",
      key: "team-details",
    },
    {
      name: `${t("sideNavContent.bankDetails")}`,
      path: "/dashboard/bank-detail",
      key: "bank-details",
    },
    { name: `${t("sideNavContent.ads")}`, path: "/dashboard/ads", key: "ads" },
  ];
  const navigate = useNavigate();
  const handleNavigate = (item) => {
    navigate(item?.path);
    setOpen(!open);
    setName(item?.name);
  };

  const location = useLocation();
  const items = [
    { name: `${t("sideNavContent.dashboard")}`, path: "/dashboard" },
    {
      name: `${t("sideNavContent.personalInfo")}`,
      path: "/dashboard/personal-info",
    },
    {
      name: `${t("sideNavContent.merchantDetail")}`,
      path: "/dashboard/merchant-detail",
    },
    {
      name: `${t("sideNavContent.serviceDetail")}`,
      path: "/dashboard/service-detail",
    },
    {
      name: `${t("sideNavContent.teamDetails")}`,
      path: "/dashboard/team-detail",
    },
    {
      name: `${t("sideNavContent.BusinessDetail")}`,
      path: "/dashboard/business-detail",
    },
    {
      name: `${t("sideNavContent.bankDetails")}`,
      path: "/dashboard/bank-detail",
    },
    { name: `${t("sideNavContent.ads")}`, path: "/dashboard/ads" },
  ];

  useEffect(() => {
    items?.map((item) => {
      if (location.pathname === item?.path) {
        setName(item?.name);
      }
    });
  }, [location.pathname, i18n.language]);
  const handleLogout = async () => {
    try {
      await BaseService.remove("/employeeAuth/logout");
      Success("Logout Successfully");
      navigate("/auth");
      removeAllCookie();
    } catch (e) {
      removeAllCookie();
      navigate("/auth");
    }
  };

  return (
    <Container isLanguage={lang}>
      <Header open={open} setOpen={setOpen} lang={lang} setLang={setLang} />
      <SubContainer>
        <Drawer
          className="container"
          title={
            <WrapIcon>
              {" "}
              <Logo isStart onClick={() => navigate("/")}>
                <LogoIcon fill="#FFF" /> beautisry
              </Logo>{" "}
              <CloseCircleOutlined
                onClick={() => setOpen(!open)}
                style={{
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  color: "#FFFF",
                }}
              />
            </WrapIcon>
          }
          placement={"left"}
          closable={false}
          onClose={() => setOpen(!open)}
          open={open}
          key={"left"}
        >
          <ListWrapp isLanguage={lang}>
            <UL>
              {menuItems?.map((item, i) => (
                <>
                  <LI
                    isLanguage={lang}
                    onClick={() => handleNavigate(item)}
                    key={i}
                  >
                    {item?.name}
                  </LI>
                </>
              ))}
            </UL>
            <ButtonWrapper>
              <Button
                isLanguage={lang}
                primary
                onClick={() => handleLogout()}
              >{`${t("sideNavContent.logout")}`}</Button>
            </ButtonWrapper>
          </ListWrapp>
        </Drawer>

        <StyledRow>
          <Col xxl={4} xl={4} lg={6}>
            <SideNav lang={lang} setLang={lang} />
          </Col>
          <Col xxl={20} xl={20} lg={18} md={24} sm={24} xs={24}>
            <OutletContainer>
              <SubHeader>
                <Heading isLanguage={lang}>{name}</Heading>
              </SubHeader>
              {location.pathname === "/dashboard" ? (
                <ChartsContainer>
                  <Row gutter={[24, 24]}>
                    <Col xl={9} lg={24} md={24} sm={24} xs={24}>
                      <AppointmentChart lang={lang} t={t} />
                    </Col>
                    <Col xl={15} lg={24} md={24} sm={24} xs={24}>
                      <RevenueChart t={t} lang={lang} />
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} style={{ marginTop: "1rem" }}>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                      <NewVsRegularChart t={t} />
                    </Col>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                      <CustomerRetentionChart t={t} lang={lang} />
                    </Col>
                  </Row>
                  <Row gutter={[24, 24]} style={{ marginTop: "1rem" }}>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                      <ChurnRateChart t={t} lang={lang} />
                    </Col>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                      <CustomerSatisfactionChart t={t} lang={lang} />
                    </Col>
                  </Row>
                  <Row
                    gutter={[24, 24]}
                    style={{
                      marginTop: "1rem",
                    }}
                  >
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                      <CustomerSatisfactionPerEmployeeChart t={t} lang={lang} />
                    </Col>
                    <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                      <CustomerGrowthChart t={t} lang={lang} />
                    </Col>
                  </Row>
                </ChartsContainer>
              ) : (
                <FormWrapper>
                  {" "}
                  <Outlet />
                </FormWrapper>
              )}
            </OutletContainer>
          </Col>
        </StyledRow>
      </SubContainer>
    </Container>
  );
};

export default Dashboard;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff9f5;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
`;
const SubContainer = styled.div`
  padding-top: 4.5rem;
`;

const Heading = styled.h2`
  font-size: 18px;
  color: #222222;
  font-weight: 500;
  margin: 0;
  padding: 0;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  @media (max-width: 600px) {
    padding-left: 1rem;
    padding: ${({ isLanguage }) =>
      isLanguage === "ar" ? `0 1rem` : "0 1rem"} !important;
  }
`;
const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 1.5rem;
  padding-bottom: 0;
  @media (max-width: 991px) {
    padding: 1rem;
    width: calc(100%);
  }
  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
const SubHeader = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #e9e4e0;

  display: flex;
  align-items: center;
`;

const FormWrapper = styled.div`
  height: 84vh;
  overflow: auto;

  @media (max-width: 991px) {
    .ant-col-xl-4 {
      height: max-content;
      margin-top: 2rem auto;
    }
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
const StyledRow = styled(Row)`
  @media (max-width: 991px) {
    .ant-col-xl-4 {
      display: none;
    }
  }
`;

const WrapIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.h1`
  cursor: pointer;
  color: #ffff;
  font-size: ${({ isLanguage }) => (isLanguage === "ar" ? "32px" : "32px")};
  margin-left: ${({ isLanguage }) => isLanguage === "ar" && "auto"};
  margin-right: 20px;
  display: flex;
  justify-content: ${({ isStart }) => (isStart ? "flex-start" : "center")};
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-family: mont-Semibold;
`;

const ListWrapp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
`;
const LI = styled.li`
  font-size: 18px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  color: #ffff;
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  &:active {
    background-color: #2e3153;
  }
`;
const UL = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.div``;

const ChartsContainer = styled.div`
  width: 100%;
  margin-top: 1.4rem;
  @media (max-width: 600px) {
    padding: 0 0.6rem;
    overflow-x: hidden;
  }
`;
