// src/actions/postActions.js
import { BaseService } from "../../Api/base";
import {
  FETCH_PRODUCT_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
} from "./types";

export const fetchProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_PRODUCT_REQUEST });
      const { data } = await BaseService.get("/merchants/products");

      dispatch({ type: FETCH_PRODUCT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: FETCH_PRODUCT_FAILURE, payload: error.message });
    }
  };
};
