import { BaseService } from '../../Api/base';
import { Error } from '../../Responses/Responses';
import { FETCH_EMPLOYEE_FAILURE, FETCH_EMPLOYEE_SUCCESS, FETCH_EMPLOYEE_REQUEST } from "./types";

export const fetchEmployeeList = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: FETCH_EMPLOYEE_REQUEST });
      const { data } = await BaseService.get("/employees/invites");

      dispatch({ type: FETCH_EMPLOYEE_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message)
      dispatch({ type: FETCH_EMPLOYEE_FAILURE, payload: error.message });
    }
  };
};

export const updateEmployee = (employees) => {
  return async (dispatch) => {


    dispatch({ type: FETCH_EMPLOYEE_SUCCESS, payload: employees });

  };
};