export function arraysEqual(a, b) {
    if (a.length !== b.length)
        return false;
    for (let i = 0; i < a.length; i++) {
        if (Array.isArray(a[i]) && Array.isArray(b[i])) {
            if (!arraysEqual(a[i], b[i]))
                return false;
        } else if (typeof a[i] === 'object' && typeof b[i] === 'object') {
            if (isObjectChanged(a[i], b[i]))
                return false;
        } else if (a[i] !== b[i]) {
            return false;
        }
    }
    return true;
}


export function isObjectChanged(obj1 = {}, obj2 = {}) {
    // Function to compare if two arrays are equal
    if (!obj1 || !obj2) return
    const keys1 = Object?.keys(obj1);
    const keys2 = Object?.keys(obj2);

    // Check if both objects have the same number of properties
    if (keys1?.length !== keys2?.length) {
        return true;
    }

    for (const key of keys1) {
        if (!keys2?.includes(key)) {
            return true;
        }

        const value1 = obj1[key];
        const value2 = obj2[key];

        // Check if the values are arrays and compare them
        if (Array.isArray(value1) && Array.isArray(value2)) {
            if (!arraysEqual(value1, value2)) {
                return true;
            }
        } else if (typeof value1 === 'object' && typeof value2 === 'object') {
            // Perform a recursive check for nested objects
            if (isObjectChanged(value1, value2)) {
                return true;
            }
        } else if (value1 !== value2) {
            return true;
        }
    }

    // If no changes are found
    return false;
}

