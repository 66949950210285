import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Logout from "../Logout/Logout";
import { useTranslation } from "react-i18next";
import { setCookie } from "../../Utils/storageVariable";
import { ReactComponent as NavIcon } from "./NavIcon.svg";
import { ReactComponent as ArLogo } from "./arLogo.svg";

import { ReactComponent as Logo } from "./logo.svg";
import { useLocation, useNavigate } from "react-router-dom";

export const Header = ({
  lang,
  setLang,
  open,
  setOpen = () => {},
  isTranparent,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleLanguageChange = (language) => {
    setCookie("language", language);
    i18n.changeLanguage(language);
    setLang(language);
  };
  const location = useLocation();
  const [isDashboard, setIsDashboard] = useState(false);

  useEffect(() => {
    const path = location.pathname;

    if (path.startsWith("/dashboard")) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [location.pathname]);

  return (
    <CustomHeader isTranparent={isTranparent} isLanguage={i18n.language}>
      <Heading isLanguage={i18n.language} onClick={() => navigate("/")}>
        {i18n.language === "ar" && <ArLogo />}
        {i18n.language === "en" && <Logo />} {lang === "en" && "beautisry"}
      </Heading>
      <BtnWrapper isLanguage={lang}>
        <LangWrapper>
          <ButtonOne
            isLanguage={i18n.language}
            onClick={() => handleLanguageChange("en")}
          >
            EN
          </ButtonOne>
          <ButtonTwo
            isLanguage={i18n.language}
            onClick={() => handleLanguageChange("ar")}
          >
            AR
          </ButtonTwo>
        </LangWrapper>
        <HeaderText isLanguage={i18n.language} isText={isDashboard}>
          {t("merchant.englishText")}
        </HeaderText>

        {isDashboard ? (
          ""
        ) : (
          <LogutWrap>
            <Logout background={"#f55a65"} />
          </LogutWrap>
        )}
        <Icon isText={isDashboard}>
          <NavIcon onClick={() => setOpen(!open)} />
        </Icon>
      </BtnWrapper>
    </CustomHeader>
  );
};

const CustomHeader = styled.div`
  height: 80px;
  width: 100%;
  background-color: ${({ isTranparent }) =>
    isTranparent ? "#FFFF" : "#feefef"};
  display: flex;
  align-items: center;
  position: fixed;
  max-width: 1600px;
  
  z-index: 99;

  
  flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "row-reverse"};
  justify-content: ${({ isLanguage }) => isLanguage === "ar" && "center"};
  border-bottom: ${({ isTranparent }) => isTranparent && "1px solid #E6E6E6"};
  @media (max-width: 1440px) {
    width: 100%;
  }
  @media (max-width: 820px) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 0.5rem;
    flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "row"};
  }
`;
const Heading = styled.h2`
  cursor: pointer;
  font-size: 28px;
  font-weight: 700;
  display: flex;
  letter-spacing: 0.5px;
  color: #060a32;
  gap: 0.5rem;
  display: flex;
  font-family: mont-semibold;
  justify-content: ${({ isLanguage }) =>
    isLanguage === "ar" ? "flex-start" : "flex-end"};
  align-items: center;
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "45%" : "55%")};
  @media (max-width: 820px) {
    width: auto;
  }
`;


const BtnWrapper = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "41%" : "38%")};
  height: auto;
  display: flex;
  justify-content: ${({ isLanguage }) =>
    isLanguage === "ar" ? "flex-start" : "flex-end"};
  align-items: flex-end;
  gap: 0.8rem;
  align-items: center;

  @media (max-width: 820px) {
    width: ${({ isLanguage }) => (isLanguage === "ar" ? "auto" : "auto")};
  }
`;
const LangWrapper = styled.div`
  height: 23px;
  width: 72px;
  display: flex;
  border-radius: 900px;
  border: 1px solid #f55a65;
  overflow: hidden;
`;

const HeaderText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  color: #f55a65;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};

  line-height: 20.16px;
  margin-bottom: 0.2rem;

  @media (max-width: 820px) {
    display: none;
  }
`;
const ButtonOne = styled.button`
  background-color: ${({ isLanguage }) =>
    isLanguage === "en" ? "#f55a65" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "en" ? "#FFFFFF" : "#f55a65")};
  border: none;
  outline: none;
  cursor: pointer;
  width: 36px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
`;

const ButtonTwo = styled.button`
  
  background-color: ${({ isLanguage }) =>
    isLanguage === "ar" ? "#f55a65" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "ar" ? "#FFFFFF" : "#f55a65")};
  border: none;
  outline: none;
  cursor: pointer;
  width: 36px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Outfit", sans-serif;
`;

const LogutWrap = styled.div`
 
`;
const Icon = styled.div`
  display: none;
  @media (max-width: 820px) {
    display: ${({ isText }) => isText && "block"};
    margin-left: 1rem;
    margin-top: 0.2rem;
    cursor: pointer;
  }
`;
