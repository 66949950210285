import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Col, Row, Drawer } from "antd";
import { Success } from "../../Responses/Responses";
import { CloseCircleOutlined } from "@ant-design/icons";
import { services, customer, useCarousel } from "../../Utils/constants";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setCookie } from "../../Utils/storageVariable";
import Logout from "../../Components/Logout/Logout";
import { ReactComponent as Mobile1 } from "./IMG1.svg";
import { ReactComponent as Mobile2 } from "./IMG2.svg";
import { ReactComponent as Mobile3 } from "./IMG3.svg";
import axios from "axios";

const Home = () => {
  const [open, setOpen] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const formRef = useRef(null);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const { scrollRef, activePageIndex } = useCarousel(customer, 4);
  const {
    scrollRef: serviceScrollRef,
    activePageIndex: activeServicePageIndex,
  } = useCarousel(services, 4);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n?.language);

  const handleLanguageChange = (language) => {
    setCookie("language", language);
    i18n.changeLanguage(language);
    setLang(language);
  };

  const baseUrl = process.env.REACT_APP_BASE_PURE_URL;
  const currentLanguage = i18n.language === "ar" ? "ar" : "en";
  const termsAndConditionsLink = `${baseUrl}/Terms&Conditions-${currentLanguage}.html`;
  const privacyPolicyLink = `${baseUrl}/Privacy-Policy-${currentLanguage}.html`;

  const onClickLink = (link) => {
    window.open(link, "_blank");
  };

  const navigate = useNavigate();

  const onSaveContactRequest = async (event) => {
    event.preventDefault();
    setFormLoading(true);

    const name = event.target["name"].value;
    const email = event.target["email"].value;
    const phoneNumber = event.target["phone"].value;
    const message = event.target["message"].value;

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/contactRequests",
        {
          contactRequest: { name, email, phoneNumber, message },
        }
      );
      Success("Your request has been sent successfully");
      formRef.current.reset(); // Reset the form
    } catch (e) {}
  };

  const LogoImage = styled.img`
    height: 80px;
    @media (max-width: 768px) {
      height: 50px;
    }
  `;

  const onClickLogo = () => {
    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Container isLanguage={lang}>
      <NavWrap isLanguage={i18n.language}>
        <NavbarContainer isLanguage={lang}>
          <LeftSection isLanguage={lang}>
            <Logo isMargin isLanguage={lang} onClick={onClickLogo}>
              {lang === "ar" && <img src="./Assets/beautry.svg" />}
              {lang === "en" && (
                <LogoImage src="./Assets/logo.svg" alt="Logo" />
              )}
              {lang === "en" && "beautisry"}
            </Logo>
            <MenuIcon onClick={showDrawer}>
              {" "}
              <Image src="./Assets/menu.svg" alt="Menu" />
            </MenuIcon>
          </LeftSection>
          <RightSection isLanguage={lang}>
            <NavItem>
              <Link isLanguage={lang} href="#HowItWorks">
                {t("home.HowItWorks")}
              </Link>{" "}
            </NavItem>
            <NavItem>
              <Link isLanguage={lang} href="#Services">
                {t("home.Services")}
              </Link>
            </NavItem>
            <NavItem>
              <Link isLanguage={lang} href="#WhyChooseUs">
                {t("home.WhyChooseUs")}
              </Link>
            </NavItem>
            <NavItem>
              <Link isLanguage={lang} href="#Testimonials">
                {t("home.Testimonials")}
              </Link>
            </NavItem>
            <WrapBtn isLanguage={lang}>
              <ActionButton
                isLanguage={lang}
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_BASE_PURE_URL}/merchantForm?lang=${i18n.language}`,
                    "_blank"
                  )
                }
              >
                {t("home.ForMerchant")}
              </ActionButton>
              <ButtonWrapper>
                <ButtonOne
                  isLanguage={lang}
                  onClick={() => handleLanguageChange("en")}
                >
                  EN
                </ButtonOne>
                <ButtonTwo
                  isLanguage={lang}
                  onClick={() => handleLanguageChange("ar")}
                >
                  AR
                </ButtonTwo>
              </ButtonWrapper>
            </WrapBtn>
          </RightSection>
        </NavbarContainer>
      </NavWrap>
      <Drawer
        title={
          <WrapIcon>
            {" "}
            <Logo isStart onClick={onClickLogo}>
              {lang === "ar" && <img src="./Assets/beautry.svg" />}
              {lang === "en" && (
                <LogoImage src="./Assets/logo.svg" alt="Logo" />
              )}{" "}
              {lang === "en" && "beautisry"}
            </Logo>{" "}
            <CloseCircleOutlined
              onClick={onClose}
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          </WrapIcon>
        }
        placement={"left"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"left"}
      >
        <NavItemWrapper>
          <NavItem>
            <Link href="#HowItWorks">{t("home.HowItWorks")}</Link>{" "}
          </NavItem>
          <NavItem>
            <Link href="#Services">{t("home.Services")}</Link>
          </NavItem>
          <NavItem>
            <Link href="#WhyChooseUs">{t("home.WhyChooseUs")}</Link>
          </NavItem>
          <NavItem>
            <Link href="#Testimonials">{t("home.Testimonials")}</Link>
          </NavItem>

          <Wrap>
            <ActionButton
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_BASE_PURE_URL}/merchantForm?lang=${i18n.language}`,
                  "_blank"
                )
              }
            >
              {t("home.ForMerchant")}
            </ActionButton>

            <ButtonWrapper>
              <ButtonOne
                isLanguage={lang}
                onClick={() => handleLanguageChange("en")}
              >
                EN
              </ButtonOne>
              <ButtonTwo
                isLanguage={lang}
                onClick={() => handleLanguageChange("ar")}
              >
                AR
              </ButtonTwo>
            </ButtonWrapper>
            <Logout />
          </Wrap>
        </NavItemWrapper>
      </Drawer>
      <WrapperCont isLanguage={i18n.language} id="home">
        <TestimonialSection isLanguage={i18n.language}>
          <Row
            gutter={[24, 24]}
            height={"100%"}
            style={
              lang === "ar" && {
                flexDirection: "row-reverse",
                justifyContent: "space-between",
              }
            }
          >
            <Col xxl={12} xl={12} lg={24} md={24} sm={24}>
              <LeftWrapper isLanguage={lang}>
                <LeftHeading isLanguage={lang}>
                  {t("home.LeftHeading")}
                </LeftHeading>
                <LeftSmallHeading isLanguage={i18n.language}>
                  {t("home.LeftSmallHeading")}
                </LeftSmallHeading>

                <BtnWrapper href="#footer">
                  <ActionButton font>{t("home.ActionButtonOne")}</ActionButton>
                </BtnWrapper>
              </LeftWrapper>
            </Col>
            <Col
              style={
                lang === "ar" ||
                ("en" && {
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                })
              }
              xxl={12}
              xl={12}
              lg={24}
              md={24}
              sm={24}
            >
              <RightWrapper isLanguage={i18n.language}>
                <RightHeading isLanguage={i18n.language}>
                  {t("home.RightHeading")}
                </RightHeading>
              </RightWrapper>
            </Col>
          </Row>
        </TestimonialSection>
      </WrapperCont>
      <AppWorkSection id="HowItWorks">
        <MediumHeading>{t("home.HowItWorksApp")}</MediumHeading>
        <TextMediumOne isLanguage={lang}>
          {t("home.HowItWorksDisc")}
        </TextMediumOne>
        <MobileAppWrapper isMargin isUpperTransForm={"4.3rem"}>
          <Row
            style={
              lang === "ar" && {
                flexDirection: "row-reverse",
                justifyContent: "center",
                width: "100%",
              }
            }
          >
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <MobileImageWrapper isLanguage={lang}>
                <Mobile1 />
              </MobileImageWrapper>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <MobileCardWrapper isLanguage={lang}>
                <Image src="./Assets/Icon1.svg" />
                <MediumHeading>{t("home.QuickAppointMent")}</MediumHeading>
                <TextMedium isLanguage={i18n.language}>
                  {t("home.QuickAppointMentDisc")}
                </TextMedium>
              </MobileCardWrapper>
            </Col>
          </Row>
        </MobileAppWrapper>
        <ArrowWrapper isUpperTransForm={"2rem"}>
          {lang === "ar" ? (
            <Image src="./Assets/RightToLeft.png" />
          ) : (
            <Image src="./Assets/leftToRight.png" />
          )}
        </ArrowWrapper>

        <MobileAppWrapper>
          <StyledRow isLanguage={i18n.language}>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <MobileCardWrapper isLanguage={lang} isEnd>
                <Image src="./Assets/Icon2.svg" />
                <MediumHeading isLanguage={lang}>
                  {t("home.EasyProfileSetups")}
                </MediumHeading>
                <TextMedium isLanguage={i18n.language} IsWidth={"91%"}>
                  {t("home.EasyProfileSetupDisc")}
                </TextMedium>
              </MobileCardWrapper>
            </Col>
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <MobileImageWrapper isOrder isBg>
                <Mobile2 />
              </MobileImageWrapper>
            </Col>
          </StyledRow>
        </MobileAppWrapper>

        <ArrowWrapper isTransform>
          {lang === "ar" ? (
            <Image src="./Assets/leftToRight.png" />
          ) : (
            <Image src="./Assets/RightToLeft.png" />
          )}
        </ArrowWrapper>
        <MobileAppWrapper isMargin isUpperTransForm={"-4.4rem"}>
          <Row
            style={
              lang === "ar" && {
                flexDirection: "row-reverse",
                justifyContent: "center",
                width: "100%",
              }
            }
          >
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <MobileImageWrapper>
                <Mobile3 />
              </MobileImageWrapper>
            </Col>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <MobileCardWrapper isLanguage={lang}>
                <Image src="./Assets/Icon3.svg" />

                <MediumHeading isFull isLanguage={lang} isLeft>
                  {t("home.PreferWay")}
                </MediumHeading>
                <TextMedium isLanguage={i18n.language} IsWidth={"75%"}>
                  {t("home.PreferWayDisc")}
                </TextMedium>
              </MobileCardWrapper>
            </Col>
          </Row>
        </MobileAppWrapper>
      </AppWorkSection>
      <OverServices>
        <WrapService>
          <MediumHeading id="Services">{t("home.OurServices")}</MediumHeading>
          <TextMediumOne isWidth isLanguage={i18n.language}>
            {t("home.OurServicesDisc")}
          </TextMediumOne>
        </WrapService>
        <CarosalItemList isLanguage={i18n.language}>
          <UnOrderedList
            // ref={serviceScrollRef}
            style={{
              display: "flex",
              overflow: "auto",
              scrollSnapType: "x mandatory",
            }}
          >
            {t("home.services", { returnObjects: true })?.map((item, i) => (
              <StyledListItem key={i}>
                {" "}
                <Item isLanguage={lang}>
                  <div>
                    <Image src={item?.img} />
                  </div>
                  <SmallHeading isLanguage={i18n.language} isLight>
                    {item?.name}
                  </SmallHeading>
                </Item>
              </StyledListItem>
            ))}
          </UnOrderedList>
        </CarosalItemList>
        <OValWrapper isLanguage={lang}>
          <OVal></OVal>
          <OL>
            {services?.slice(-3)?.map((_, i) => (
              <Circle
                key={i}
                style={i === activeServicePageIndex ? { opacity: 0.5 } : {}}
              ></Circle>
            ))}
          </OL>
        </OValWrapper>
      </OverServices>
      <ChooseUs id="WhyChooseUs">
        <MediumHeading>{t("home.OurWhyChooseUs")}</MediumHeading>
        <TextMediumOne isLanguage={lang}>
          {t("home.OurWhyChooseUsDisc")}
        </TextMediumOne>

        <CardWrapper isLanguage={lang}>
          <Row gutter={[24, 24]} justify={"center"} align={"center"}>
            {t("home.chooseUsCards", { returnObjects: true })?.map(
              (card, index) => (
                <Column key={index} xxl={8} lg={8} md={12} sm={24}>
                  <ChooseUsCard isLanguage={lang}>
                    <div>
                      <Image src={card.img} />
                    </div>
                    <SmallHeading>{card.heading}</SmallHeading>
                    <Discription isLanguage={i18n.language} isMargin>
                      {card.disc}
                    </Discription>
                  </ChooseUsCard>
                </Column>
              )
            )}
          </Row>
        </CardWrapper>
        <Para>{t("home.chooseUsInfo")}</Para>
        <BtnWrapper href="#footer">
          <ActionButton font>{t("home.ActionButtonOne")}</ActionButton>
        </BtnWrapper>
        <DonwLoadSection isLanguage={i18n.language}>
          <Row
            style={
              lang === "ar" && {
                flexDirection: "row-reverse",
                justifyContent: "center",

                width: "100%",
              }
            }
            gutter={{ xxl: 24, xl: 24, lg: 14, md: 8, sm: 8, xs: 0 }}
          >
            <Col xxl={12} lg={12} md={24} sm={24}>
              <DownLoadCard isGreen>
                <DownloadCardHeading isLanguage={lang}>
                  {t("home.downloadgHeading")} {t("home.appointment")}
                </DownloadCardHeading>
                <DownloadCardDisc isWidth isLanguage={lang}>
                  {t("home.downloadDisc")}
                </DownloadCardDisc>
                <StoreWrapper isLanguage={lang}>
                  <PlayButton>
                    <div>
                      <Icon src="./Assets/googlePlayIcon.png" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "start",
                      }}
                    >
                      <StoreText>GET IT ON</StoreText>
                      <StoreHeading>Google Play</StoreHeading>
                    </div>
                  </PlayButton>
                  <PlayButton>
                    <div>
                      <Icon src="./Assets/appleIcon.svg" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "start",
                      }}
                    >
                      <StoreText>Download on the</StoreText>
                      <StoreHeading>App Store</StoreHeading>
                    </div>
                  </PlayButton>
                </StoreWrapper>
                <MobileImage src="./Assets/desktop.png" />
              </DownLoadCard>
            </Col>
            <Col xxl={12} lg={12} md={24} sm={24}>
              <DownLoadCard>
                <DownloadCardHeading isLanguage={lang}>
                  {t("home.signUpHeading")} {t("home.business")}
                </DownloadCardHeading>
                <DownloadCardDisc isLanguage={lang}>
                  {t("home.signUpDisc")}
                </DownloadCardDisc>
                <ActionButton
                  isLanguage={lang}
                  font
                  isBg
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_BASE_PURE_URL}/merchantForm?lang=${i18n.language}`,
                      "_blank"
                    )
                  }
                >
                  {t("home.sginUpMarchant")}
                </ActionButton>
                <DeskTopImage src="./Assets/mobile.png" />
              </DownLoadCard>
            </Col>
          </Row>
        </DonwLoadSection>
        <OverCustumer id="Testimonials">
          <OverCustumerHeading isLanguage={lang}>
            {t("home.OurCostumer")}
          </OverCustumerHeading>
          <TextMediumOne isWidth isLanguage={i18n.language}>
            {`( ${t("home.soon")} )`}
          </TextMediumOne>
          {/* <CarosalItemList isLanguage={i18n.language}>
            <UnOrderedList
              // ref={scrollRef}
              style={{
                display: "flex",
                overflow: "auto",
                scrollSnapType: "x mandatory",
              }}
            >
              {t("home.Customer", { returnObjects: true })?.map((item, i) => (
                <StyledListItem>
                  <ItemOne>
                    <div>
                      <Image src={"./Assets/stars.png"} />
                    </div>

                    <Disc isLanguage={lang} isCenter>
                      {item?.disc}
                    </Disc>
                    <LargeHeading isLanguage={lang}>{item?.name}</LargeHeading>
                  </ItemOne>
                </StyledListItem>
              ))}
            </UnOrderedList>
          </CarosalItemList> */}
          {/* <OValWrapper isLanguage={lang}>
            <OVal></OVal>
            <OL>
              {customer?.slice(-3).map((_, i) => (
                <Circle
                  key={i}
                  style={i === activePageIndex ? { opacity: 0.5 } : {}}
                ></Circle>
              ))}
            </OL>
          </OValWrapper> */}
        </OverCustumer>
      </ChooseUs>
      <Footer id="footer">
        <FootetItem isLanguage={i18n.language}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Logo isLanguage={lang} onClick={onClickLogo}>
              {lang === "ar" && <img src="./Assets/footarlogo.svg" />}
              {lang === "en" && (
                <LogoImage src="./Assets/logo.svg" alt="Logo" />
              )}{" "}
              {lang === "en" && "beautisry"}
            </Logo>
          </div>

          <Row
            style={
              lang === "ar"
                ? {
                    flexDirection: "row-reverse",

                    width: "100%",
                    marginTop: "2rem",
                  }
                : { marginTop: "2rem" }
            }
          >
            <Col xl={8} lg={8} md={24} sm={24}>
              <div>
                <FooterDisc isLanguage={lang}>
                  {t("home.footerDisc")}
                </FooterDisc>
                <FooterDisc isLanguage={lang} isMargin={"2rem"}>
                  {t("home.needTalk")}
                </FooterDisc>
                <CustomDisc isLanguage={lang} weight={400} isMargin={"0.5rem"}>
                  <Image src="./Assets/email.svg" /> support@beautisry.com
                </CustomDisc>
                <CustomDisc
                  top
                  isLanguage={lang}
                  weight={400}
                  isMargin={"0.5rem"}
                >
                  <Image src="./Assets/phone-call.svg" /> 9200 31756
                </CustomDisc>
                <FooterDisc isLanguage={lang} isMargin={"2rem"}>
                  {t("home.stayConnect")}
                </FooterDisc>
                <FooterDisc isLanguage={lang} isMargin={"0.5rem"}>
                  <Image src="./Assets/instagram.svg" />
                  <Image src="./Assets/linkedin.svg" />
                  <Image src="./Assets/snapchat.svg" />
                  <Image src="./Assets/twitter.svg" />
                </FooterDisc>
              </div>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24}>
              <ListWrapper>
                <UL>
                  <FooterDisc isLanguage={lang} isMarginBottom top>
                    {t("home.quickLink")}
                  </FooterDisc>
                  <LI>
                    {" "}
                    <Link href="#home">{t("home.Home")}</Link>
                  </LI>
                  <LI>
                    {" "}
                    <Link href="#HowItWorks">{t("home.HowItWorks")}</Link>
                  </LI>
                  <LI>
                    <Link href="#Services">{t("home.Services")}</Link>
                  </LI>
                  <LI>
                    <Link href="#WhyChooseUs">{t("home.WhyChooseUs")}</Link>
                  </LI>
                  <LI>
                    <Link href="#Testimonials">{t("home.Testimonials")}</Link>
                  </LI>
                  <LI>
                    <Link onClick={() => onClickLink(termsAndConditionsLink)}>
                      {t("home.TermsAndconditions")}
                    </Link>
                  </LI>
                  <LI>
                    <Link onClick={() => onClickLink(privacyPolicyLink)}>
                      {t("home.privarcyPolicy")}
                    </Link>
                  </LI>
                </UL>
              </ListWrapper>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24}>
              <div>
                <FooterDisc isWidth={"350px"} isLanguage={lang}>
                  {t("home.formHeading")}
                </FooterDisc>
                <Form
                  isLanguage={lang}
                  onSubmit={onSaveContactRequest}
                  ref={formRef}
                >
                  <FooterDisc isLanguage={lang} isPadding>
                    {t("home.requirment")}
                  </FooterDisc>
                  <InoutsWrapper>
                    <Input
                      type="text"
                      placeholder={t("home.fullNamePlaceholder")}
                      name="name"
                      required
                    />
                    <Input
                      type="email"
                      placeholder={t("home.emailPlaceholder")}
                      name="email"
                      required
                    />
                    <Input
                      type="text"
                      placeholder={t("home.phoneNumberPlaceholder")}
                      name="phone"
                      required
                    />
                    <TextArea
                      placeholder={t("home.messagePlaceholder")}
                      name="message"
                      required
                    ></TextArea>
                    <ActionButton type="submit">
                      {t("home.sendButton")}
                    </ActionButton>
                  </InoutsWrapper>
                </Form>
              </div>
            </Col>
          </Row>
        </FootetItem>
      </Footer>
      <Info>
        <Text isLanguage={i18n.language}>{t("home.footerText")}</Text>
      </Info>
    </Container>
  );
};

export default Home;

const Column = styled(Col)`
  @media (max-width: 400px) {
    padding: 0 !important;
  }
`;

const Container = styled.div`
  min-width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;

  text-align: ${({ isLanguage }) => (isLanguage === "ar" ? "right" : "")};
`;
const NavWrap = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  z-index: 999;
  position: fixed;
  border-bottom: 2px solid #e6e6e6;
  height: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  @media (max-width: 768px) {
    height: 80px;
  }
`;
const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "row-reverse"};

  width: ${({ isLanguage }) => (isLanguage === "ar" ? "83%" : "75%")};

  z-index: 99;
  @media (max-width: 1600px) {
    width: ${({ isLanguage }) => (isLanguage === "ar" ? "83%" : "75%")};
  }
  @media (max-width: 1240px) {
    padding: 10px 1rem;
    width: 100%;
  }
  @media (max-width: 1150px) {
    zoom: 90%;
  }
  @media (max-width: 1024px) {
    zoom: 100%;
  }
  @media (max-width: 768px) {
    padding: 10px 20px;
    height: 80px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  /* background-color: red; */

  @media (max-width: 1023px) {
    justify-content: space-between;
    width: 100%;
    display: ${({ isLanguage }) => isLanguage === "ar" && "contents"};
  }
`;

const Logo = styled.h1`
  cursor: pointer;
  color: #060a32;
  font-size: ${({ isLanguage }) => (isLanguage === "ar" ? "32px" : "32px")};
  margin-left: ${({ isLanguage }) => isLanguage === "ar" && "auto"};
  margin-right: ${({ isMargin }) => isMargin && "20px"};
  display: flex;
  justify-content: ${({ isStart }) => (isStart ? "flex-start" : "center")};
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  font-family: mont-Semibold;

  @media (max-width: 400px) {
    margin-right: 10px;
  }
`;

const MenuIcon = styled.div`
  color: #fff;
  cursor: pointer;
  display: none;

  @media (max-width: 1023px) {
    display: block;
    margin-top: 0.5rem;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "row-reverse"};
  gap: 1rem;
  /* background-color: red; */
  width: ${({ isLanguage }) => isLanguage === "ar" && "69%"};
  justify-content: ${({ isLanguage }) =>
    isLanguage === "ar" && "space-between"};
  @media (max-width: 1023px) {
    display: none;
  }
`;

const NavItem = styled.p`
  color: #060a32;
  margin-right: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
`;

const ActionButton = styled.button`
  min-width: ${({ isLanguage }) => (isLanguage === "ar" ? "119px" : "142px")};
  margin: 0;
  color: #ffffff;
  border: none;
  line-height: 19.5px;
  padding: ${({ font }) =>
    font ? "18px 24px 18px 24px" : "14px 16px 14px 16px"};
  cursor: pointer;
  border-radius: 50px;
  background-color: ${({ isBg }) => (isBg ? "#7452A0" : "#F3967D")};
  font-size: ${({ font, isLanguage }) =>
    isLanguage === "ar" ? "14px" : font ? "20px" : "16px"};
  font-weight: ${({ font }) => (font ? "500" : "500")};
  transition: 0.6s ease-in-out;
  font-family: mont-Light;

  &:hover {
    background-color: transparent;
    color: #f3967d;
    border: 1px solid #f3967d;
  }

  @media (max-width: 400px) {
    padding: ${({ font }) =>
      font ? "12px 24px 12px 24px" : "14px 16px 14px 16px"};
    font-size: ${({ font, isLanguage }) =>
      isLanguage === "ar" ? "14px" : font ? "15px" : "16px"};
  }
`;

const Image = styled.img``;
const WrapperCont = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "83%" : "75%")};
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding-top: 132px;
  max-height: 800px;

  @media (max-width: 1220px) {
    padding-top: 70px;
    max-height: 800px;
    width: 100%;
  }

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    padding-top: 0;
  }
`;
const TestimonialSection = styled.div`
  height: 100%;
  max-height: 800px;
  width: 100%;

  padding-top: 132px;
  @media (max-width: 1220px) {
    padding: 10px 4rem;
    padding-top: 132px;
    max-height: 800px;
  }

  @media (max-width: 768px) {
    padding: 2rem 1.2rem;
    justify-content: ${({ isLanguage }) =>
      isLanguage === "ar" ? "flex-end" : "flex-start"};
    align-items: ${({ isLanguage }) =>
      isLanguage === "ar" ? "flex-end" : "flex-start"};
    height: 100%;
    width: 100%;
    padding-top: ${({ isLanguage }) =>
      isLanguage === "ar" ? "150px" : "150px"};
    padding-bottom: ${({ isLanguage }) =>
      isLanguage === "ar" ? "80px" : "80px"};
  }
`;

const LeftSmallHeading = styled.p`
  color: #3b4c66;
  line-height: 32px;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  font-size: ${({ isLanguage }) => (isLanguage === "ar" ? "24px" : "24px")};
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "540px" : "560px")};
  font-weight: 500;
  font-family: mont-Light;
  margin-bottom: 1rem;

  @media (max-width: 1200px) {
    font-size: 18px;
    width: 60vw;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    width: 100%;
    line-height: 26px;
  }

  @media (max-width: 400px) {
    font-size: 16px;
    width: ${({ isLanguage }) => (isLanguage === "ar" ? "100%" : "100%")};
    margin-bottom: 0.5rem;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: ${({ isLanguage }) => isLanguage === "ar" && "end"};

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const LeftHeading = styled.h2`
  font-size: ${({ isLanguage }) => (isLanguage === "ar" ? "48px" : "48px")};
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "630px" : "620px")};
  color: #060a32;
  line-height: ${({ isLanguage }) =>
    isLanguage === "ar" ? "65.51px" : "58.51px"};
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
  font-family: mont-Regular;
  margin-bottom: ${({ isLanguage }) =>
    isLanguage === "en" ? "1rem" : "0.6rem"};

  @media (max-width: 1222px) {
    font-size: 48px;
  }
  @media (max-width: 1025px) {
    font-size: 48px;
    width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 30px;
    width: 90vw;
    font-family: mont-medium;
    line-height: 41px;
  }

  @media (max-width: 400px) {
    font-size: 30px;
    line-height: 39px;
  }
`;

const RightHeading = styled.h1`
  font-size: 79px;
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "490px" : "400px")};
  color: #090e40;
  line-height: ${({ isLanguage }) =>
    isLanguage === "ar" ? "104.83px" : "104.83px"};
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
  font-family: mont-Bold;

  @media (max-width: 1222px) {
    font-size: 60px;
    width: 33vw;
    line-height: 100px;
  }

  @media (max-width: 1185px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0;
    font-size: 48px;
    width: 100%;
  }
  @media (max-width: 400px) {
    margin: ${({ isLanguage }) =>
      isLanguage === "ar" ? "2rem 0" : "0.5rem 0"};
    font-size: 48px;
    width: ${({ isLanguage }) => isLanguage === "ar" && "100%"};
    line-height: 65px;
  }
`;

const RightWrapper = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "670px" : "100%")};
  height: 80%;
  display: flex;
  justify-content: ${({ isLanguage }) =>
    isLanguage === "ar" ? "flex-start" : "flex-end"};
  align-items: flex-end;
  @media (max-width: 1220px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const BtnWrapper = styled.a``;

const AppWorkSection = styled.div`
  width: 100%;
  max-height: 2500px;
  background-color: #ffede3;
  padding: 4rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 1220px) {
    max-height: 100%;
  }
  @media (max-width: 768px) {
    padding: 3rem 0;
  }
  @media (max-width: 400px) {
    padding: 3rem 0.5rem;
  }
`;
const TextMedium = styled.p`
  font-size: 16px;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  color: #050a30;
  line-height: 24px;
  width: ${({ isLanguage, IsWidth }) =>
    isLanguage === "ar" ? "75%" : IsWidth ? IsWidth : "58%"};
  font-family: mont-Light;

  @media (max-width: 1222px) {
    width: ${({ isLanguage, IsWidth }) =>
      isLanguage === "ar" ? "75%" : IsWidth ? IsWidth : "70%"};
  }

  @media (max-width: 1025px) {
    width: ${({ isLanguage, IsWidth }) =>
      isLanguage === "ar" ? "90%" : IsWidth ? IsWidth : "80%"};
  }

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    width: 70%;
    text-align: center;
  }
  @media (max-width: 400px) {
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    text-align: center;
  }
`;
const TextMediumOne = styled.p`
  font-size: 18px;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "28%" : "40%")};
  margin-top: 1rem;
  font-weight: 500;
  text-align: center;
  color: #3b4c66;
  font-family: mont-Light;

  @media (max-width: 1025px) {
    width: 60%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    width: ${({ isLanguage }) => (isLanguage === "ar" ? "60%" : "75%")};
    margin: 0.5rem 0rem;
  }
  @media (max-width: 400px) {
    font-size: 16px;
    width: ${({ isLanguage, isWidth }) =>
      isLanguage === "ar" ? (isWidth ? "60%" : "90%") : "85%"};
    margin-top: ${({ isLanguage }) => isLanguage === "ar" && "0.7rem"};
  }
`;
const MediumHeading = styled.h2`
  font-size: 34px;
  width: ${({ isFull }) => isFull && "350px"};
  font-weight: 700;
  line-height: 45px;

  font-family: mont-Medium;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 33px;
    text-align: center;
  }

  @media (max-width: 400px) {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
  }
`;
const MobileAppWrapper = styled.div`
  width: 82%;
  margin-top: ${({ isMargin }) => isMargin && "2rem"};

  transform: translateY(
    ${({ isTransform, isUpperTransForm }) =>
      (isTransform && "-3.5rem") || (isUpperTransForm && isUpperTransForm)}
  );
  @media (max-width: 768px) {
    width: 95%;
    margin-top: ${({ isMargin }) => isMargin && "0"};
    transform: translateY(1px);
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  @media (max-width: 400px) {
    margin-top: ${({ isMargin }) => isMargin && "2.5rem"};
  }
`;
const StyledRow = styled(Row)`
  flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "row-reverse"};
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
const MobileReplaceWrapper = styled.div`
  width: 82%;
  margin-top: ${({ isMargin }) => isMargin && "2rem"};

  transform: translateY(
    ${({ isTransform, isUpperTransForm }) =>
      (isTransform && "-3.5rem") || (isUpperTransForm && isUpperTransForm)}
  );
  display: none;
  @media (max-width: 768px) {
    display: none;
    width: 95%;
    transform: translateY(1px);
    margin-top: ${({ isMargin }) => isMargin && "0"};
    min-height: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  @media (max-width: 400px) {
    margin-top: 2.5rem;
  }
`;

const MobileCardWrapper = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "80%" : "100%")};
  height: 100%;
  display: flex;
  justify-content: center;
  padding-left: ${({ isLanguage }) =>
    isLanguage === "ar" ? "0rem" : "3.5rem"};
  flex-direction: column;
  align-items: ${({ isEnd, isLanguage }) =>
    isEnd && isLanguage === "ar" ? "flex-start" : "flex-start"};
  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  gap: 1rem;

  @media (max-width: 768px) {
    padding-left: 0;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
    margin-bottom: 2rem;
  }

  @media (max-width: 400px) {
    padding-left: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;
const MobileImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 400px) {
    order: 5;
  }
`;
const ArrowWrapper = styled.div`
  width: 82%;
  height: 25vh;
  max-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translateY(
    ${({ isTransform, isUpperTransForm }) =>
      (isTransform && "-1.5rem") || (isUpperTransForm && isUpperTransForm)}
  );
  margin-bottom: ${({ isTransform }) => isTransform && "2rem"};
  @media (max-width: 768px) {
    display: none;
  }
`;
const Wrapper = styled.div`
  width: 68%;
`;
const OverServices = styled.div`
  height: 60vh;
  min-height: 500px;
  max-height: 600px;
  background-color: #fff9f5;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 0;

  @media (max-width: 768px) {
    padding: 3rem 0;
  }
`;
const WrapService = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;
const ChooseUs = styled.div`
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 0;

  @media (max-width: 400px) {
    padding: 3rem 0;
  }
`;
const CardWrapper = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "80%" : "75%")};

  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
  margin-top: 3rem;
`;
const ChooseUsCard = styled.div`
  padding: 30px 16px 30px 16px;
  border-radius: 12px;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 250px;

  @media (max-width: 1025px) {
    height: 280px;
  }
  @media (max-width: 768px) {
    height: 230px;
  }
  @media (max-width: 400px) {
    height: 256px;
  }
`;
const SmallHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 21.94px;
  color: #060a32;
  font-family: ${({ isLanguage, isLight }) =>
    isLanguage === "ar" && isLight ? " mont-Light" : "mont-Regular"};

  @media (max-width: 400px) {
    font-size: 16px;
    font-family: ${({ isLanguage, isLight }) =>
      isLanguage === "ar" && isLight ? " mont-Light" : "mont-Regular"};
  }
`;
const Discription = styled.p`
  margin: 0;
  margin-top: ${({ isMargin }) => (isMargin ? "0rem" : "0.3rem")};
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  color: #3b4c66;
  text-align: ${({ isCenter }) => isCenter && "center"};
  font-family: mont-Light;

  @media (max-width: 400px) {
    width: ${({ isLanguage }) => isLanguage === "ar" && "80%"};
  }
`;
const Para = styled.p`
  color: #060a32;
  font-size: 18px;
  line-height: 26px;
  margin-top: 4rem;
  margin-bottom: 1.7rem;
  text-align: center;
  width: 51%;
  font-family: mont-Regular;

  @media (max-width: 768px) {
    margin-top: 3rem;
    margin-bottom: 2rem;
    width: 75%;
  }

  @media (max-width: 400px) {
    margin-top: 2rem;
    margin-bottom: 0.7rem;
    width: 85%;
    font-size: 16px;
  }
`;
const DonwLoadSection = styled.div`
  margin: 4.5rem 0rem;
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "83%" : "75%")};

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const DownLoadCard = styled.div`
  height: 90vh;
  max-height: 800px;
  width: 100%;
  border-radius: 16px;
  background-color: ${({ isGreen }) => (isGreen ? "#C9EEEE" : "#EADCFC")};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  position: relative;
  @media (max-width: 768px) {
    min-height: 100%;
    height: 100%;
    transform: ${({ isGreen }) => (isGreen ? "" : "translateY(20px)")};
  }
  @media (max-width: 400px) {
    width: 100%;
    margin-top: ${({ isGreen }) => (isGreen ? "0" : "1rem")};
  }
`;

const DownloadCardHeading = styled.h1`
  color: #060a32;
  font-size: 24px;
  text-align: center;
  margin-top: ${({ isLanguage }) => (isLanguage ? "1rem" : 0)};
  line-height: ${({ isLanguage }) => (isLanguage === "ar" ? "45px" : "30px")};
  font-family: mont-medium;

  @media (max-width: 400px) {
    font-size: 20px;
    width: ${({ isLanguage }) => (isLanguage ? "90%" : "100%")};
    line-height: ${({ isLanguage }) => isLanguage === "ar" && "35px"};
  }
`;
const DownloadCardDisc = styled.p`
  text-align: center;
  font-size: ${({ isLanguage }) => (isLanguage === "ar" ? "15px" : "18px")};

  line-height: 26px;
  color: #3b4c66;
  font-family: mont-Light;
  width: ${({ isLanguage, isWidth }) =>
    isLanguage === "ar" && isWidth ? "100%" : "85%"};
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 769px) {
    width: ${({ isLanguage }) => isLanguage === "ar" && "70%"};
  }

  @media (max-width: 600px) {
    width: 100%;
    font-size: ${({ isLanguage }) => (isLanguage === "ar" ? "16px" : "18px")};
  }
`;
const StoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  margin-bottom: "4rem";

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: 400px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: ${({ isLanguage }) => isLanguage === "ar" && "0.5rem"};
  }
  @media (max-width: 1025px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: ${({ isLanguage }) => isLanguage === "ar" && "0.5rem"};
  }
`;
const PlayButton = styled.div`
  width: 202px;
  height: 62px;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
  border-radius: 6px;
  border: 1px solid #cbced3;

  @media (max-width: 400px) {
    width: 160px;
    height: 45px;
  }
`;
const Icon = styled.img`
  width: ${({ isWidth }) => (isWidth ? isWidth : "40px")};
  height: ${({ isHeight }) => isHeight && isHeight};

  @media (max-width: 768px) {
    width: ${({ isWidth }) => (isWidth ? isWidth : "30px")};
    height: 100%;
  }
`;
const StoreHeading = styled.h1`
  font-size: 20px;
  font-weight: 600;
  font-family: mont-regular;
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
`;
const StoreText = styled.p`
  font-size: 13px;
  font-size: 400;
  font-family: mont-Light;
  @media (max-width: 768px) {
    font-size: 12px;
    margin-right: 1rem;
  }
  @media (max-width: 400px) {
    font-size: 10px;
    margin-right: 0rem;
  }
`;
const DeskTopImage = styled.img`
  position: absolute;
  bottom: 10%;
  @media (max-width: 768px) {
    width: 100%;
    position: initial;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  @media (max-width: 1222px) {
    width: 90%;
  }
`;
const MobileImage = styled.img`
  position: absolute;
  bottom: 0;
  @media (max-width: 768px) {
    width: 90%;
    position: initial;
    transform: translateY(20px);
  }
  @media (max-width: 768px) {
    width: 90%;
    transform: translateY(16px);
  }
  @media (max-width: 1025px) {
    width: 73%;
  }
`;

const Circle = styled.button`
  width: 10px;
  height: 10px;
  background-color: #f3967d;
  border-radius: 50%;
  outline: none;
  border: none;
`;
const OL = styled.ol`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  margin-top: 1rem;
  flex-direction: row-reverse;
`;
const StyledListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    min-width: 100%;
  }
  @media (max-width: 768px) {
    margin-top: ${({ isLanguage }) =>
      isLanguage === "ar" ? "0.5rem" : "0.5rem"};
  }
`;

const Item = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "190px" : "170px")};
  text-align: center;
  margin: 1rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 768px) {
    width: 380px;
  }
`;
const UnOrderedList = styled.ul`
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const CarosalItemList = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "90%" : "87%")};
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
  margin-left: auto;

  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
  }
`;

const OVal = styled.div`
  background-color: #f3967d;
  height: 10px;
  width: 36px;
  border-radius: 25px;
  margin-top: 1rem;
`;
const OValWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "row-reverse"};
  gap: 0.5rem;
  margin-top: 0.7rem;
`;
const OverCustumer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 4rem 0;

  @media (max-width: 400px) {
    align-items: center;
    padding: 2rem 0;
  }
`;

const OverCustumerHeading = styled.h2`
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-family: mont-medium;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 33px;
  }
  @media (max-width: 400px) {
    font-size: 24px;
    width: ${({ isLanguage }) => (isLanguage === "ar" ? "200px" : "90%")};
  }
`;
const ItemOne = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  margin: 1rem;
  width: 354px;
  height: 333px;
  padding: 1rem;
  background-image: url("./Assets/custumerCardBg.png");
  background-color: #fff9f5;

  @media (max-width: 768px) {
    width: 343px;
    height: 294px;
    margin: 1rem;
  }
`;
const LargeHeading = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-family: mont-Regular;
`;
const Disc = styled.p`
  font-size: 18px;
  text-align: center;
  font-size: ${({ isLanguage }) => (isLanguage === "ar" ? "18px" : "18px")};
  font-family: mont-Light;
  color: #3b4c66;
`;
const ButtonWrapper = styled.div`
  display: flex;
  border: 1px solid #f3967d;
  border-radius: 25px;
  overflow: hidden;
  cursor: pointer;
  min-width: 110px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: max-content;
`;
const ButtonOne = styled.button`
  height: 42px;
  width: 50%;
  background-color: ${({ isLanguage }) =>
    isLanguage === "en" ? "#F3967D" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "en" ? "#FFFFFF" : "#F3967D")};
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;

const ButtonTwo = styled.button`
  height: 42px;
  width: 50%;
  background-color: ${({ isLanguage }) =>
    isLanguage === "ar" ? "#F3967D" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "ar" ? "#FFFFFF" : "#F3967D")};
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
`;
const Link = styled.a`
  color: #060a32;
  border: none;
  font-size: 16px;
  font-weight: ${({ isLanguage }) => (isLanguage === "ar" ? "400" : "400")};
  line-height: 19.5px;
  font-family: mont-Light;
  white-space: nowrap;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;
const NavItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const WrapIcon = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Footer = styled.div`
  /* min-height: 70vh; */
  background-color: #fff9f5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  flex-direction: row-reverse;
  @media (max-width: 768px) {
    padding: 2rem 0;
  }
  @media (max-width: 400px) {
    padding: 1rem 0;
  }
`;
const FootetItem = styled.div`
  width: ${({ isLanguage }) => (isLanguage === "ar" ? "80%" : "75%")};

  @media (max-width: 768px) {
    width: 90%;
    margin-bottom: 2rem;
    padding-top: 2rem;
  }
`;
const FooterDisc = styled.p`
  width: ${({ isWidth, isShortWidth }) => isWidth && isWidth};
  /* float: right; */
  font-size: 16px;
  line-height: 24px;
  font-weight: ${({ weight }) => (weight ? weight : 400)};
  color: #060a32;
  margin-top: ${({ isMargin }) => isMargin && isMargin};
  margin-bottom: ${({ isMarginBottom }) => isMarginBottom && "1rem"};
  display: flex;
  gap: 0.5rem;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  font-family: mont-Regular;
  padding-bottom: ${({ isPadding }) => isPadding && "0.5rem"};

  @media (max-width: 1300px) {
    width: ${({ isWidth, isShortWidth }) => isWidth && "100%"};
  }
  @media (max-width: 768px) {
    margin-top: ${({ isMargin, top }) =>
      (isMargin && isMargin) || (top && "2rem")};
    width: ${({ isWidth }) => isWidth && "100%"};
  }

  @media (max-width: 400px) {
    font-size: 14px;
    margin-bottom: 0rem;
  }
  @media (max-width: 1025px) {
    font-size: 15px;
  }
`;

const CustomDisc = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${({ weight }) => (weight ? weight : 400)};
  color: #060a32;
  margin-top: ${({ isMargin }) => isMargin && isMargin};
  margin-bottom: ${({ isMarginBottom }) => isMarginBottom && "1rem"};
  display: flex;
  gap: 0.5rem;
  font-family: mont-Regular;
  padding-bottom: ${({ isPadding }) => isPadding && "0.5rem"};

  @media (max-width: 768px) {
    margin-top: ${({ isMargin, top }) =>
      (isMargin && isMargin) || (top && "2rem")};
    width: ${({ isWidth }) => isWidth && "100%"};
  }
  @media (max-width: 400px) {
    font-size: 14px;
    margin-bottom: 0rem;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;
const UL = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;
const LI = styled.li`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #060a32;
  font-family: mont-Light;
`;
const Form = styled.form`
  width: 354px;
  height: 410px;
  background-color: #ffff;
  border-radius: 12px;
  margin-top: 1rem;
  padding: 1rem 1rem;
  padding-top: 1.5rem;
  box-shadow: 1px 6px 18px -10px rgba(0, 0, 0, 0.65);
  -webkit-box-shadow: 1px 6px 18px -10px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 1px 6px 18px -10px rgba(0, 0, 0, 0.75);

  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};

  @media (max-width: 1222px) {
    width: 100%;
    height: max-content;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    width: 340px;
  }
`;
const InoutsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;
const Input = styled.input`
  width: 100%;
  padding: 10px 16px 10px 16px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  &::placeholder {
    color: #e2e2e2;
  }
`;
const TextArea = styled.textarea`
  height: 80px;
  width: 100%;
  padding: 10px 16px 10px 16px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  &::placeholder {
    color: #e2e2e2;
  }
`;

const Info = styled.div`
  height: 48px;
  width: 100%;
  background-color: #060a32;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  width: 80%;
  color: #ffff;
  font-family: mont-Light;
  direction: ${({ isLanguage }) => (isLanguage === "ar" ? "rtl" : "ltr")};
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
`;
const WrapBtn = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: ${({ isLanguage }) =>
    isLanguage === "ar" ? "row-reverse" : "row"};
  align-items: center;
`;
