// reducers.js

import { FETCH_PRODUCT_FAILURE, FETCH_PRODUCT_REQUEST, FETCH_PRODUCT_SUCCESS } from "./types";


const initialState = {
  products: null,
  loading: false,
  error: ''
};

const ProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,

        loading: false,
        products: action.payload,
        error: ''
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default ProductReducer;
