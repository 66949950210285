import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const NewVsRegularChart = ({ t, lang }) => {
  const { i18n } = useTranslation();
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        horizontal: false,
        columnWidth: "80%",

        dataLabels: {
          position: "top", 
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
      offsetY: -20,
      offsetX: 2,
      style: {
        fontSize: "10px",

        colors: ["#808396", "#808396"],
      },
    },

    stroke: {
      show: true,
      width: 6,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        `${t("newClientChart.jan")}`,
        `${t("newClientChart.feb")}`,
        `${t("newClientChart.mar")}`,
        `${t("newClientChart.apr")}`,
        `${t("newClientChart.may")}`,
        `${t("newClientChart.june")}`,
        `${t("newClientChart.july")}`,
        `${t("newClientChart.aug")}`,
        `${t("newClientChart.sep")}`,
        `${t("newClientChart.oct")}`,
        `${t("newClientChart.nov")}`,
        `${t("newClientChart.dec")}`,
      ],
    },

    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5, 
      labels: {
        formatter: (val) => `${val}%`, 
      },
    },
    grid: {
      show: true, 
      borderColor: "#D0D0DA", 
      strokeDashArray: 5, 
    },

    fill: {
      opacity: 1,
    },
  });

  const [series, setSeries] = useState([
    {
      name:`${t('newClientChart.newClient')}`,
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 50, 70],
      color: "#F55A65", // Set the color for "New Client" series
    },
    {
      name: `${t('newClientChart.regular')}`,
      data: [76, 85, 80, 90, 87, 80, 80, 80, 80, 30, 60, 50],
      color: "#1B55DA", // S
    },
  ]);
  useEffect(() => {
    setSeries([
      {
        name: `${t('newClientChart.newClient')}`,
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 50, 70],
        color: "#F55A65", // Set the color for "New Client" series
      },
      {
        name: `${t('newClientChart.regular')}`,
        data: [76, 85, 80, 90, 87, 80, 80, 80, 80, 30, 60, 50],
        color: "#1B55DA", // Set the color for "Regular Client" series
      },
    ]);
  }, [i18n.language]);
  useEffect(() => {
    const translatedLabels = [
      `${t("newClientChart.jan")}`,
      `${t("newClientChart.feb")}`,
      `${t("newClientChart.mar")}`,
      `${t("newClientChart.apr")}`,
      `${t("newClientChart.may")}`,
      `${t("newClientChart.june")}`,
      `${t("newClientChart.july")}`,
      `${t("newClientChart.aug")}`,
      `${t("newClientChart.sep")}`,
      `${t("newClientChart.oct")}`,
      `${t("newClientChart.nov")}`,
      `${t("newClientChart.dec")}`,
    ];

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: translatedLabels,
      },
    }));
  }, [i18n.language]);
  return (
    <Container isLanguage={i18n?.language}>
      <Header>
        <Title isLanguage={i18n.language}>{t("newClientChart.heading")}</Title>
      </Header>
      <div className="chart">
        <Chart options={options} series={series} type="bar" height={260} />
      </div>
    </Container>
  );
};

export default NewVsRegularChart;

const Container = styled.div`
  width: 100%;
  background-color: #ffff;
  border-radius: 12px;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  direction: ltr;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  .chart {
    width: 100%;
    padding: 1rem;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

 
  .apexcharts-legend {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
  .apexcharts-text tspan {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
`;

const Header = styled.div`
  padding: 0.2rem 1rem;
`;

const Title = styled.h3`
  border-bottom: 1px solid #eee9e4;
  padding: 1rem 0;
  font-weight: ${({ isLanguage }) => (isLanguage === "ar" ? 300 : 400)};

  font-size: 16px;
  color: #222222;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};

  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
