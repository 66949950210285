// deleteProductReducer.js

import { DELETE_PRODUCT_FAILURE, DELETE_PRODUCT_SUCCESS, DELETE_PRODUCT_REQUEST } from "./types";

const initialState = {
  loading: false,
  error: '',
};

const deleteProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: ''
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default deleteProductReducer;
