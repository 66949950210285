// reducers.js

import { ADD_EMPLOYEE_FAILURE, ADD_EMPLOYEE_SUCCESS, ADD_EMPLOYEE_REQUEST } from "./types";



const initialState = {
  data: null,
  loading: false,
  error: ''
};

const addEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };
    case ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default addEmployeeReducer;
