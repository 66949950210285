import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const ChurnRateChart = ({ t, lang }) => {
  const { i18n } = useTranslation();
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
        horizontal: false,
        columnWidth: "70%",
        endingShape: "rounded",

        dataLabels: {
          position: "top", 
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val} %`;
      },
      offsetY: -20,
      offsetX: -2,
      style: {
        fontSize: "10px",
        colors: ["#808396"],
      },
    },

    stroke: {
      show: true,
      width: 6,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        `${t("revenueChart.jan")}`,
        `${t("revenueChart.feb")}`,
        `${t("revenueChart.mar")}`,
        `${t("revenueChart.apr")}`,
        `${t("revenueChart.may")}`,
        `${t("revenueChart.june")}`,
        `${t("revenueChart.july")}`,
        `${t("revenueChart.aug")}`,
        `${t("revenueChart.sep")}`,
        `${t("revenueChart.oct")}`,
        `${t("revenueChart.nov")}`,
        `${t("revenueChart.dec")}`,
      ],
    },

    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5, 
      labels: {
        formatter: (val) => `${val}%`, 
      },
    },
    grid: {
      show: true, 
      borderColor: "#D0D0DA", 
      strokeDashArray: 5, 
    },

    fill: {
      opacity: 1,
    },
  });

  const [series, setSeries] = useState([
    {
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 50, 70],
      color: "#F55A65", 
    },
  ]);
  useEffect(() => {
    const translatedLabels = [
      `${t("revenueChart.jan")}`,
      `${t("revenueChart.feb")}`,
      `${t("revenueChart.mar")}`,
      `${t("revenueChart.apr")}`,
      `${t("revenueChart.may")}`,
      `${t("revenueChart.june")}`,
      `${t("revenueChart.july")}`,
      `${t("revenueChart.aug")}`,
      `${t("revenueChart.sep")}`,
      `${t("revenueChart.oct")}`,
      `${t("revenueChart.nov")}`,
      `${t("revenueChart.dec")}`,
    ];

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: translatedLabels,
      },
    }));
  }, [i18n.language]);
  return (
    <Container isLanguage={i18n.language}>
      <Header>
        <Title isLanguage={i18n.language}>
          {t("revenueChart.rateHeading")}
        </Title>
      </Header>
      <div className="chart">
        <Chart options={options} series={series} type="bar" height={260} />
      </div>
    </Container>
  );
};

export default ChurnRateChart;

const Container = styled.div`
  width: 100%;
  background-color: #ffff;
  border-radius: 12px;
  padding: 0rem 0.5rem;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  direction: ltr;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  @media (max-width: 600px) {
    padding: 0;
  }
  .apexcharts-text tspan {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
  
  .apexcharts-legend {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
`;

const Header = styled.div`
  padding: 0.2rem 1rem;
`;

const Title = styled.h3`
  border-bottom: 1px solid #eee9e4;
  padding: 1rem 0;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  font-weight: ${({ isLanguage }) => (isLanguage === "ar" ? 300 : 400)};
  font-size: 16px;
  color: #222222;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
`;
