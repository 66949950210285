// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCookie } from "./Utils/storageVariable";

const enTranslations = {
  home: {
    logo: "beautisry",
    HowItWorks: "How It Works",
    Services: "Services",
    WhyChooseUs: "Why Choose Us",
    Testimonials: "Testimonials",
    ForMerchant: "For Merchant",
    EasyProfileSetups: "Intuitive, Simple and Friendly",
    EasyProfileSetupDisc:
      "Easily navigate stores and review services. We made it so that you will experience elegance and efficiency seamlessly!",
    PreferWay: `Choose The Way You Prefer`,
    PreferWayDisc:
      "With Beautisry, you’ll find tons of options to choose from according to your preference.",
    footerText: "Copyright © 2024 Beautisry. All Rights Reserved.",
    LeftHeading:
      "Welcome to Your Ultimate Retreat for Wellness and beauty!",
    LeftSmallHeading:
      "Indulge in luxury and relaxation with our premier services. Book your appointment today to experience the epitome of self-care.",
    RightHeading: "“Creation of beauty is art.”",
    ActionButtonOne: "Book an Appointment",
    HowItWorksApp: "HOW OUR APP WORKS",
    HowItWorksDisc:
      "Explore our offerings and effortlessly book your next appointment online, anytime, anywhere.",
    QuickAppointMent: "Quick Appointments",
    QuickAppointMentDisc:
      "No more calls and hectic booking processes. Book an appointment with us for seamless experience.",
    QuickAppointMentDisc3:
      "With Beautisry, you’ll find tons of options to choose from according to your preference.",
    OurServices: "OUR SERVICES",
    OurServicesDisc:
      "Explore our offerings and effortlessly book your next appointment online, anytime, anywhere.",
    services: [
      {
        img: "./Assets/service1.png",
        name: "Teeth Care",
      },
      {
        img: "./Assets/service2.png",
        name: "Barbershop",
      },
      {
        img: "./Assets/service3.png",
        name: "Beauty Clinics",
      },
      {
        img: "./Assets/service4.png",
        name: "Spa/Massage",
      },
      {
        img: "./Assets/service5.png",
        name: "Hair Care",
      },
      {
        img: "./Assets/service6.png",
        name: "Men Suits",
      },
      {
        img: "./Assets/service7.png",
        name: "Dress Design",
      },
      {
        img: "./Assets/service8.png",
        name: "Beauty Salons",
      },
      {
        img: "./Assets/service9.png",
        name: "Event Planning",
      },
      {
        img: "./Assets/service10.png",
        name: "Tailors",
      },
      {
        img: "./Assets/service11.png",
        name: "Waxing",
      },
      {
        img: "./Assets/service12.png",
        name: "Wedding Planning",
      },
      {
        img: "./Assets/service13.png",
        name: "Nails for Women",
      },
      {
        img: "./Assets/service14.png",
        name: "Nails for Men",
      },
      {
        img: "./Assets/service15.png",
        name: "Photography",
      },
      {
        img: "./Assets/service16.png",
        name: "Skin Care",
      },
    ],
    OurWhyChooseUs: "WHY CHOOSE US",
    soon: "Soon",
    OurWhyChooseUsDisc:
      "Explore our offerings and effortlessly book your next appointment online, anytime, anywhere.",
    chooseUsCards: [
      {
        img: "./Assets/chooseUsCard1.svg",
        heading: "24/7 Booking",
        disc: "Our platform operates around the clock, allowing you to book appointments at any hour of the day. Whether you’re an early bird or a night owl, we’re always open online.",
      },
      {
        img: "./Assets/chooseUsCard2.svg",
        heading: "User-Friendly Interface",
        disc: "Navigate with ease through our intuitive booking system. Our platform is designed with you in mind, making it simple to find, compare, and book the services you love.",
      },
      {
        img: "./Assets/chooseUsCard3.svg",
        heading: "Instant Confirmations",
        disc: "Say goodbye to waiting for calls or email responses. Book your appointment and receive immediate confirmation, along with reminders as your appointment approaches.",
      },
      {
        img: "./Assets/chooseUsCard4.svg",
        heading: "Verified Reviews",
        disc: "Make informed decisions with access to authentic reviews from other users. Our community-driven ratings help ensure the quality and reliability of all services offered.",
      },
      {
        img: "./Assets/chooseUsCard5.svg",
        heading: "Premium Partners",
        disc: "We've partnered with the best local businesses, ensuring that you have access to top-tier services in grooming, beauty, wellness, and spa treatments.",
      },
    ],
    chooseUsInfo:
      "Book your next appointment with us and discover why we are the preferred choice for discerning clients like you.",
    downloadgHeading: `DOWNLOAD & BOOK AN`,
    appointment: "APPOINTMENT",
    downloadDisc: "Manage, grow and advertise your business, hassle free.",
    signUpHeading: "SIGN UP FOR YOUR",
    signUpDisc:
      "Explore our offerings and effortlessly book your next appointment online, anytime, anywhere.",
    business: "BUSINESS",
    sginUpMarchant: "Sign Up As Merchant",
    OurCostumer: "WHAT OUR CUSTOMERS ARE SAYING!",
    Customer: [
      {
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis, magna at pulvinar bibendum, ante purus maximus mauris, non consectetur justo tellus at metus.",
        name: "Robert Martin",
      },
      {
        disc: "Pellentesque eget posuere enim. Nunc eget lectus ut massa mattis vestibulum eu vel ex. Cras iaculis lacinia lacus, ut imperdiet odio iaculis vel. Maecenas et ex eget arcu vestibulum gravida",
        name: "John Martin",
      },
      {
        disc: "Vivamus vulputate commodo ligula, non congue justo laoreet eu. Etiam finibus dui at erat aliquet iaculis. Donec laoreet vitae purus quis gravida. Quisque sollicitudin neque in nisi laoreet",
        name: "Beauty Care",
      },
      {
        disc: "Vivamus vulputate commodo ligula, non congue justo laoreet eu. Etiam finibus dui at erat aliquet iaculis. Donec laoreet vitae purus quis gravida. Quisque sollicitudin neque in nisi laoreet",
        name: "Nina Anderson",
      },
      {
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis, magna at pulvinar bibendum, ante purus maximus mauris, non consectetur justo tellus at metus. ",
        name: "Beauty Care",
      },
      {
        disc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lobortis, magna at pulvinar bibendum, ante purus maximus mauris, non consectetur justo tellus at metus. ",
        name: "Nina Anderson",
      },
      {
        disc: "Pellentesque eget posuere enim. Nunc eget lectus ut massa mattis vestibulum eu vel ex. Cras iaculis lacinia lacus, ut imperdiet odio iaculis vel. Maecenas et ex eget arcu vestibulum gravida",
        name: "John Martin",
      },
    ],
    footerDisc:
      "Indulge in luxury, relaxation, and style at our premier online booking platform for all your grooming, wellness, and beauty needs.",
    needTalk: "Need to talk it through? Our team is ready to listen.",
    stayConnect:
      "Stay Connected! Follow us for updated, tips, and our latest projects.",
    quickLink: "Quick Link:",
    TermsAndconditions: "Terms & conditions",
    privarcyPolicy: "Privacy Policy",
    Home: "Home",
    formHeading:
      "Got Questions? We're here to provide the answers and guidance you need.",
    requirment: "Lets discuss your requirements.",
    fullNamePlaceholder: "Full Name",
    emailPlaceholder: "Email Address",
    phoneNumberPlaceholder: "Phone Number",
    messagePlaceholder: "Message",
    sendButton: "Send Message",
  },
  signupContent: {
    leftSection: {
      heading:
        "Welcome to Your Ultimate Retreat for Grooming, Wellness, and Beauty.",
      description:
        "We believe in Beautisry that creation of beauty is art, join us!",
    },
    rightSection: {
      logo: "beautisry",
      buttonText: "Continue",
      englishText: "English",
      label: "Phone Number",
      placeholder: "Enter Phone Number",
      bottomText: "already have an account?",
      signUpText:"Create an Account?",
      signInText:"Sign In",
      bottomTextOne:"Are you new?"

    },
    waitingText:" You can resend OTP in",
    seconds:"seconds",
    resendText:"Didn't recieved the verification code?",
    resend:"Resend."
  },
  planContent: {
    englishText: "English",
    planHeading: "Select a plan",
    planText: "We have few options for you to choose from.",
    planMonth: "First 2 Months are Free on us!",
    yearlyPlanTitle: "Yearly Plan",
    yearlyPlanPrice: "90 SAR per month",
    yearlyPlanButton: "Select Plan",
    yearlyPlanFeaturesOne: "Unlimited Services",
    yearlyPlanFeaturesTwo: "Unlimited Appointments",
    yearlyPlanFeaturesThree: "Unlimited Images",
    internationPaymentSupport:"Internal payments support",
    yearlyPlanFeaturesFour: "500+ Members",
    yearlyPlanFeaturesFive: "Internal marketing support",
    yearlyPlanFeaturesSix: "24/7 Customer Support",
    monthlyPlanTitle: "MONTHLY Plan",
    monthlyPlanPrice: "99 SAR per month",
    monthlyPlanButton: "Select Plan",
    monthlyPlanFeaturesOne: "Unlimited Services",
    monthlyPlanFeaturesTwo: "Unlimited Appointments",
    monthlyPlanFeaturesThree: " Unlimited Images",
    monthlyPlanFeaturesFour: " 500+ Members",
    monthlyPlanFeaturesFive: "Internal marketing support",
    monthlyPlanFeaturesSix: "24/7 Customer Support",
    monthlyPlanStaff: "75 SAR per Additional staff",
    yearlyPlanStaff: "70 SAR per Additional staff",
  },
  merchant: {
    nameOne: "Personal Information",
    nameTwo: "Merchant Details",
    nameThree: "Service Details",
    nameFour: "Business Schedules",
    nameFive: "Team Details",
    nameSix: "Bank Details",
    englishText: "English",
    heading: "Personal Information",
    description: "Get started with online appointment services",
    nameInputPlaceholeder: "Enter First Name",
    inputName: "Name",
    lastNameInputPlaceholeder: "Enter Last Name",
    inputLastName: "Last Name",
    emailInputPlaceholder: "Enter work email",
    inputEmail: "Work Email",
    cancelButtonText: "Cancel",
    buttonText: "Continue",
  },
  merchantDetail: {
    heading: "Merchant Information",
    description: "Get started with online appointment services",
    merchantTypeLabel: "Merchant Type",
    freelanceOption: "Freelance",
    shopOption: "Shop",
    businessLogoLabel: "Business Logo",
    selectLogoText: "Select Logo",
    businessNamePlaceholder: "Enter Business Name",
    businessNameLabel: "Business Name",
    arabicBusinessNamePlaceholder: "Enter your business name in arabic",
    arabicBusinessNameLabel: "Business Name (In Arabic)",
    uploadFileLabel: "Upload File",
    businessRegistrationDocLabel: "Business Registration Document",
    nationalAddressPlaceholder: "AAAA 1111",
    nationalAddressLabel: "National Address",
    chooseLocationLabel: "Please choose your location on the map",
    latitudePlaceholder: "Latitude",
    longitudePlaceholder: "Longitude",
    prevButtonText: "Previous",
    continueButtonText: "Continue",
    autoPlaceHolder: "Address, airport, hotel, ...",
  },
  serviceDetails: {
    text: "SERVICE",
    desc:"Enter A Description",
    heading: "Service Details",
    description: "Get started with online appointment services",
    targetedCustomersLabel: "Targeted Customers",
    menOption: "Men",
    womenOption: "Women",
    whereToFindLabel: "Where can clients find you?",
    atHomeOption: "At home",
    atStoreOption: "At store",
    businessImagesLabel: "Business Images",
    selectImagesText: "upload images",
    providedServicesLabel: "Provided Services",
    addAnotherServiceButtonText: "Add Another Service",
    servicePlaceholder: "Service Name",
    costPlaceholder: "Service Cost",
    descriptionPlaceholder: "Service Description",
    previousButtonText: "Previous",
    continueButtonText: "Continue",
    subCategoryEn :"Sub Category English",
    subCategoryAr:"Sub Category Arabic",
    serviceNameEn:"Service Name English",
    serviceNameAr:"Service Name Arabic",
    descriptionEn :"Description English",
    descriptionAr :"Description Arabic",
    timeInMinutes:"Time in minutes",
    serviceCost:"Service Cost",
    category:"Category",
    customerNotes:"Customer Notes",
    notes:"Note",
    addAnotherNotes:"Add Another Note",
    titlePlacholder:"Note Title",
    bodyPlacholder:"Note Body"
  },
  businessSchedule: {
    heading: "Business Schedule",
    description: "Select your business days & hours",
    businessDaysLabel: "Business Days",
    sundayOption: "Sunday",
    mondayOption: "Monday",
    tuesdayOption: "Tuesday",
    wednesdayOption: "Wednesday",
    thursdayOption: "Thursday",
    fridayOption: "Friday",
    saturdayOption: "Saturday",
    businessHoursLabel: "Business Hours",
    dayLabel: "Day",
    openTimeLabel: "Open Time",
    closeTimeLabel: "Close Time",
    addAnotherSlotsButtonText: "Add Another Slots",
    previousButtonText: "Previous",
    continueButtonText: "Continue",
  },
  teamDetails: {
    heading: "Team Details",
    text: "MEMBER",
    description: "Get started with online appointment services",
    addMemberButtonText: "Add Another Member",
    memberFirstNamePlaceholder: "Member Name",
    memberLastNamePlaceholder: "Member Last Name",
    memberPhoneNumberPlaceholder: "Member Phone Number",
    previousButtonText: "Previous",
    continueButtonText: "Continue",
  },
  bankingDetails: {
    heading: "Banking Details",
    description: "Enter bank details where you would like to receive money in",
    acceptOnlinePaymentsLabel: "Are you accepting online payments?",
    yesOption: "Yes",
    noOption: "No",
    bankNamePlaceholder: "Enter Bank Name",
    bankNameLabel: "Bank Name",
    accountNumberPlaceholder: "Enter Account Number (IBAN)",
    accountNumberLabel: "Account Number (IBAN)",
    accountNamePlaceholder: "Enter Account Name ",
    accountNameLabel: "Account Name",
    consentText:
      "I hereby consent to affirm that all information provided by me is true, to adhere to the regulations of the country, and acknowledge that there exists no legal obligation.",
    previousButtonText: "Previous",
    signUpButtonText: "Sign Up",
    onlinePayText:"Receive payments online only",
    phyPayText:"Receive payments physically and online",
    chooseYourWay:"Choose your way:"
  },

  Success: {
    buttonText: "English",
    successMessage: "Account successfully created!",
    description:
      "You can now download the app and login to start taking appointments.",

    infoMessage:
      "We have also sent you a link in your email with details on how to contact support. Please check.",
  },
  ads: {
    activeContent: {
      activeInfo:
        " Currently, your ADs feature is inactive If you wish to activate this feature, please send us your request!",
      btn: "Activate ADs   ",
    },
    pendingContent: {
      pendingInfo:
        " Currently, your ADs feature is inactive If you wish to activate this feature, please send us your request!",
      btn: "Activate ADs   ",
    },
    deactivateContent: {
      deactivateInfo:
        " Currently, your ADs feature is inactive If you wish to activate this feature, please send us your request!",
      btn: "Activate ADs   ",
    },
    interestedlabel: "Which ads are you interested in.",
    interestedInfo: "",
    checkbox_1: " Dashboard",
    checkbox_2: " Explore",
    checkbox_3: " Ranking",
    singleStartDate: " Start Date",
    singleStartDateInfo:
      " Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    singleStartDateplaceholder: "Select Date",
    durationlabel: "Start Duration",
    durationInfo: " Please select Start and End date ",
    startdDate: "Start Date",
    endDate: "End Date",
    startdDateplaceholder: "Select Start Date",
    endDateplaceholder: "Select End Date",
    updateBtn: "Update",
  },
  sideNavContent: {
    dashboard: "Dashboard",
    personalInfo: "Personal Information",
    merchantDetail: "Merchant Details",
    serviceDetail: "Service Details",
    BusinessDetail: "Business Schedules",
    teamDetails: "Team Details",
    bankDetails: "Bank Details",
    ads: "Ads",
    logout: "Logout",
  },
  appointmentChart: {
    heading: "Appointment",
    total: "Total",
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    june: "June",
    july: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  },
  revenueChart: {
    heading: "Revenue Generated",
    rentationHeading: "Customer Retention",
    rateHeading: "Churn Rate",
    customerHeading: "Customer Satisfaction Overall",
    employeHeading: "Customer Satisfaction Per Employee",
    growthHeading: "Customer Growth",
    placeholder: "Employee Name",

    total: "Total",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    june: "Jun",
    july: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
  },
  newClientChart: {
    heading: "New Clients vs Regular",
    total: "Total",
    jan: "Jan",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "May",
    june: "Jun",
    july: "Jul",
    aug: "Aug",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Dec",
    newClient: "New Client",
    regular: "Regular",
  },
  Update: "Update",
  signOutText: "Sign Out",
  teamDetailPage: {
    firstName: "Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    action: "Action",
    btnText: "Add New Member",
  },
  categoryModal:{
    heading:"categories",
    cancel:"Cancel",
    confirm:"Confirm",
    pleaseSelect:"Please Select Targeted Customers To Select Categories"
  }
};

const arTranslations = {
  home: {
    logo: "التجميل",
    HowItWorks: "وش نسوي",
    Services: "خدماتنا",
    WhyChooseUs: "ليش بيوتسري",
    Testimonials: "رأيكم فينا",
    ForMerchant: "إنضم كشريك ",
    EasyProfileSetups: "تصميم سهل و بسيط و مرتب",
    EasyProfileSetupDisc:
      "متاجر مرتبة بكل سهولة، تصميم جميل، راعينا فيها ادق التفاصيل عشان تعيش فيه تجربة مختلفة.",
    PreferWay: "اختر الطريقة التي تفضلها",
    PreferWayDisc:
      "مع بيوتسري الخيارات كثير و متنوعة، الستايل اللي يناسبك بالطريقة اللي تبيها. ",

    LeftHeading: `أهلا فيك في عالمك المثالي للعناية بالصحة و الجمال!`,
    LeftSmallHeading:
      "عيش تجربة مختلفة من الاسترخاء و الرفاهية مع متاجر بيوتسري. احجز موعدك اليوم وعيش تجربة مختلفة.",
    RightHeading: `”صناعة الجمال، فن.”`,
    ActionButtonOne: "حجز موعد",
    HowItWorksApp: "كيف يشتغل بيوتسري؟",
    HowItWorksDisc:
      "استكشف عروضنا واحجز موعدك اليوم بسهولة في أي وقت وفي أي مكان.",
    QuickAppointMent: " مواعيد سريعة ",
    QuickAppointMentDisc:
      "بدون تليفونات، واتس، سناب او رسائل. إحجز موعدك بدون ما ترجع لأحد. ",
    QuickAppointMentDisc3:
      " مع بيوتسري ستجد الكثير من الخيارات للاختيار من بينها وفقًا لتفضيلاتك.",

    OurServices: "خدماتنا",
    OurServicesDisc:
      "بدون ما تحتاج تكلم أحد، إحجز موعدك من خلال التطبيق بالوقت اللي يناسبك",
    services: [
      {
        img: "./Assets/service1.png",
        name: "أسنان",
      },
      {
        img: "./Assets/service2.png",
        name: "حلاقة",
      },
      {
        img: "./Assets/service3.png",
        name: "جلدية و تجميل",
      },
      {
        img: "./Assets/service4.png",
        name: "سبا/مساج",
      },
      {
        img: "./Assets/service5.png",
        name: "عناية بالشعر",
      },
      {
        img: "./Assets/service6.png",
        name: "بدل رجالية",
      },
      {
        img: "./Assets/service7.png",
        name: "تصميم فساتين",
      },
      {
        img: "./Assets/service8.png",
        name: "صالون تجميل",
      },
      {
        img: "./Assets/service9.png",
        name: "استعداد المناسبات",
      },
      {
        img: "./Assets/service10.png",
        name: "خياطة",
      },
      {
        img: "./Assets/service11.png",
        name: "شمع",
      },
      {
        img: "./Assets/service12.png",
        name: "استعداد الأفراح",
      },
      {
        img: "./Assets/service13.png",
        name: "عناية بالأظافر للسيدات",
      },
      {
        img: "./Assets/service14.png",
        name: "عناية بالأظافر للرجال",
      },
      {
        img: "./Assets/service15.png",
        name: "تصوير",
      },
      {
        img: "./Assets/service16.png",
        name: "عناية بالبشرة",
      },
    ],
    OurWhyChooseUs: "ليش بيوتسري",
    soon: "قريبا",
    OurWhyChooseUsDisc:
      "لا مزيد من المكالمات وعمليات الحجز المطولة. احجز موعدًا معنا لتجربة سلسة.",
    chooseUsCards: [
      {
        img: "./Assets/chooseUsCard1.svg",
        heading: "حجز على مدار الساعة ٢٤/٧",
        disc: "بيوتسري يتيح لك حجز الموعد اللي تبيه في اي وقت. صبح، ليل، على راحتك.",
      },
      {
        img: "./Assets/chooseUsCard2.svg",
        heading: "واجهة سهلة و بسيطة ",
        disc: "راعينا تكون الواجهة بسيطة، جميلة و سلسة. ما راح تحتاج تدور كثير عشان تحصل اللي تبيه، قارن المتاجر و احجز اللي يناسبك. ",
      },
      {
        img: "./Assets/chooseUsCard3.svg",
        heading: "تأكيدات فورية",
        disc: "حجزك مؤكد و فوري، بمجرد تأكيد اهتمامك بالخدمة، مع تنبيهات تذكرك بقرب موعدك مع مقدم الخدمة.",
      },
      {
        img: "./Assets/chooseUsCard4.svg",
        heading: "تقييمات موثقة",
        disc: "شف وش يقولون الناس بعد تعاملهم مع مقدمين الخدمة، التقييمات تكون من ناس جربوا و تساعدك تأخذ القرار المناسب لك.",
      },
      {
        img: "./Assets/chooseUsCard5.svg",
        heading: "شركاء مميزين",
        disc: "المتاجر الموجودة على بيوتسري هم مقدمين خدمة مختصين بالجمال في أحيائنا و مدنا في جميع انحاء المملكة و المنطقة العربية .",
      },
    ],
    chooseUsInfo: "إحجز موعدك و اكتشف الخدمات المقدمة من بيوتسري",
    downloadgHeading: "حمل التطبيق و احجز موعدك",
    downloadDisc:
      "استكشف خدمات و عروض مزودين الخدمة من التطبيق من أي مكان و في اي وقت.",
    signUpHeading: "التسجيل",
    signUpDisc: "دير عملك اليومي، استمتع بالنمو، أعلن، بكل اريحية و بدون تكلف.",
    appointment: "اليوم",
    business: "كمزود خدمة",
    sginUpMarchant: "انضم كشريك",
    OurCostumer: "وش يقولون عن بيوتسري؟",
    footerText: "حقوق الطبع والنشر © 2024 بيوتسري. كل الحقوق محفوظة.",
    Customer: [
      {
        disc: "لوريم إيبسوم دولور سيت آميت، كونسيكتيتور أديبيسسينغ إيليت. أليكوام لوبورتيس، ماغنا آت بولفينار بيبيندوم، أنتي بوروس ماكسيموس ماوريس، نون كونسيكتيتور جستو تيلوس آت ميتوس.",
        name: "روبرت مارتن",
      },
      {
        disc: "بيلنتسيسكو إيجت بوسويري إنيم. نونك إيجيت ليكتوس أوت ماسا ماتتيس فيستيبولوم إيو فيل إكس. كراس إياكوليس لاسينيا لاكوس، أوت إمبيرديت أوديو إياكوليس فيل. مايسناس إت إكس إيجيت آركو فيستيبولوم جرافيدا",
        name: "جون مارتن",
      },
      {
        disc: "فيفاموس فولبوتات كومودو ليجولا، نون كونغوه جوستو لاوريت إيو. إتيام فينيبوس دوي آت إرات أليكويت إياكوليس. دونيك لاوريت فيتا بوروس كويس غرافيدا. كويسكو سوليسيتودين نيكوي إن نيسي لاوريت",
        name: "العناية بالجمال",
      },
      {
        disc: "فيفاموس فولبوتات كومودو ليجولا، نون كونغوه جوستو لاوريت إيو. إتيام فينيبوس دوي آت إرات أليكويت إياكوليس. دونيك لاوريت فيتا بوروس كويس غرافيدا. كويسكو سوليسيتودين نيكوي إن نيسي لاوريت",
        name: "نينا أندرسون",
      },
      {
        disc: "لوريم إيبسوم دولور سيت آميت، كونسيكتيتور أديبيسسينغ إيليت. أليكوام لوبورتيس، ماغنا آت بولفينار بيبيندوم، أنتي بوروس ماكسيموس ماوريس، نون كونسيكتيتور جستو تيلوس آت ميتوس. ",
        name: "العناية بالجمال",
      },
      {
        disc: "لوريم إيبسوم دولور سيت آميت، كونسيكتيتور أديبيسسينغ إيليت. أليكوام لوبورتيس، ماغنا آت بولفينار بيبيندوم، أنتي بوروس ماكسيموس ماوريس، نون كونسيكتيتور جستو تيلوس آت ميتوس. ",
        name: "نينا أندرسون",
      },
      {
        disc: "بيلنتسيسكو إيجت بوسويري إنيم. نونك إيجيت ليكتوس أوت ماسا ماتتيس فيستيبولوم إيو فيل إكس. كراس إياكوليس لاسينيا لاكوس، أوت إمبيرديت أوديو إياكوليس فيل. مايسناس إت إكس إيجيت آركو فيستيبولوم جرافيدا",
        name: "جون مارتن",
      },
    ],
    footerDisc:
      "عيش تجربة الرفاهية، الاسترخاء و الاناقة في عبر منصتنا. بيوتسري تلبي جميع احتياجاتك في مجال العناية بالصحة و العافية و الجمال. ",
    needTalk: "تحتاج للتحدث لفريقنا؟ جاهزين بأي وقت ",
    stayConnect:
      "خلنا على اتصال! تابعنا للحصول على آخر المستجدات، النصائح و المشاريع القادمة",
    quickLink: "روابط سريعة:",
    TermsAndconditions: "الشروط و الأحكام",
    privarcyPolicy: "سياسة الخصوصية",
    Home: "الرئيسية",
    formHeading: "باقي عندك اسئلة؟ فريقنا بخدمتكم بأي وقت",
    requirment: "خلنا نناقش متطلباتك ",
    fullNamePlaceholder: "الاسم الكامل",
    emailPlaceholder: "عنوان البريد الإلكتروني",
    phoneNumberPlaceholder: "رقم الهاتف",
    messagePlaceholder: "رسالة",
    sendButton: "ارسال",
  },
  signupContent: {
    leftSection: {
      heading: "أهلا فيك في عالمك المثالي للعناية بالصحة و الجمال.",
      description: "مؤمنين في بيوتسري ان صناعة الجمال فن، شاركنا الرحلة!",
    },
    rightSection: {
      logo: "التجميل",
      buttonText: "متابعة",
      englishText: "الإنجليزية",
      label: "رقم الهاتف",
      placeholder: "أدخل رقم الهاتف",
      bottomText:"هل لديك حساب؟",
      signUpText:"انشاء حساب",
      signInText:"تسجيل الدخول",
      bottomTextOne:"هل انت جديد؟",
    },
    waitingText:"يمكنك إعادة إرسال OTP في",
    seconds:"ثواني",
    resendText:"لم تتلق رمز التحقق؟",
    resend:"إعادة إرسال."
    
  },
  planContent: {
    arabicText: "عربى",
    planHeading: "اختار الخطة اللي تناسبك",
    planText: "غير الباقة، كنسل الإشتراك اي وقت",
    planMonth: "اول شهرين مجانًا علينا!",
    yearlyPlanTitle: "الخطة السنوية",
    yearlyPlanPrice: "٩٠ ريال في الشهر",
    internationPaymentSupport:"دعم للمدفوعات",
    yearlyPlanButton: "اختار الباقة",
    yearlyPlanFeaturesOne: "خدمات غير محدودة",
    yearlyPlanFeaturesTwo: "مواعيد غير محدودة",
    yearlyPlanFeaturesThree:"عدد غير محدود من الصور",
    yearlyPlanFeaturesFour: "دعم تسويقي داخلي",
    yearlyPlanFeaturesFive: "إمكانية إضافة ٥٠٠  عضو فأكثر",
    yearlyPlanFeaturesSix: "دعم ٢٤/٧ للمتاجر",
    monthlyPlanTitle: "الباقه الشهرية",
    monthlyPlanPrice: "٩٩ ريال في الشهر",
    monthlyPlanButton: "اختار الباقة",
    monthlyPlanFeaturesOne: "خدمات غير محدودة",
    monthlyPlanFeaturesTwo: "مواعيد غير محدودة",
    monthlyPlanFeaturesThree: "عدد غير محدود من الصور",
    monthlyPlanFeaturesFour: "دعم تسويقي داخلي",
    monthlyPlanFeaturesFive: "إمكانية إضافة ٥٠٠  عضو فأكثر",
    monthlyPlanFeaturesSix: "دعم ٢٤/٧ للمتاجر",
    monthlyPlanStaff: "٧٥ ريال لإضافة شخص آخر للفريق",
    yearlyPlanStaff: "٧٠ ريال لإضافة شخص آخر للفريق",
  },
  merchant: {
    nameOne: "معلومات شخصية",
    nameTwo: "تفاصيل التاجر",
    nameThree: "تفاصيل الخدمة",
    nameFour: "جداول الأعمال",
    nameFive: "تفاصيل الفريق",
    nameSix: "تفاصيل البنك",
    englishText: "العربيه",
    heading: "المعلومات الشخصية",
    description: "ابدأ في خدمات المواعيد عبر الإنترنت",
    nameInputPlaceholeder: "أدخل الاسم الأول",
    inputName: "الاسم",
    lastNameInputPlaceholeder: "أدخل الاسم الأخير",
    inputLastName: "الاسم الأخير",
    emailInputPlaceholder: "أدخل البريد الإلكتروني للعمل",
    inputEmail: "البريد الإلكتروني للعمل",
    cancelButtonText: "إلغاء",
    buttonText: "متابعة",
  },
  merchantDetail: {
    heading: "معلومات التاجر",
    description: "ابدأ في خدمات الحجز عبر الإنترنت",
    merchantTypeLabel: "نوع التاجر",
    freelanceOption: "عمل حر",
    shopOption: "متجر",
    businessLogoLabel: "شعار الشركة",
    selectLogoText: "حدد الشعار",
    businessNamePlaceholder: "أدخل اسم الشركة",
    businessNameLabel: "اسم الشركة",
    arabicBusinessNamePlaceholder: "أدخل اسم شركتك باللغة العربية",
    arabicBusinessNameLabel: "اسم الشركة (بالعربية)",
    uploadFileLabel: "تحميل الملف",
    businessRegistrationDocLabel: "السجل التجاري",
    nationalAddressPlaceholder: "AAAA 1111",
    nationalAddressLabel: "العنوان الوطني",
    chooseLocationLabel: "الرجاء اختيار موقعك على الخريطة",
    latitudePlaceholder: "خط العرض",
    longitudePlaceholder: "خط الطول",
    prevButtonText: "السابق",
    continueButtonText: "متابعة",
    autoPlaceHolder: "العنوان، المطار، الفندق,...",
  },
  serviceDetails: {
    heading: "تفاصيل الخدمة",
    text: "الخدمة",
    desc:"أدخل وصفاً",
    description: "ابدأ في خدمات المواعيد عبر الإنترنت",
    descriptionEn :"وصف الخدمة بالانجليزية",
    descriptionAr :"وصف الخدمة بالعربية",
    targetedCustomersLabel: "العملاء المستهدفين",
    menOption: "الرجال",
    womenOption: "النساء",
    whereToFindLabel: "أين يمكن للعملاء العثور عليك؟",
    atHomeOption: "في المنزل",
    atStoreOption: "في المتجر",
    businessImagesLabel: "صور الأعمال التجارية",
    selectImagesText: "حمل الصور",
    providedServicesLabel: "الخدمات المقدمة",
    addAnotherServiceButtonText: "إضافة خدمة أخرى",
    servicePlaceholder: "اسم الخدمة",
    costPlaceholder: "تكلفة الخدمة",
    descriptionPlaceholder: "وصف الخدمة",
    previousButtonText: "السابق",
    continueButtonText: "متابعة",
    category:"نوع الخدمة",
    subCategoryEn:"نوع الخدمة الفرعية بالانجليزية",
    subCategoryAr:"نوع الخدمة الفرعية بالعربية",
    serviceNameEn:"اسم الخدمه بالانجليزية",
    serviceNameAr:"اسم الخدمه بالعربية",
    timeInMinutes:"الوقت بالدقائق",
    serviceCost:"تكلفه الخدمة",
    customerNotes:"ملاحظات الزبون",
    notes:"ملاحظة",
    addAnotherNotes:"أضف ملاحظة أخرى",
    titlePlacholder:"عنوان الملاحظة",
    bodyPlacholder:" الملاحظة"
  },
  businessSchedule: {
    heading: "جدول العمل التجاري",
    description: "حدد أيام وساعات عملك",
    businessDaysLabel: "أيام العمل",
    sundayOption: "الأحد",
    mondayOption: "الاثنين",
    tuesdayOption: "الثلاثاء",
    wednesdayOption: "الأربعاء",
    thursdayOption: "الخميس",
    fridayOption: "الجمعة",
    saturdayOption: "السبت",
    businessHoursLabel: "ساعات العمل",
    dayLabel: "اليوم",
    openTimeLabel: "وقت الفتح",
    closeTimeLabel: "وقت الإغلاق",
    addAnotherSlotsButtonText: "إضافة فترات زمنية أخرى",
    previousButtonText: "السابق",
    continueButtonText: "متابعة",
  },
  teamDetails: {
    heading: "تفاصيل الفريق",
    description: "ابدأ في خدمات المواعيد عبر الإنترنت",
    addMemberButtonText: "إضافة عضو آخر",
    memberFirstNamePlaceholder: "اسم العضو",
    memberLastNamePlaceholder: "الاسم الأخير للعضو",
    memberPhoneNumberPlaceholder: "رقم هاتف العضو",
    previousButtonText: "السابق",
    continueButtonText: "متابعة",
    text: "العضو",
  },
  bankingDetails: {
    heading:"التفاصيل البنكية",
    description: "أدخل تفاصيل البنك حيث ترغب في استلام الأموال فيها",
    acceptOnlinePaymentsLabel: "هل تقبل المدفوعات عبر الإنترنت؟",
    yesOption: "نعم",
    noOption: "لا",
    bankNamePlaceholder: "أدخل اسم البنك",
    bankNameLabel: "اسم البنك",
    accountNumberPlaceholder: "ادخل رقم الحساب (ايبان)",
    accountNumberLabel: "رقم الحساب (ايبان)",
    accountNamePlaceholder: "ادخل اسم الحساب",
    accountNameLabel: "اسم الحساب",
    consentText:
      "اقر هنا بأن جميع المعلومات التي قدمتها صحيحة، و على الالتزام بقوانين البلاد و أقر بعدم وجود إلتزام قانوني.",
    previousButtonText: "السابق",
    signUpButtonText: "التسجيل",
    onlinePayText:"استقبال المدفوعات الالكترونية فقط",
    phyPayText:"الدفع من خلال المتجر و الدفع الالكتروني معًا",
    chooseYourWay:"اختر الطريقة اللي تفضلها:"
  },
  Success: {
    buttonText: "العربية",
    successMessage: "تم إنشاء الحساب بنجاح!",
    description: "يمكنك الآن تنزيل التطبيق وتسجيل الدخول لبدء تحديد المواعيد.",
    googlePlayImage: "./Assets/googlePlay.png",
    appStoreImage: "./Assets/AppStore.png",
    infoMessage:
      "لقد أرسلنا لك أيضًا رابطًا في بريدك الإلكتروني مع تفاصيل حول كيفية الاتصال بالدعم. يرجى التحقق.",
  },
  ads: {
    activeContent: {
      activeInfo:
        "حاليًا خدمات التسويق غير مفعلة، في حال رغبتكم بتفعيلها أضغط على خاصية تفعيل التسويق!",
      btn: "فعل التسويق",
    },
    pendingContent: {
      pendingInfo:
        "خاصية التسويق حاليًا تحت المراجعة، راح يتم التواصل معاكم عند أول فرصة متاحة!",
      btn: "الغاء طلب التسويق ",
    },
    deactivateContent: {
      deactivateInfo: `حاليًا خاصية طلب التسويق مفعلة! في حال رغبتكم بإيقافها الرجاء الضغط على "إيقاف التسويق"`,
      btn: "إيقاف التسويق",
    },
    interestedlabel: "ما هي الإعلانات التي تهتم بها.",
    interestedInfo: "",
    checkbox_1: " الواجهة الأساسية",
    checkbox_2: " إكسبلور",
    checkbox_3: " ترتيب المتاجر",
    singleStartDate: "تاريخ البدايه",
    singleStartDateInfo: " نص وهمي لمحتوي سوف يوضع فيما بعد",
    singleStartDateplaceholder: "اختر التاريخ",
    durationlabel: "مدة الإعلانات",
    durationInfo:"الرجاء تحديد تاريخ البدء والانتهاء",
    startdDate: "تاريخ البدايه",
    endDate: "تاريخ الانتهاء",
    startdDateplaceholder: "اختر تاريخ البدايه",
    endDateplaceholder: "اختر تاريخ الانتهاء",
    updateBtn: "تحديث",
  },
  sideNavContent: {
    dashboard: "لوحه التحكم",
    personalInfo: "معلومات شخصيه",
    merchantDetail: "تفاصيل التاجر",
    serviceDetail: "تفاصيل الخدمة",
    BusinessDetail: "جداول الأعمال",
    teamDetails: "تفاصيل الفريق",
    bankDetails: "التفاصيل المصرفية",
    ads: "إعلانات",
    logout: "تسجيل الخروج",
  },
  appointmentChart: {
    heading: "إجمالي المواعيد",
    total: "الإجمالي",
    jan: "يناير",
    feb: "فبراير",
    mar: "مارس",
    apr: "ابريل",
    may: "مايو",
    june: "يونيو",
    july: "يوليو",
    aug: "اغسطس",
    sep: "سبتمبر",
    oct: "اكتوبر",
    nov: "نوفمبر",
    dec: "ديسمبر",
  },
  revenueChart: {
    heading: "الإيرادات المولدة",
    rentationHeading: "معدل المحافظة على العملاء",
    rateHeading: "معدل الاستنزاف",
    customerHeading: "رضا العملاء بشكل عام",
    employeHeading: "رضا العملاء لكل عامل",
    growthHeading: "نمو العملاء",
    placeholder: "اسم الموظف",
    total: "الإجمالي",
    jan: "يناير",
    feb: "فبراير",
    mar: "مارس",
    apr: "ابريل",
    may: "مايو",
    june: "يونيو",
    july: "يوليو",
    aug: "اغسطس",
    sep: "سبتمبر",
    oct: "اكتوبر",
    nov: "نوفمبر",
    dec: "ديسمبر",
  },
  newClientChart: {
    heading: "العملاء الجدد مقابل العملاء المنتظمين",
    total: "الإجمالي",
    jan: "يناير",
    feb: "فبراير",
    mar: "مارس",
    apr: "ابريل",
    may: "مايو",
    june: "يونيو",
    july: "يوليو",
    aug: "اغسطس",
    sep: "سبتمبر",
    oct: "اكتوبر",
    nov: "نوفمبر",
    dec: "ديسمبر",
    newClient: "عميل جديد",
    regular: "منتظم",
  },
  Update: "تحديث",
  signOutText: "خروج",
  teamDetailPage: {
    firstName: "الاسم",
    lastName: "الاسم الاخير",
    phoneNumber: "رقم الهاتف",
    action: "التصرف",
    btnText: "اضافه عضو جديد",
  },
  categoryModal:{
    heading:"نوع الخدمات",
    cancel:"إلغاء",
    confirm:"تأكيد",
    pleaseSelect:"يرجى تحديد العملاء المستهدفين لتحديد الفئات"
  }
};

const lang = getCookie("language");
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    ar: {
      translation: arTranslations,
    },
  },
  lng: lang ? lang : "ar", // Default language
  fallbackLng: "en", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
