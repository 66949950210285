import { BaseService } from "../../Api/base";
import { Error, Success } from "../../Responses/Responses";
import { updateMerchant } from "../Merchant/action";
import {
  ADD_MERCHANT_FAILURE,
  ADD_MERCHANT_REQUEST,
  ADD_MERCHANT_SUCCESS,
} from "./types";

export const addMerchant = (
  fields,
  nextStep = () => {},
  path,
  refetch,
  fetchServices
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_MERCHANT_REQUEST });
      const { data, status } = await BaseService.post(
        "/merchantAdmins/merchants",
        {
          merchant: { ...fields },
        }
      );
      if (status === 200) {
        if (refetch) await refetch();
        if (fetchServices) await fetchServices();
        if (path?.startsWith("/dashboard")) {
          Success("Updated SuccessFully");
        }
        dispatch(updateMerchant(fields));
        nextStep();
      }
      dispatch({ type: ADD_MERCHANT_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message);
      dispatch({ type: ADD_MERCHANT_FAILURE, payload: error.message });
    }
  };
};

export const updateEmployeeProfile = (
  fields,
  nextStep = () => {},
  path,
  refetch
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_MERCHANT_REQUEST });
      const { data, status } = await BaseService.patch("/employees/profile", {
        profile: fields,
      });
      if (status === 200) {
        refetch();
        if (path?.startsWith("/dashboard")) {
          Success("Updated SuccessFully");
        }
        nextStep();
      }
      dispatch({ type: ADD_MERCHANT_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message);
      dispatch({ type: ADD_MERCHANT_FAILURE, payload: error.message });
    }
  };
};

export const addBusinessHours = (fields, nextStep = () => {}, path, fetchTimeSlots) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_MERCHANT_REQUEST });
      const { data, status } = await BaseService.post(
        "/employees/timeSlots",
        fields
      );
      if (status === 200) {
        fetchTimeSlots()
        if (path?.startsWith("/dashboard")) {
          Success("Updated SuccessFully");
        }
        dispatch(updateMerchant(fields));
        nextStep();
      }
      dispatch({ type: ADD_MERCHANT_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message);
      dispatch({ type: ADD_MERCHANT_FAILURE, payload: error.message });
    }
  };
};
