import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import Button from "../../Elements/Button/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { signUpSchema } from "../../Schemas";
import { ReactComponent as LogoAr } from "./arLogo.svg";
import { ReactComponent as SignUpImage } from "./signUpImage.svg";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { app } from "../../config";
import { Error } from "../../Responses/Responses";
import { getCookie, setCookie } from "../../Utils/storageVariable";
import { Header } from "../../Components/Header/Header";
import axios from "axios";

const Signup = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n?.language);
  const [loading, setLoading] = useState(false);

  const handleLanguageChange = (language) => {
    setCookie("language", language);
    i18n.changeLanguage(language);
    setLang(language);
  };

  const initialValues = {
    phoneNumber: "",
  };

  const auth = getAuth(app);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptca-container",
      {
        size: "normal",
        callback: (response) => {},
        "expired-callback": (response) => {},
      }
    );
  }, [auth]);

  const handleSendOtp = async (values) => {
    try {
      setLoading(true);
      const formattedPhoneNumber = `${values.phoneNumber}`;
      setCookie("phoneNumber", formattedPhoneNumber);
      axios
        .post(process.env.REACT_APP_BASE_URL + `/employeeAuth/sendOtp`, {
          phoneNumber: formattedPhoneNumber,
          scope: "ALL",
        })
        .then((res) => {
          setLoading(false);
          navigate("/verify");
        })
        .catch((error) => {
          setLoading(false);
          Error(error?.message);
          if (
            error.code === "auth/code-expired" ||
            error.code === "auth/timeout"
          ) {
          } else {
          }
        });
    } catch (error) {
      setLoading(false);
      Error(error?.message);
    }
  };
  const { errors, values, handleChange, handleBlur, handleSubmit, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        handleSendOtp(values);
      },
    });

  return (
    <>
      <Container isLanguag={lang}>
        <Row>
          <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
            <HeaderWrapper>
              {" "}
              <Header isTranparent={true} lang={lang} setLang={setLang} />
            </HeaderWrapper>

            <LeftSection isLanguag={lang}>
              <Section isMobile>
                <SignUpImage />
                <LeftHeading isLanguage={lang}>
                  {t("signupContent.leftSection.heading")}
                </LeftHeading>
                <Disc isLanguage={lang}>
                  {t("signupContent.leftSection.description")}
                </Disc>
              </Section>
            </LeftSection>
          </Col>
          <Col xxl={12} xl={12} lg={12} sm={24} xs={24}>
            <RightSecton isLanguag={lang}>
              <HeaderDesktop isLanguag={lang}>
                <BtnWrapper isLanguag={lang}>
                  <LangWrapper>
                    <ButtonOne
                      isLanguage={lang}
                      onClick={() => handleLanguageChange("en")}
                    >
                      EN
                    </ButtonOne>
                    <ButtonTwo
                      isLanguage={lang}
                      onClick={() => handleLanguageChange("ar")}
                    >
                      AR
                    </ButtonTwo>
                  </LangWrapper>
                  <HeaderText isLanguage={lang}>
                    {t("signupContent.rightSection.englishText")}
                  </HeaderText>
                </BtnWrapper>
              </HeaderDesktop>
              <FormWrapper isLanguag={lang}>
                <Form onSubmit={handleSubmit}>
                  <FormInput>
                    <Heading onClick={() => navigate("/")}>
                      {lang === "ar" && <LogoAr />}
                      {lang === "en" && (
                        <Image src="./Assets/logo.svg" alt="Logo" />
                      )}{" "}
                      {lang === "en" && "beautisry"}
                    </Heading>

                    <InputsWrapper>
                      <Label isLanguage={lang}>
                        {t("signupContent.rightSection.label")}
                      </Label>
                      <Input
                        isLanguage={lang}
                        label={t("signupContent.rightSection.label")}
                        value={values?.phoneNumber}
                        onBlur={handleBlur}
                        name="phoneNumber"
                        onChange={(value) =>
                          handleChange({
                            target: { name: "phoneNumber", value },
                          })
                        }
                        defaultCountry="SA"
                      />
                      {errors.phoneNumber || touched.phoneNumber ? (
                        <Span>{errors.phoneNumber}</Span>
                      ) : null}
                    </InputsWrapper>

                    <InputsWrapper>
                      <Button
                        primary
                        isLanguage={lang}
                        onClick={handleSubmit}
                        disabled={loading}
                        loading={loading}
                      >
                        {t("signupContent.rightSection.buttonText")}
                      </Button>
                    </InputsWrapper>

                    <RecapWrap>
                      <div id="recaptca-container"></div>
                    </RecapWrap>
                  </FormInput>
                </Form>
              </FormWrapper>
            </RightSecton>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Signup;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  direction: ${({ isLanguag }) => isLanguag === "ar" && "rtl"};

  @media (max-width: 991px) {
    height: 100%;
    &::-webkit-scrollbar-track {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
`;
const HeaderWrapper = styled.div`
  display: none;

  @media (max-width: 991px) {
    display: block;
    position: fixed;
    width: 100%;
  }
`;
const LeftSection = styled.div`
  background-color: #feefef;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: ${({ isLanguag }) =>
    isLanguag === "ar" ? "center" : "flex-end"};
  align-items: center;

  @media (max-width: 991px) {
    background-color: transparent;
    height: 100%;
    margin-top: 4rem;
  }
`;
const Section = styled.div`
  height: auto;
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  margin-right: 2rem;
  @media (max-width: 1180px) {
    width: 70%;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    gap: 1rem;
    svg {
      width: ${({ isMobile }) => isMobile && "300px"};
    }
  }
`;
const LeftHeading = styled.h2`
  font-size: 24px;
  line-height: ${({ isLanguage }) => (isLanguage === "ar" ? "45px" : "34px")};
  font-weight: 700;
  text-align: center;
  color: #060a32;
  padding: 0 1rem;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  margin-top: 2rem;
  @media (max-width: 991px) {
    margin: 0;
  }
  @media (max-width: 600px) {
    padding: 0;
    width: 95%;
  }
`;
const Disc = styled.p`
  font-size: 18px;
  line-height: ${({ isLanguage }) => (isLanguage === "ar" ? "36px" : "26px")};
  font-weight: 500;
  text-align: center;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  color: #3b4c66;
  margin-top: 1rem;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    gap: 1rem;
  }
  @media (max-width: 600px) {
    padding: 0;
    width: 95%;
  }
`;

const Image = styled.img`
  height: ${({ isHeight }) => isHeight && "60px"};

  @media (max-width: 991px) {
    width: ${({ isMobile }) => isMobile && "230px"};
  }
`;

const RightSecton = styled.div`
  width: 100%;
  height: 100vh;

  @media (max-width: 991px) {
    height: 100%;
  }
`;
const HeaderDesktop = styled.div`
  height: 4rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

const BtnWrapper = styled.div`
  width: ${({ isLanguag }) => (isLanguag === "ar" ? "80%" : "50%")};
  height: auto;
  display: flex;
  justify-content: ${({ isLanguag }) =>
    isLanguag === "ar" ? "flex-start" : "flex-end"};
  align-items: flex-end;
  gap: 0.8rem;
  align-items: center;
`;
const LangWrapper = styled.div`
  height: 23px;
  width: 72px;
  display: flex;
  border-radius: 900px;
  border: 1px solid #f55a65;
  overflow: hidden;
`;

const HeaderText = styled.p`
  margin: 0;
  padding: 0;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  font-weight: 500;
  color: #f55a65;

  line-height: 20.16px;
  margin-bottom: 0.2rem;
`;
const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding-top: ${({ isLanguag }) => (isLanguag === "ar" ? "13rem" : "4rem")};

  padding-right: ${({ isLanguag }) => isLanguag === "ar" && "4rem"};

  @media (max-width: 991px) {
    padding: 0;
    padding-top: ${({ isLanguag }) => (isLanguag === "ar" ? "3rem" : "3rem")};
  }
`;
const Form = styled.div`
  width: 100%;
`;
const Heading = styled.h2`
  cursor: pointer;
  font-size: 32px;
  font-weight: 700;
  display: flex;
  letter-spacing: 1px;
  color: #060a32;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3rem;

  @media (max-width: 991px) {
    display: none;
  }
`;
const FormInput = styled.div`
  width: 80%;
  margin-left: 2rem;

  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
    padding-top: 0;
  }
`;
const InputsWrapper = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

const ButtonOne = styled.button`
  background-color: ${({ isLanguage }) =>
    isLanguage === "en" ? "#f55a65" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "en" ? "#FFFFFF" : "#f55a65")};
  border: none;
  outline: none;
  cursor: pointer;
  width: 36px;
  font-size: 12px;
  font-weight: 500;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
`;

const ButtonTwo = styled.button`
  background-color: ${({ isLanguage }) =>
    isLanguage === "ar" ? "#f55a65" : "transparent"};
  color: ${({ isLanguage }) => (isLanguage === "ar" ? "#FFFFFF" : "#f55a65")};
  border: none;
  outline: none;
  cursor: pointer;
  width: 36px;
  font-size: 12px;
  font-weight: 500;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
`;
const Span = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;
const Input = styled(PhoneInput)`
  direction: ${({ isLanguage }) => isLanguage === "ar" && "ltr"};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 14px;
  outline: none;
  border: 1px solid ${(props) => (props.isBorder ? "red" : "#D0D5DD")};
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  border-radius: 8px;
  margin-top: 0.4rem;
  color: #344054;
  width: 100%;
  .PhoneInputInput {
    border: none;
    font-size: 16px;
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"};
    outline: none;
    font-weight: 400;
  }
`;
const RecapWrap = styled.div`
  margin-top: 1rem;
`;
const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #344054;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  display: block;
`;
