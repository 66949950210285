import React, { useEffect, useState, useContext } from "react";
import { Checkbox, Col, Modal, Row } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "../../Elements/Button/Button";
import { useSelector } from "react-redux";
import { ReactComponent as NoData } from "./noData.svg";
import { FormDataContext } from "../../App";

const CategoryModal = ({
  isOpen,
  setIsOpen,
  targatedCostumer,
  selectedCategory,
  handleCheckboxChange,
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [filteredCategories, setFilteredCategories] = useState([]);
  const { data } = useContext(FormDataContext);

  useEffect(() => {
    if (targatedCostumer.length === 0 || !data.categories)
      return setFilteredCategories([]);
    setFilteredCategories(data.categories);
    // if (
    //   targatedCostumer.includes("men") &&
    //   targatedCostumer.includes("women")
    // )
    //   return setFilteredCategories(
    //     data.categories?.filter((cat) => cat?.type.length === 2)
    //   );
    // if (targatedCostumer.includes("men"))
    //   return setFilteredCategories(
    //     data.categories?.filter((cat) => cat?.type.includes("men"))
    //   );
    // if (targatedCostumer.includes("women"))
    //   return setFilteredCategories(
    //     data.categories?.filter((cat) => cat?.type.includes("women"))
    //   );
  }, [targatedCostumer, data.categories]);

  return (
    <ModalWrapper>
      <Modal
        className="container"
        footer={false}
        centered={"center"}
        open={isOpen}
        width={"max-content"}
        closeIcon={targatedCostumer?.length === 0 ? true : false}
        onCancel={() => setIsOpen(false)}
      >
        <Container isLanguage={i18n.language}>
          {targatedCostumer?.length === 0 ? (
            <NoDataContainer>
              <NoData />
              <SmallText isLanguage={i18n.language}>
                {t("categoryModal.pleaseSelect")}
              </SmallText>
            </NoDataContainer>
          ) : (
            <>
              <Heading isLanguage={i18n.language}>
                {t("categoryModal.heading")}
              </Heading>

              <Row gutter={16}>
                {filteredCategories?.map((category) => (
                  <Col key={category.nameInEnglish} span={12}>
                    <CheckWrapper>
                      <CheckBoxWrapper>
                        <Checkbox
                          name={"categoryId"}
                          checked={selectedCategory === category?.id}
                          onChange={(e) => handleCheckboxChange(category?.id)}
                        />
                      </CheckBoxWrapper>
                      <Content>
                        <Text>{language === 'en'? category.title: category.titleAr}</Text>
                        {/* <TextAr>{category.titleAr}</TextAr> */}
                      </Content>
                    </CheckWrapper>
                  </Col>
                ))}
              </Row>
            </>
          )}{" "}
          {targatedCostumer?.length !== 0 && (
            <ButtonWrapper isTop={targatedCostumer?.length === 0}>
              <WraperBtn isWidth={"30%"}>
                <Button
                  isLanguage={i18n.language}
                  onClick={() => setIsOpen(false)}
                >
                  {t("categoryModal.cancel")}
                </Button>
              </WraperBtn>

              <WraperBtn isWidth={"60%"}>
                <Button
                  isLanguage={i18n.language}
                  onClick={() => setIsOpen(false)}
                  primary
                >
                  {t("categoryModal.confirm")}
                </Button>
              </WraperBtn>
            </ButtonWrapper>
          )}
        </Container>
      </Modal>
    </ModalWrapper>
  );
};

export default CategoryModal;

const ModalWrapper = styled.div``;
const Container = styled.div`
  width: 356px;

  max-width: max-content;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};

  @media (max-width: 600px) {
    zoom: 90%;
  }
`;
const Heading = styled.h2`
  font-size: 32px;
  font-weight: 700;

  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const CheckWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  margin-top: 1rem;
`;
const CheckBoxWrapper = styled.div`
  .ant-checkbox-inner:after {
    border-color: #f55a65 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #f55a65 !important;
    background-color: transparent !important;
  }
`;
const Content = styled.div``;
const Text = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 140px;
`;
const TextAr = styled.p`
  margin: 0;
  padding: 0;
  font-family: "Noto Sans Arabic", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: end;
  width: 140px;
`;
const ButtonWrapper = styled.div`
  width: ${({ isWidth }) => (isWidth ? "40%" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 100%;
  padding-top: ${({ isTop }) => (isTop ? "" : "2rem")};
`;

const WraperBtn = styled.div`
  width: ${({ isWidth }) => isWidth && isWidth};
`;
const NoDataContainer = styled.div`
  width: 350px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const SmallText = styled.h2`
  text-align: center;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  width: 80%;
  font-weight: 400;
  color: #d9d9d9;
`;
