import { combineReducers } from "redux";
import GetMerchantReducer from "./Merchant/reducer";
import ProductReducer from "./Product/reducer";
import AddMerchantReducer from "./Add-Merchant/reducer";
import bankDetailReducer from "./Bank-Detail/reducer";
import addBankDetailReducer from "./Add-Bank/reducer";
import EmployeeListReducer from "./Employee-List/reducer";
import addEmployeeReducer from "./Add-Employee/reducer";
import addProductsSReducer from "./Add-Products/reducer";
import deleteProductReducer from "./Delete-Product/reducer";
import deleteMemberReducer from "./Delete-Member/reducer";
import addProductSReducer from "./Add-Product/reducer";
import categoryReducer from "./Categories/reducer";
export default combineReducers({
    GetMerchantReducer,
    ProductReducer,
    AddMerchantReducer,
    bankDetailReducer,
    addBankDetailReducer,
    EmployeeListReducer,
    addEmployeeReducer,
    addProductsSReducer,
    deleteProductReducer,
    deleteMemberReducer,
    addProductSReducer,
    categoryReducer,
});
