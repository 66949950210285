import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
const CustomerGrowthChart = ({ t }) => {
  const { i18n } = useTranslation();
  const handleChange = (value) => {};

  const [series, setSeries] = useState([
    {
      data: [80, 60, 40, 81, 29, 72, 91, 42, 67, 12, 40, 60],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "area",
      height: 160,

      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val} %`;
      },
      offsetY: -8,

      style: {
        fontSize: "10px",
        colors: ["#808396"],
      },
    },
    xaxis: {
      categories: [
        `${t("revenueChart.jan")}`,
        `${t("revenueChart.feb")}`,
        `${t("revenueChart.mar")}`,
        `${t("revenueChart.apr")}`,
        `${t("revenueChart.may")}`,
        `${t("revenueChart.june")}`,
        `${t("revenueChart.july")}`,
        `${t("revenueChart.aug")}`,
        `${t("revenueChart.sep")}`,
        `${t("revenueChart.oct")}`,
        `${t("revenueChart.nov")}`,
        `${t("revenueChart.dec")}`,
      ],
    },
    markers: {
      size: 4,
      strokeColors: "#F55A65",
      shape: "circle",
      offsetX: 0,
      offsetY: 0,
    },
    stroke: {
      width: 1,
    },
    yaxis: {
      min: 0,
      max: 160,
      tickAmount: 4,
      labels: {
        formatter: (val) => `${val}%`,
      },
    },

    grid: {
      show: true,
      borderColor: "#D0D0DA",
      strokeDashArray: 6,
    },

    colors: ["#F55A65"],
  });
  useEffect(() => {
    const translatedLabels = [
      `${t("revenueChart.jan")}`,
      `${t("revenueChart.feb")}`,
      `${t("revenueChart.mar")}`,
      `${t("revenueChart.apr")}`,
      `${t("revenueChart.may")}`,
      `${t("revenueChart.june")}`,
      `${t("revenueChart.july")}`,
      `${t("revenueChart.aug")}`,
      `${t("revenueChart.sep")}`,
      `${t("revenueChart.oct")}`,
      `${t("revenueChart.nov")}`,
      `${t("revenueChart.dec")}`,
    ];

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: translatedLabels,
      },
    }));
  }, [i18n.language]);
  return (
    <Container isLanguage={i18n.language}>
      <Header isLanguage={i18n.language}>
        <Title isLanguage={i18n.language}>
          {t("revenueChart.growthHeading")}
        </Title>
        <Select
          placeholder={`${t("revenueChart.placeholder")}`}
          popupClassName="chart_select"
          onChange={handleChange}
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "Yiminghe", label: "yiminghe" },
          ]}
        />
      </Header>
      <div id="chart">
        <Chart options={options} series={series} type="area" height={260} />
      </div>
    </Container>
  );
};

export default CustomerGrowthChart;

const Container = styled.div`
  width: 100%;
  background-color: #ffff;
  border-radius: 12px;
  padding: 0rem 0.5rem;
  direction: ltr;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
  @media (max-width: 600px) {
    padding: 0;
  }
  .apexcharts-legend {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
  .ant-select-selection-placeholder {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
    font-weight: 500;
    font-size: 14px;
    color: #222222;
  }
  .apexcharts-text tspan {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid #eee9e4;
  padding: 0.2rem 1rem;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h3`
  padding: 1rem 0;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  font-weight: ${({ isLanguage }) => (isLanguage === "ar" ? 300 : 400)};

  font-size: 16px;
  color: #222222;
`;
