import { Col, Row } from "antd";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { fetchEmployeeList } from "../../../Redux/Employee-List/action";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../Components/Loader/Loading";
import Button from "../../../Elements/Button/Button";
import EditModal from "../../../Components/Modal/Modal";
import { deleteMember } from "../../../Redux/Delete-Member/action";
import { useTranslation } from "react-i18next";
import { FormDataContext } from "../../../App";

const TeamDetail = ({ fetchEmployees }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { data } = useContext(FormDataContext);

  useEffect(() => {
    if (!data.employees) fetchEmployees();
  }, []);

  // const data = useSelector((state) => state?.EmployeeListReducer?.data);
  const loading = useSelector((state) => state?.EmployeeListReducer?.loading);
  const addLoading = useSelector((state) => state?.addEmployeeReducer?.loading);

  const handleEdit = (item) => {
    if (!item) return;
    setEditData(item);
    setIsOpen(true);
  };

  const handleDelete = (item) => {
    if (!item) return;

    dispatch(deleteMember(item?.id, fetchEmployees));
  };
  const handleAddNewMember = () => {
    setIsOpen(true);
    setEditData(null);
  };

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Table>
          <TableHeader>
            <Row>
              <Col span={6}>
                <HeaderLabel isLanguage={i18n.language}>
                  {t("teamDetailPage.firstName")}
                </HeaderLabel>
              </Col>
              {/* <Col span={6}>
                <HeaderLabel isLanguage={i18n.language}>
                  {t("teamDetailPage.lastName")}
                </HeaderLabel>
              </Col> */}
              <Col span={6}>
                <HeaderLabel isLanguage={i18n.language}>
                  {t("teamDetailPage.phoneNumber")}
                </HeaderLabel>
              </Col>
              <Col span={6}>
                <HeaderLabel isLanguage={i18n.language}>
                  {t("teamDetailPage.action")}{" "}
                </HeaderLabel>
              </Col>
            </Row>
          </TableHeader>
          {data &&
            data.employees &&
            data?.employees.map((item, i) => (
              <TableContent>
                <Row>
                  <Col span={6}>
                    <HeaderLabel font isLanguage={i18n.language}>
                      {item?.name}
                    </HeaderLabel>
                  </Col>
                  <Col span={6}>
                    <HeaderLabel font isLanguage={i18n.language}>
                      {item?.phoneNumber}
                    </HeaderLabel>
                  </Col>

                  <Col span={6}>
                    <BtnWrapper>
                      <DeletBtn
                        onClick={() => handleDelete(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteIcon /> Delete
                      </DeletBtn>
                      <EditBtn
                        onClick={() => handleEdit(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <EditIcon /> Edit
                      </EditBtn>
                    </BtnWrapper>
                  </Col>
                </Row>
              </TableContent>
            ))}
        </Table>
        <ButtonWrapper isWidth isLanguage={i18n.language}>
          <Button
            disabled={loading}
            loading={addLoading}
            primary
            onClick={handleAddNewMember}
            isLanguage={i18n.language}
          >
            {t("teamDetailPage.btnText")}
          </Button>
        </ButtonWrapper>
        <EditModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          editData={editData}
          setEditData={setEditData}
          fetchEmployees={fetchEmployees}
        />
      </Container>
    </>
  );
};

export default TeamDetail;

const Container = styled.div`
  width: 100%;

  padding-top: 1.5rem;
  @media (max-width: 768px) {
    width: 767px;
    overflow: scroll;
    margin-left: 1em;
    margin-right: 1em;
  }
`;
const Table = styled.div`
  width: 70%;
  min-height: 82px;
  max-height: auto;
  background-color: #fff;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid #e9e9e9;

  @media (max-width: 991px) {
    width: 100%;
  }
`;
const TableHeader = styled.div`
  width: 100%;
  height: 40px;
  background-color: #feefef;
  padding: 0 1rem;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #e8dbda;
`;
const HeaderLabel = styled.p`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #222222;
  font-size: 14px;
  font-weight: ${({ font }) => (font ? "300" : "500")};
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
`;
const TableContent = styled.div`
  width: 100%;
  height: 45px;
  padding: 0 1rem;

  border: 1px solid #e9e9e9;
  border-bottom: none;
`;
const BtnWrapper = styled.div`
  gap: 1rem;
  height: 45px;
  min-width: 150px;
  max-width: ${({ isWidth }) => (isWidth ? "12%" : "100%")};
  display: flex;
  align-items: center;
  margin-top: ${({ isWidth }) => (isWidth ? "2rem" : "0")};
`;
const DeletBtn = styled.button`
  font-weight: 300;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  color: #fa1c1c;
`;
const EditBtn = styled.button`
  font-weight: 300;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  font-size: 14px;
  font-family: "Outfit", sans-serif;
  color: #05a13a;
`;
const ButtonWrapper = styled.div`
  width: 150px;
  margin-top: 1rem;
  position: fixed;

  @media (max-width: 768px) {
    left: ${({ isLanguage }) => isLanguage === "en" && "1rem"};
    right: ${({ isLanguage }) => isLanguage === "ar" && "1rem"};
  }
`;
const PhoneLabel = styled.p`
  color: #222222;
  font-size: 14px;
  font-weight: ${({ font }) => (font ? "300" : "500")};
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  direction: ltr;
  display: flex;
  justify-content: ${({ isLanguage }) =>
    isLanguage === "ar" ? "flex-end" : "flex-start"};
  align-items: center;
  height: 100%;
`;
