import React from 'react'
import styled from 'styled-components'
import Notfound from "./notfound.jpg"
import Button from '../../Elements/Button/Button'
import { useNavigate } from 'react-router-dom'
const NotFound = () => {
    const navigate = useNavigate()
  return (
    <Container>
        <Image src={Notfound} />
        <Heading>Page Not Found</Heading>
        <Wrapper>
        <Button primary onClick={()=> navigate(-1)}>Go Back</Button>
        </Wrapper>
    </Container>
  )
}

export default NotFound

const Container = styled.div`
width: 100%;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: #FFFFFF;
flex-direction: column;
`
const Heading = styled.h1`
    font-size: 'Outfit', sans-serif;
  transform: translateY(-20px);
  color: #d9d9d9;
`
const Image = styled.img`
width: 300px;
height: 300px;
`

const Wrapper = styled.div`
    width: 200px;
`