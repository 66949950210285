import { Amplify, Storage, Auth } from "aws-amplify";
import awsmobile from "../../aws-exports";
import Compressor from "compressorjs";

Amplify.configure(awsmobile);

Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.REACT_APP_AWS_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
});

Storage.configure({
  region: process.env.REACT_APP_AWS_REGION,
  bucket: process.env.REACT_APP_S3_BUCKET,
});

export const uploadFile = async (
  file,
  progressCallback,
  callBack,
  errorCallback,
  quality
) => {
  return new Promise((resolve) => {
    new Compressor(file, {
      quality: quality ?? 0.8,
      success(result) {
        try {
          Storage.put(Date.now() + "_" + result.name, result, {
            contentType: result.type,
            progressCallback: (progress) => {
              progressCallback(progress);
            },
          }).then((success) => {
            callBack(success.key);
          });
        } catch (error) {
          console.log("Error uploading file: ", error);
        }
        resolve(true);
      },
      error(err) {
        console.log(err.message);
        errorCallback(err);
      },
    });
  });
};
export const uploadFiles = async (files, fileType) => {
  return Promise.all(
    files.map((file) => {
      if (fileType === "IMAGE") {
        return new Promise((resolve) => {
          new Compressor(file, {
            quality: 0.6,
            convertSize: Infinity,
            success(result) {
              try {
                Storage.put(result.name, result, {
                  contentType: result.type,
                }).then((success) => {
                  resolve(success.key);
                });
              } catch (error) {
                console.log("Error uploading file: ", error);
              }
            },
            error(err) {
              console.log(err.message);
            },
          });
        });
      } else {
        return new Promise((resolve) => {
          const result = [];
          try {
            Storage.put(file.name, file, {
              contentType: file.type,
            }).then((success) => {
              result.push(success.key);
              resolve(success.key);
            });
          } catch (error) {
            console.log("Error uploading file: ", error);
          }
        });
      }
    })
  );
};
