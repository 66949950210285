import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Provider } from 'react-redux';
import store from "./Redux/store";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
      <Provider store={store}>
      <ToastContainer   />

        <App />

      </Provider>
  </I18nextProvider>
);


reportWebVitals();
