import { BaseService } from "../../Api/base";
import { Error } from "../../Responses/Responses";
import { fetchEmployeeList } from "../Employee-List/action";
import {
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
} from "./types";
export const deleteMember = (id, fetchEmployees) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_MEMBER_REQUEST });
      const { data, status } = await BaseService.remove(
        `/merchantAdmins/employees/${id}`,
        {}
      );
      if (status === 200) {
        if (fetchEmployees) fetchEmployees();
      }
      dispatch({ type: DELETE_MEMBER_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message);
      dispatch({ type: DELETE_MEMBER_FAILURE, payload: error.message });
    }
  };
};
