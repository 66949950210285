import { Checkbox, DatePicker } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Calender } from "./Calender.svg";
import Button from "../../../Elements/Button/Button";
import { useTranslation } from "react-i18next";
import { adsSchema } from "../../../Schemas";
import { useFormik } from "formik";

const Ads = () => {
  const { t, i18n } = useTranslation();
  const [isUpdate, setisUpdate] = useState(true);

  const initialValues = {
    dashboard: false,
    explore: false,
    ranking: false,
    date: "",
    startDate: "",
    endDate: "",
  };
  const { errors, setValues, values, handleChange, handleSubmit, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: adsSchema,
      onSubmit: (values, action) => {},
    });

  return (
    <Container isLanguage={i18n.language}>
      {isUpdate ? (
        <Main>
          <Form isLanguage={i18n.language} onSubmit={handleSubmit}>
            <Content>
              <Unorderlist>
                <Subcontent isLanguage={i18n.language}>
                  <Label isLanguage={i18n.language}>
                    {" "}
                    {t("ads.interestedlabel")}
                  </Label>
                  <Para isLanguage={i18n.language}>
                    {t("ads.interestedInfo")}
                  </Para>
                  <CheckboxContainer isLanguage={i18n.language}>
                    <CustomCheckbox name="dashboard" onChange={handleChange}>
                      <CheckText isLanguage={i18n.language}>
                        {t("ads.checkbox_1")}
                      </CheckText>
                    </CustomCheckbox>

                    <CustomCheckbox name="explore" onChange={handleChange}>
                      <CheckText isLanguage={i18n.language}>
                        {t("ads.checkbox_2")}
                      </CheckText>
                    </CustomCheckbox>
                    <CustomCheckbox name="ranking" onChange={handleChange}>
                      <CheckText isLanguage={i18n.language}>
                        {t("ads.checkbox_3")}
                      </CheckText>
                    </CustomCheckbox>
                  </CheckboxContainer>
                </Subcontent>

                <Subcontent isLanguage={i18n.language} gap={true}>
                  <Label isLanguage={i18n.language}>
                    {t("ads.durationlabel")}
                  </Label>
                  <Para isLanguage={i18n.language}>
                    {t("ads.durationInfo")}
                  </Para>
                  <Datecontainer>
                    <Heading isLanguage={i18n.language}>
                      {t("ads.startdDate")}
                    </Heading>
                    <CustomDatePicker
                      isLanguage={i18n.language}
                      name="startDate"
                      placeholder={t("ads.startdDateplaceholder")}
                      onChange={(date, dateString) =>
                        setValues((prevValues) => ({
                          ...prevValues,
                          startDate: date,
                        }))
                      }
                      format="MMMM DD, YYYY"
                      suffixIcon={<Calender />}
                    />
                    {errors.startDate && touched.startDate ? (
                      <Span>{errors.startDate}</Span>
                    ) : null}
                    <Heading ismargin={true} isLanguage={i18n.language}>
                      {" "}
                      {t("ads.endDate")}
                    </Heading>
                    <CustomDatePicker
                      isLanguage={i18n.language}
                      name="endDate"
                      placeholder={t("ads.endDateplaceholder")}
                      onChange={(date, dateString) =>
                        setValues((prevValues) => ({
                          ...prevValues,
                          endDate: date,
                        }))
                      }
                      format="MMMM DD, YYYY"
                      suffixIcon={<Calender />}
                    />
                    {errors.endDate && touched.endDate ? (
                      <Span>{errors.endDate}</Span>
                    ) : null}
                  </Datecontainer>
                </Subcontent>
              </Unorderlist>
              <Btncontainer>
                <Button isLanguage={i18n.language} type="submit" primary>
                  {t("ads.updateBtn")}
                </Button>
              </Btncontainer>
            </Content>
          </Form>
        </Main>
      ) : (
        <ActivateAdsContainer>
          <Activecontent>
            <Info isLanguage={i18n.language}>
              {t("ads.activeContent.activeInfo")}
            </Info>
            <Div>
              <AdsContentbtn>
                <Button
                  isLanguage={i18n.language}
                  onClick={() => setisUpdate(true)}
                  primary
                >
                  {t("ads.activeContent.btn")}
                </Button>
              </AdsContentbtn>
            </Div>
          </Activecontent>
        </ActivateAdsContainer>
      )}
    </Container>
  );
};

export default Ads;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
`;

const Form = styled.form``;

const Main = styled.div`
  min-width: 505px;
  height: 580px;
  background-color: #ffff;
  border: 1px solid #eaecf0;
  padding: 1.5rem 3.2rem;
  border-radius: 12px;
  overflow: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 820px) {
    padding: 1rem;
    margin-bottom: 4rem;
    height: 100%;
  }
  @media (max-width: 600px) {
    min-width: 90%;
    max-width: 90%;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 100%;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
`;

const Subcontent = styled.div`
  margin-bottom: ${({ gap }) => (gap ? "1.7rem" : "2rem")};

  @media (max-width: 600px) {
    padding-left: 1rem;
    padding-right: ${({ isLanguage }) => isLanguage === "ar" && "1rem"};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;

const Unorderlist = styled.ol`
  margin: 0;
  padding: 0;
`;

const Label = styled.li`
  font-size: 20px;

  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  color: #1d2939;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

const Para = styled.p`
  font-size: 14px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  color: #475467;
  font-weight: normal;
  padding-top: 3px;
`;

const Datecontainer = styled.div`
  margin-top: 1rem;
`;

const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 0.7rem 0.8rem;

  .ant-picker-input {
    input {
      font-family: ${({ isLanguage }) =>
        isLanguage === "ar"
          ? `"Noto Sans Arabic", sans-serif`
          : "'Outfit', sans-serif"};
    }
  }
`;

const Heading = styled.div`
  padding-bottom: 0.5rem;
  margin-top: ${({ ismargin }) => ismargin && "0.8rem"};
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;

const Btncontainer = styled.div`
  width: 187px;
`;

const CustomCheckbox = styled(Checkbox)`
  font-family: "Outfit", sans-serif;

  .ant-checkbox .ant-checkbox-inner {
    background-color: #ffffff;
    border: 1px solid #000000;
  }
  .ant-checkbox-inner:after {
    border-color: #000000 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #000000 !important;
    background-color: transparent !important;
  }
  .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: #000000;
  }
`;

const ActivateAdsContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Info = styled.p`
  font-size: 18px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  color: #222222;
  font-weight: 500;
  text-align: center;
`;

const AdsContentbtn = styled.div`
  width: 200px;
`;

const Activecontent = styled.div`
  margin-top: 2rem;
  width: 600px;
`;

const Div = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Span = styled.span`
  color: red;
`;
const CheckText = styled.p`
  font-size: 16px;
  color: #1d2939;
  font-weight: 300;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  line-height: 24px;
`;
