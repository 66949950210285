import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Button from "../../Elements/Button/Button";
import { Checkbox, Radio } from "antd";
import CustomInput from "../../Elements/Input/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { bankDetailSchema } from "../../Schemas";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loader/Loading";
import { useTranslation } from "react-i18next";
import { addMerchant } from "../../Redux/Add-Merchant/action";
import { FormDataContext } from "../../App";

const BankDetails = ({
  isUpdate = false,
  nextStep = () => {},
  prevStep = () => {},
  refetch,
}) => {
  const { t, i18n } = useTranslation();
  const [onlinePayChecked, setOnlinePayChecked] = useState(false);
  const [physicallyPayChecked, setPhysicallyPayChecked] = useState(false);
  const [isDashboard, setIsDashboard] = useState(false);
  const { data } = useContext(FormDataContext);
  const [onlinePayments, setOnlinePayments] = useState(
    data && data.merchant && data.merchant.acceptsOnlinePayment ? true : false
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !data.merchantAdmin ||
      (isUpdate && !data.merchant) ||
      (data &&
        data.merchantAdmin &&
        data.merchantAdmin.merchantId &&
        !data.merchant)
    )
      refetch();
  }, [isUpdate]);

  // const merchantData = useSelector((state) => state?.GetMerchantReducer?.data);
  const merchantLoading = useSelector(
    (state) => state?.GetMerchantReducer?.loading
  );
  // const data = useSelector((state) => state?.bankDetailReducer?.data);
  const loading = useSelector((state) => state?.bankDetailReducer?.loading);
  const addloading = useSelector(
    (state) => state?.addBankDetailReducer?.loading
  );
  const addMerchantLoading = useSelector(
    (state) => state?.AddMerchantReducer?.loading
  );
  const initialValues = {
    bankName: "",
    bankAccountNumber: "",
    bankAccountName: "",
    consent: true,
    acceptsOnlinePaymentOnly: data.merchant?.acceptsOnlinePaymentOnly,
  };
  const {
    errors,
    setValues,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: bankDetailSchema,
    onSubmit: (values, action) => {
      const path = location.pathname;

      dispatch(addMerchant({ ...values, acceptsOnlinePayment: onlinePayments }, nextStep, path, refetch));

      return !isUpdate && navigate("/success");
    },
  });

  useEffect(() => {
    setValues({
      bankName: data.merchant?.bankName,
      bankAccountNumber: data.merchant?.bankAccountNumber,
      bankAccountName: data?.merchant?.bankAccountName,
      consent: true,
      merchantId: data?.merchantAdmin?.merchantId,
      acceptsOnlinePaymentOnly: data.merchant?.acceptsOnlinePaymentOnly,
    });
  }, [data]);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/dashboard")) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [location.pathname]);

  const handleChangePaymentMethod = (e) => {
    const { name } = e.target;

    if (name === "acceptsOnlinePaymentOnly") {
      setOnlinePayChecked(true);
      setPhysicallyPayChecked(false);
      setValues({
        ...values,
        acceptsOnlinePaymentOnly: true,
      });
    } else if (name === "physicallyPay") {
      setPhysicallyPayChecked(true);
      setOnlinePayChecked(false);
      setValues({
        ...values,
        acceptsOnlinePaymentOnly: false,
      });
    }
  };
  useEffect(() => {
    if (!data || !data.merchant) return;
    if (data.merchant?.acceptsOnlinePaymentOnly === true) {
      setOnlinePayChecked(true);
      setPhysicallyPayChecked(false);
    } else {
      setOnlinePayChecked(false);
      setPhysicallyPayChecked(true);
    }
  }, [data.merchant]);

  return (
    <>
      {(merchantLoading && <Loading />) || (loading && <Loading />)}
      <Container isDashboard={isDashboard} isLanguage={i18n.language}>
        <Form isDashboard={isDashboard} onSubmit={handleSubmit}>
          <Heading isLanguage={i18n.language}>
            {t("bankingDetails.heading")}
          </Heading>
          <Text isLanguage={i18n.language}>
            {t("bankingDetails.description")}
          </Text>

          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("bankingDetails.acceptOnlinePaymentsLabel")}
            </Label>

            <RadioWrapper isLanguage={i18n.language}>
              <Radio.Group
                onChange={(e) => {
                  setOnlinePayments(e.target.value);
                }}
                value={onlinePayments}
              >
                <RadioButton value={true}>
                  {" "}
                  {t("bankingDetails.yesOption")}
                </RadioButton>
                <RadioButton value={false}>
                  {" "}
                  {t("bankingDetails.noOption")}
                </RadioButton>
              </Radio.Group>
            </RadioWrapper>
          </InputWrapper>
          {onlinePayments && (
            <>
              <InputWrapper>
                <Label isLanguage={i18n.language}>
                  {t("bankingDetails.chooseYourWay")}
                </Label>
                <RadioWrapper isMargin isLanguage={i18n.language}>
                  <InputWrapper isMargin isLanguage={i18n.language}>
                    <Checkbox
                      onChange={(e) => handleChangePaymentMethod(e)}
                      type="checkbox"
                      name="acceptsOnlinePaymentOnly"
                      checked={onlinePayChecked}
                    >
                      <CheckText isLanguage={i18n.language}>
                        {t("bankingDetails.onlinePayText")}
                      </CheckText>
                    </Checkbox>
                  </InputWrapper>
                  <InputWrapper isMargin isLanguage={i18n.language}>
                    <Checkbox
                      onChange={(e) => handleChangePaymentMethod(e)}
                      type="checkbox"
                      name="physicallyPay"
                      checked={physicallyPayChecked}
                    >
                      <CheckText isLanguage={i18n.language}>
                        {t("bankingDetails.phyPayText")}
                      </CheckText>
                    </Checkbox>
                  </InputWrapper>
                </RadioWrapper>
              </InputWrapper>
              <InputWrapper>
                <CustomInput
                  type="text"
                  placeholder={t("bankingDetails.bankNamePlaceholder")}
                  label={t("bankingDetails.bankNameLabel")}
                  name="bankName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bankName}
                />
                {errors.bankName && touched.bankName && (
                  <Span>{errors.bankName}</Span>
                )}
              </InputWrapper>
              <InputWrapper>
                <CustomInput
                  type="text"
                  placeholder={t("bankingDetails.accountNumberPlaceholder")}
                  label={t("bankingDetails.accountNumberLabel")}
                  name="bankAccountNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bankAccountNumber}
                />
                {errors.bankAccountNumber && touched.bankAccountNumber && (
                  <Span>{errors.bankAccountNumber}</Span>
                )}
              </InputWrapper>
              <InputWrapper>
                <CustomInput
                  type="text"
                  placeholder={t("bankingDetails.accountNamePlaceholder")}
                  label={t("bankingDetails.accountNameLabel")}
                  name="bankAccountName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bankAccountName}
                />
                {errors.bankAccountName && touched.bankAccountName && (
                  <Span>{errors.bankAccountName}</Span>
                )}
              </InputWrapper>
              <TextWrapper isLanguage={i18n.language}>
                <Checkbox
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.consent}
                  name="concent"
                ></Checkbox>
                {t("bankingDetails.consentText")}
              </TextWrapper>
            </>
          )}
          {isUpdate === false ? (
            <ButtonWrapper>
              <Button isLanguage={i18n.language} onClick={prevStep}>
                {t("bankingDetails.previousButtonText")}
              </Button>
              <Button
                loading={addloading || addMerchantLoading}
                disabled={addloading}
                isLanguage={i18n.language}
                primary
                // type={"submit"}
                onClick={() => handleSubmit(values)}
              >
                {t("bankingDetails.signUpButtonText")}
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper isWidth>
              <Button
                loading={addloading || addMerchantLoading}
                isLanguage={i18n.language}
                primary
                // type={"submit"}
                onClick={handleSubmit}
              >
                {t("Update")}
              </Button>
            </ButtonWrapper>
          )}
        </Form>
      </Container>
    </>
  );
};

export default BankDetails;

const Container = styled.div`
  width: 100%;

  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  @media (max-width: 600px) {
    min-width: 100%;
    padding: 0;
    max-height: max-content;
  }
`;

const Form = styled.div`
  width: 524px;
  min-height: 508px;
  max-width: 50%;
  background-color: #ffffff;
  max-height: auto;

  padding: 2rem;
  border: 1px solid hsla(220, 17%, 93%, 1);
  border-radius: 12px;
  margin-bottom: ${({ isDashboard }) => (isDashboard ? "" : "6rem")};

  @media (max-width: 820px) {
    padding: 1rem;
    max-height: max-content;
    margin-bottom: 4rem;
  }
  @media (max-width: 600px) {
    min-width: 90%;
    max-width: 90%;
    padding: 1rem;
    max-height: max-content;
    margin-bottom: ${({ isDashboard }) => (isDashboard ? "1rem" : "10rem")};
    margin-top: ${({ isDashboard }) => isDashboard && "3rem"};
  }
`;
const Heading = styled.h2`
  line-height: 26px;
  font-size: 20px;
  font-weight: Weight;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const InputWrapper = styled.div`
  margin-top: ${({ isMargin }) => (isMargin ? "0.4rem" : "1.5rem")};
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: hsla(216, 18%, 34%, 1);
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  line-height: 20px;
`;
const ButtonWrapper = styled.div`
  width: ${({ isWidth }) => (isWidth ? "40%" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
  gap: 15rem;
  @media (max-width: 820px) {
    gap: 13rem;
  }
  @media (max-width: 600px) {
    gap: 36%;
  }
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  display: block;
`;
const RadioWrapper = styled.div`
  margin-top: ${({ isMargin }) => (isMargin ? "0.8rem" : "1.5rem")};
  margin-bottom: 1.3rem;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "ltr"};
  .ant-checkbox-inner:after {
    border-color: #f55a65 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #f55a65 !important;
    background-color: transparent !important;
  }

  span {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
`;
const CheckText = styled.p`
  font-size: 16px;
  color: #1d2939;
  font-weight: 400;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  line-height: 24px;
`;
const TextWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  align-items: flex-start;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  .ant-checkbox-wrapper {
    margin-top: ${({ isLanguage }) =>
      isLanguage === "ar" && "0.6rem"}!important;
  }
  .ant-checkbox-inner:after {
    border-color: #f55a65 !important;
  }
  .ant-checkbox-checked > .ant-checkbox-inner {
    border-color: #f55a65 !important;
    background-color: transparent !important;
  }
`;
const RadioButton = styled(Radio)`
  .ant-radio-checked > .ant-radio-inner {
    border-color: #c44851 !important;
    background-color: #c44851 !important;
  }
`;

const Span = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;
