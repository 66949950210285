import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const AppointmentChart = ({ t }) => {
  const { i18n } = useTranslation();

  const [series, setSeries] = useState([
    44, 55, 41, 17, 15, 10, 12, 12, 12, 56, 45, 20,
  ]);
  const [labels, setLabels] = useState([
    t("appointmentChart.jan"),
    t("appointmentChart.feb"),
    t("appointmentChart.mar"),
    t("appointmentChart.apr"),
    t("appointmentChart.may"),
    t("appointmentChart.june"),
    t("appointmentChart.july"),
    t("appointmentChart.aug"),
    t("appointmentChart.sep"),
    t("appointmentChart.oct"),
    t("appointmentChart.nov"),
    t("appointmentChart.dec"),
  ]);
  const [options, setOptions] = useState({
    chart: {
      type: "donut",
      dropShadow: {
        enabled: false,
      },
    },
    responsive: [
      {
        breakpoint: 430,

        options: {
          chart: {
            width: "100%",
           
          },
          legend: {
            show: true, 
          },
        },
      },
    ],
    stroke: {
      width: 0,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            },
          },
        },
      },
    },
    labels: labels,
    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: [
        "#E70000",
        "#A00093",
        "#710B96",
        "#351D98",
        "#004CAC",
        "#00ACA0",
        "#00AD4C",
        "#78D000",
        "#FBFA00",
        "#FFC800",
        "#FF9100",
        "#FF5B00",
      ],
    },

    states: {
      hover: {
        filter: "none",
      },
    },
    legend: {
      markers: {
        fillColors: [
          "#E70000",
          "#A00093",
          "#710B96",
          "#351D98",
          "#004CAC",
          "#00ACA0",
          "#00AD4C",
          "#78D000",
          "#FBFA00",
          "#FFC800",
          "#FF9100",
          "#FF5B00",
        ],
      },
    },
  });
  useEffect(() => {
    const translatedLabels = [
      t("appointmentChart.jan"),
      t("appointmentChart.feb"),
      t("appointmentChart.mar"),
      t("appointmentChart.apr"),
      t("appointmentChart.may"),
      t("appointmentChart.june"),
      t("appointmentChart.july"),
      t("appointmentChart.aug"),
      t("appointmentChart.sep"),
      t("appointmentChart.oct"),
      t("appointmentChart.nov"),
      t("appointmentChart.dec"),
    ];

    setLabels(translatedLabels);
    setOptions((prevOptions) => ({
      ...prevOptions,
      labels: translatedLabels,
    }));
  }, [i18n.language]);
  return (
    <Container isLanguage={i18n.language}>
      <Header>
        <Title isLanguage={i18n.language}>
          {t("appointmentChart.heading")}
        </Title>
      </Header>
      <Wrapper>
        <div className="donut">
          <Chart options={options} series={series} type="donut" />
        </div>
      </Wrapper>
    </Container>
  );
};

export default AppointmentChart;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffff;
  border-radius: 12px;
  direction: ltr;
  .donut:nth-child(1){
  
  }
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));

  .apexcharts-canvas {
  }
  .apexcharts-legend {
    display: grid;
    grid-template-columns: 1fr 1fr;
    top: 18px !important;
    bottom: 18px !important;
    padding: 0 !important;
    height: 220px !important;
  }
  @media (max-width:1220px) {
    .donut:nth-child(1){
    /* background-color: red; */
    height: 300px;
  }
  }

  .apexcharts-series .apexcharts-pie-series {
    color: red;
  }
  .apexcharts-legend-text {
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
  .apexcharts-legend-series {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  .apexcharts-legend.apx-legend-position-right {
    overflow: hidden;
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;

    
  }
  .apexcharts-canvas {
    height: 100% !important;

    svg {
      foreignObject {
        height: 230px !important;
      }
      @media (max-width:1600px) {
        height: 300px !important;
      }
      @media (max-width:1443px) {
        height: 230px !important;
      }
     
      @media (max-width: 1200px) {
        height:100% !important;
      
      }
      @media (max-width: 600px) {
        height:230px !important;
      
      }

      .apexcharts-graphical {
        @media (max-width: 1600px) {
          direction: ltr;
           scale:1;
           transform: ${({ isLanguage }) =>
          isLanguage === "ar"
            ? "translate(20px, 10px)"
            : "translate(20px, 20px)"} !important;
        }
        transform: ${({ isLanguage }) =>
          isLanguage === "ar"
            ? "translate(20px, 10px)"
            : "translate(20px, 40px)"} !important;
  
        @media (max-width: 1220px) {
          direction: ltr;
           scale:0.45;
        }
        @media (max-width: 992px) {
           scale:0.37;
        }
        @media (max-width: 768px) {
           scale:0.5;
        }
        @media (max-width: 600px) {
          scale:0.67;
        }
        @media (max-width: 450px) {
          scale:1;
        }
      }
    }
  }
`;

const Header = styled.div`
  padding: 0.2rem 1rem;
`;

const Title = styled.h3`
  border-bottom: 1px solid #eee9e4;
  padding: 1rem 0;
  font-family: ${({ isLanguage }) =>
    isLanguage == "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  font-weight: ${({ isLanguage }) => (isLanguage === "ar" ? 300 : 400)};

  font-size: 16px;
  color: #222222;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "rtl"};
`;
const Wrapper = styled.div``;
