import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CustomInput from "../../Elements/Input/Input";
import Button from "../../Elements/Button/Button";
import { Radio, Upload } from "antd";
import GoogleMapReact from "google-map-react";
import { useFormik } from "formik";
import { MerchandDetailSchema } from "../../Schemas";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { addMerchant } from "../../Redux/Add-Merchant/action";
import { storage } from "../../config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Loading from "../../Components/Loader/Loading";
import { Error } from "../../Responses/Responses";
import { useTranslation } from "react-i18next";
import { extractFilenameFromURL } from "../../Utils/constants";
import { ReactComponent as UploadIcon } from "./upload.svg";
import { ReactComponent as DocumentUploadIcon } from "./document-upload.svg";
import { useLocation } from "react-router-dom";
import { isObjectChanged } from "../../Utils/common";
import { FormDataContext } from "../../App";
import { uploadImages } from "../../controllers/ImageController";

const GOOGLE_API_KEY = "AIzaSyDSB0lFKYDTKvy23J8iOZChrptpWSHN4ao";

const MerchantDetails = ({
  isUpdate = false,
  nextStep = () => {},
  prevStep = () => {},
  refetch,
}) => {
  const { t, i18n } = useTranslation();
  const { data } = useContext(FormDataContext);

  useEffect(() => {
    if (
      !data.merchantAdmin ||
      (isUpdate && !data.merchant) ||
      (data &&
        data.merchantAdmin &&
        data.merchantAdmin.merchantId &&
        !data.merchant)
    )
      refetch();
  }, [isUpdate]);

  const AnyReactComponent = ({ text }) => {
    return (
      <div>
        <img
          src="http://icons.iconarchive.com/icons/paomedia/small-n-flat/256/map-marker-icon.png"
          style={{ height: "50px", width: "50px" }}
          alt="marker"
        />
      </div>
    );
  };

  const dispatch = useDispatch();
  //const data = useSelector((state) => state?.GetMerchantReducer?.data);
  const loading = useSelector((state) => state?.GetMerchantReducer?.loading);
  const addLoading = useSelector((state) => state?.AddMerchantReducer?.loading);

  const [state, setState] = useState();
  const [uploadFileLoading, setUploadFileLoading] = useState(false);

  const initialValues = {
    type: null,
    logo: null,
    businessName: "",
    businessNameAr: "",
    registrationDocument: null,
    nationalAddress: "",
    latitude: "",
    longitude: "",
  };
  useEffect(() => {
    if (data === null || !data.merchant) return;
    setValues({
      type: data.merchant?.type,
      logo: data.merchant?.logo,
      businessName: data.merchant?.businessName,
      businessNameAr: data.merchant?.businessNameAr,
      registrationDocument: data.merchant?.registrationDocument,
      nationalAddress: data.merchant?.nationalAddress,
      latitude: data.merchant?.latitude,
      longitude: data.merchant?.longitude,
    });
  }, [data]);

  const {
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: MerchandDetailSchema,
    onSubmit: (values) => {
      const path = location.pathname;
      if (
        !isObjectChanged(values, {
          type: data.merchant?.type,
          logo: data.merchant?.logo,
          businessName: data.merchant?.businessName,
          businessNameAr: data.merchant?.businessNameAr,
          registrationDocument: data.merchant?.registrationDocument,
          nationalAddress: data.merchant?.nationalAddress,
          latitude: data.merchant?.latitude,
          longitude: data.merchant?.longitude,
        })
      )
        return nextStep();
      dispatch(
        addMerchant(
          { ...values, acceptsOnlinementOnly: false },
          nextStep,
          path,
          refetch
        )
      );
    },
  });

  const handleUpload = async (file, name, type) => {
    if (!file) {
      return;
    }

    setUploadFileLoading(true);

    try {
      const response = await uploadImages(file, type);
      setUploadFileLoading(false);
      setValues({
        ...values,
        [name]: response.records[0],
      });
    } catch (e) {
      Error("Error uploading file");
      setUploadFileLoading(false);
    }
  };

  const handleChangeRigestration = (fileList, name, type) => {
    handleUpload([fileList[0].originFileObj], name, type);
  };

  const getLatLong = async (address) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${GOOGLE_API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === "OK" && data.results.length > 0) {
      } else {
        throw new Error("Unable to find coordinates for the given address");
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
      return null;
    }
  };

  const onFocusHandler = (event) => {
    setState({ ...state, [event.target.name]: true });
  };
  const onBlurHandler = (event) => {
    setState({ ...state, [event.target.name]: false });
  };

  const handleChangeFrom = (place) => {
    setValues((prevValues) => ({
      ...prevValues,
      nationalAddress: place?.formatted_address,
      ...(place && place.name
        ? {}
        : {
            longitude: place.geometry.location.lng(),
            latitude: place.geometry.location.lat(),
          }),
    }));

    getLatLong(place.formatted_address);
  };

  const handleRemoveImage = (file, name) => {
    if (!file || !file?.name) {
      console.error("Invalid file object or file name.");
      return;
    }

    setValues((prevValues) => ({
      ...prevValues,
      [name]: null,
    }));
  };
  const [logoUrl, setIlogoUrl] = useState([]);
  useEffect(() => {
    if (values?.logo) {
      const newFileList = [{ name: extractFilenameFromURL(values?.logo.url) }];
      setIlogoUrl(newFileList);
    }
  }, [values?.logo]);
  const [documentUrl, setDocumentUrl] = useState([]);
  useEffect(() => {
    if (values?.registrationDocument) {
      const newFileList = [
        { name: extractFilenameFromURL(values?.registrationDocument.url) },
      ];
      setDocumentUrl(newFileList);
    }
  }, [values?.registrationDocument]);

  const [isDashboard, setIsDashboard] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/dashboard")) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [location.pathname]);

  const defaultProps = {
    center: {
      lat: values?.latitude ? values?.latitude : 21.5292,
      lng: values?.longitude ? values?.longitude : 39.1611,
    },
    zoom: 11,
  };

  return (
    <>
      {loading || (uploadFileLoading && <Loading />)}
      <Container isLanguage={i18n.language}>
        <Form isDashboard={isDashboard} onSubmit={handleSubmit}>
          <Heading isLanguage={i18n.language}>
            {t("merchantDetail.heading")}
          </Heading>
          <Text isLanguage={i18n.language}>
            {t("merchantDetail.description")}
          </Text>
          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("merchantDetail.merchantTypeLabel")}
            </Label>
            <RadioWrapper isLanguage={i18n.language}>
              <Radio.Group
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.type}
                id="type"
                name="type"
              >
                <RadioButton value={"freelancer"}>
                  {t("merchantDetail.freelanceOption")}
                </RadioButton>
                <RadioButton value={"shop"}>
                  {t("merchantDetail.shopOption")}
                </RadioButton>
              </Radio.Group>
              {errors.type && touched.type && <Span>{errors.type}</Span>}
            </RadioWrapper>
          </InputWrapper>
          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("merchantDetail.businessLogoLabel")}
            </Label>
            <Upload
              fileList={logoUrl}
              onChange={(value) =>
                handleChangeRigestration(value.fileList, "logo", "IMAGE")
              }
              beforeUpload={() => false}
              accept=".png,.jpeg"
              style={{ width: "100%" }}
              name="logo"
              maxCount={1}
              onRemove={(value) => handleRemoveImage(value, "logo")}
            >
              <BtnWrapper>
                <UploadIcon />
                <Text isLanguage={i18n.language}>
                  {t("merchantDetail.selectLogoText")}
                </Text>
              </BtnWrapper>
            </Upload>
            {errors.logo && touched.logo && <Span>{errors.logo}</Span>}
          </InputWrapper>
          <InputWrapper>
            <CustomInput
              type="text"
              placeholder={t("merchantDetail.businessNamePlaceholder")}
              label={t("merchantDetail.businessNameLabel")}
              name="businessName"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.businessName}
            />
            {errors.businessName && touched.businessName && (
              <Span>{errors.businessName}</Span>
            )}
          </InputWrapper>
          <InputWrapper>
            <CustomInput
              isDirection
              type="text"
              placeholder={t("merchantDetail.arabicBusinessNamePlaceholder")}
              label={t("merchantDetail.arabicBusinessNameLabel")}
              name="businessNameAr"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.businessNameAr}
            />
            {errors.businessNameAr && touched.businessNameAr && (
              <Span>{errors.businessNameAr}</Span>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("merchantDetail.businessRegistrationDocLabel")}
            </Label>
            <Upload
              onChange={(value) =>
                handleChangeRigestration(
                  value?.fileList,
                  "registrationDocument",
                  "PDF"
                )
              }
              name="registrationDocument"
              fileList={documentUrl}
              accept=".pdf"
              beforeUpload={() => false}
              style={{ width: "100%" }}
              maxCount={1}
            >
              <UploadFileWrapper>
                <DocumentUploadIcon />
                <Text isLanguage={i18n.language}>
                  {t("merchantDetail.uploadFileLabel")}
                </Text>
              </UploadFileWrapper>
            </Upload>
            {errors.registrationDocument && touched.registrationDocument && (
              <Span>{errors.registrationDocument}</Span>
            )}
          </InputWrapper>
          <InputWrapper>
            <Autocomplete
              style={{
                width: "100%",
                height: "55px",
                border: "1px solid #D0D5DD",
                padding: "1rem",
                fontSize: "16px",
                borderRadius: "8px",
                fontWeight: 400,
                fontFamily:
                  i18n.language === "ar"
                    ? `"Noto Sans Arabic", sans-serif`
                    : "'Outfit', sans-serif",
                color: "#344054",
              }}
              onPlaceSelected={(place) => handleChangeFrom(place)}
              types={["address"]}
              language={i18n.language}
              apiKey={GOOGLE_API_KEY}
              placeholder={t("merchantDetail.autoPlaceHolder")}
              onBlur={onBlurHandler}
              onFocus={onFocusHandler}
              componentRestrictions={{ country: "SA" }}
              name="nationalAddress"
              id="nationalAddress"
              defaultValue={values.nationalAddress}
            />
          </InputWrapper>
          <InputWrapper>
            <Label isLanguage={i18n.language}>
              {t("merchantDetail.chooseLocationLabel")}
            </Label>
            <MapWrapper>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: GOOGLE_API_KEY,
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                center={{
                  lat: values.latitude || defaultProps.center.lat,
                  lng: values.longitude || defaultProps.center.lng,
                }}
                yesIWantToUseGoogleMapApiInternals
              >
                <AnyReactComponent
                  lat={values.latitude || defaultProps.center.lat}
                  lng={values.longitude || defaultProps.center.lng}
                  text={values?.nationalAddress}
                />
              </GoogleMapReact>
            </MapWrapper>
            <Wrapper>
              <CustomInput
                type="text"
                placeholder={t("merchantDetail.nationalAddressPlaceholder")}
                label={t("merchantDetail.latitudePlaceholder")}
                name="latitude"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.latitude}
                disabled={true}
              />
              <CustomInput
                type="text"
                placeholder={t("merchantDetail.nationalAddressPlaceholder")}
                label={t("merchantDetail.longitudePlaceholder")}
                name="longitude"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.longitude}
                disabled={true}
              />
            </Wrapper>
          </InputWrapper>
          {isUpdate === false ? (
            <ButtonWrapper>
              <Button isLanguage={i18n.language} onClick={prevStep}>
                {t("merchantDetail.prevButtonText")}
              </Button>
              <Button
                loading={addLoading}
                disabled={addLoading}
                isLanguage={i18n.language}
                primary
                type="submit"
                onClick={handleSubmit}
              >
                {t("merchantDetail.continueButtonText")}
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper isWidth>
              <Button
                disabled={
                  addLoading ||
                  !isObjectChanged(values, {
                    type: data?.type,
                    logo: data?.logo,
                    businessName: data?.businessName,
                    businessNameAr: data?.businessNameAr,
                    registrationDocument: data?.registrationDocument,
                    nationalAddress: data?.nationalAddress,
                    latitude: data?.latitude,
                    longitude: data?.longitude,
                  })
                }
                loading={addLoading}
                isLanguage={i18n.language}
                primary
                onClick={handleSubmit}
              >
                {t("Update")}
              </Button>
            </ButtonWrapper>
          )}
        </Form>
      </Container>
    </>
  );
};

export default MerchantDetails;
const Container = styled.div`
  width: 100%;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: ${({ isLanguage }) => isLanguage === "ar" && "right"};
  @media (max-width: 600px) {
    min-width: 100%;
    padding: 0;
    max-height: max-content;
  }
  .ant-upload-select {
    width: 100% !important;
  }
`;
const Form = styled.div`
  min-width: 524px;
  min-height: 508px;
  max-width: 50%;
  background-color: #ffffff;
  max-height: auto;

  padding: 2rem;
  border: 1px solid hsla(220, 17%, 93%, 1);
  border-radius: 12px;
  margin-bottom: ${({ isDashboard }) => (isDashboard ? "" : "6rem")};
  @media (max-width: 820px) {
    padding: 1rem;
    max-height: max-content;
    margin-bottom: 4rem;
  }
  @media (max-width: 600px) {
    min-width: 90%;
    max-width: 90%;
    padding: 1rem;
    max-height: max-content;
    margin-bottom: ${({ isDashboard }) => (isDashboard ? "1rem" : "10rem")};
    margin-top: ${({ isDashboard }) => isDashboard && "3rem"};
  }
`;
const Heading = styled.h2`
  line-height: 26px;
  font-size: 20px;
  font-weight: Weight;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;
`;
const InputWrapper = styled.div`
  margin-top: 1.5rem;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: hsla(216, 18%, 34%, 1);
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  line-height: 20px;
`;
const ButtonWrapper = styled.div`
  width: ${({ isWidth }) => (isWidth ? "40%" : "100%")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 2rem;
  gap: 15rem;
  @media (max-width: 820px) {
    gap: 13rem;
  }
  @media (max-width: 600px) {
    gap: 36%;
  }
`;
const Label = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ isLanguage }) =>
    isLanguage === "ar"
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"} !important;

  display: block;
  color: #344054;
`;
const RadioWrapper = styled.div`
  margin-top: 0.5rem;
  direction: ${({ isLanguage }) => isLanguage === "ar" && "ltr"};
  display: ${({ isLanguage }) => isLanguage === "ar" && "flex"};
  gap: ${({ isLanguage }) => isLanguage === "ar" && "2rem"};
  flex-direction: ${({ isLanguage }) => isLanguage === "ar" && "row-reverse"};
  .ant-radio-inner {
    margin-right: ${({ isLanguage }) =>
      isLanguage === "ar" && "0.6rem"} !important;
  }
  span {
    padding: ${({ isLanguage }) => isLanguage === "ar" && "0"}!important;
    margin: ${({ isLanguage }) => isLanguage === "ar" && "0"} !important;
    font-family: ${({ isLanguage }) =>
      isLanguage === "ar"
        ? `"Noto Sans Arabic", sans-serif`
        : "'Outfit', sans-serif"} !important;
  }
`;
const BtnWrapper = styled.div`
  width: 460px;
  height: 144px;
  border-radius: 6px;
  border: 2px dotted #98a2b3;
  background-color: #f9fafb;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.4rem;
  @media (max-width: 820px) {
    width: 460px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const UploadFileWrapper = styled.div`
  width: 144px;
  height: 48px;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  margin-top: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #344054;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
`;
const MapWrapper = styled.div`
  width: 100%;
  height: 20vh;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 1rem;
`;
const RadioButton = styled(Radio)`
  .ant-radio-checked > .ant-radio-inner {
    border-color: #c44851 !important;
    background-color: transparent !important;
  }
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #c44851 !important;
  }
`;
const Span = styled.p`
  font-size: 12px;
  color: red;
  margin-top: 5px;
`;
