// reducers.js

import { ADD_MERCHANT_FAILURE, ADD_MERCHANT_REQUEST, ADD_MERCHANT_SUCCESS } from "./types";



const initialState = {
  data: null,
  loading: false,
  error: ''
};

const addMerchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case ADD_MERCHANT_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };

    case ADD_MERCHANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default addMerchantReducer;
