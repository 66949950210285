import axios from "axios";
import {
  getCookie,
  removeAllCookie,
  setCookie,
} from "../Utils/storageVariable";
import { Error } from "../Responses/Responses";

const onError = async (error, originalRequest, repeat) => {
  if (error.response) {
    if (error.response.status === 403 || error.response.status === 401) {
      if (repeat && getCookie("token") && getCookie("refreshToken")) {
        const tokenResponse = await BaseService.post(
          "/employeeAuth/extendToken",
          {
            accessToken: getCookie("token"),
            refreshToken: getCookie("refreshToken"),
          },
          false,
          false,
          false
        );
        const { data } = tokenResponse;
        const device = data.data.device;
        setCookie("token", device.accessToken);
        setCookie("refreshToken", device.refreshToken);
        return request(originalRequest, true, false, false);
      } else {
        removeAllCookie();
        Error("Session expired!");
        window.location.href = "/auth";
        return Promise.reject(error);
      }
    }
  }

  return Promise.reject(error);
};
const request = async (options, isAuth, isMultipart, repeat) => {
  let baseURL = process.env.REACT_APP_BASE_URL;
  const token = getCookie("token");
  let headers = {};

  if (isAuth) {
    headers["Authorization"] = `${token}`;
  }
  if (isMultipart) {
    headers["Content-Type"] = "multipart/form-data";
  }
  const client = axios.create({
    baseURL,
    headers: { ...headers },
  });

  client.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  return client(options).catch((e) => onError(e, options, repeat));
};

export class BaseService {
  static get = (url, isAuth = true, isMultipart = false, repeat = true) => {
    return request(
      {
        url,
        method: "GET",
      },
      isAuth,
      isMultipart,
      repeat
    );
  };

  static post = (
    url,
    data,
    isAuth = true,
    isMultipart = false,
    repeat = true
  ) => {
    return request(
      {
        url,
        method: "POST",
        data,
      },
      isAuth,
      isMultipart,
      repeat
    );
  };

  static put = (url, data, isAuth = true, isMultipart = false) => {
    return request(
      {
        url,
        method: "PUT",
        data,
      },
      isAuth,
      isMultipart
    );
  };
  static patch = (
    url,
    data,
    isAuth = true,
    isMultipart = false,
    repeat = true
  ) => {
    return request(
      {
        url,
        method: "PATCH",
        data,
      },
      isAuth,
      isMultipart,
      repeat
    );
  };
  static remove = (url, data, isAuth = true, isMultipart = false) => {
    return request(
      {
        url,
        method: "DELETE",
        data,
      },
      isAuth,
      isMultipart
    );
  };
}
