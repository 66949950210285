// reducers.js

import { FETCH_MERCHANT_FAILURE, FETCH_MERCHANT_REQUEST, FETCH_MERCHANT_SUCCESS, UPDATE_MERCHANT } from "./types";



const initialState = {
  data: null,
  loading: false,
  error: ''
};

const GetMerchantReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_MERCHANT_SUCCESS:
      return {
        ...state,

        loading: false,
        data: action.payload,
        error: ''
      };
    case UPDATE_MERCHANT:
      return {
        ...state,
        data: { ...state?.data, ...action?.payload },
      };
    case FETCH_MERCHANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default GetMerchantReducer;
