import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowIcon } from "./Icon.svg";
import { useTranslation } from "react-i18next";
import CategoryModal from "../CategoryModal/CategoryModal";

const SelectInput = ({
  onChange,
  placeholder,
  type,
  label,
  error,
  onBlur,
  name,
  value,
  disabled,
  isDirection,
  isArrow,
  targetCustomer,
  handleCheckboxChange,
  ...rest
}) => {
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const handleArrowClick = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Wrapper
        isLanguage={i18n.language}
        isDirection={isDirection}
        onClick={handleArrowClick}
      >
        <StyledInputArrow
          isDirection={isDirection}
          isLanguage={i18n.language}
          type={type}
          value={i18n.language === "ar" ? value?.titleAr : value?.title}
          placeholder={placeholder}
          disabled={true}
          allowClear
          name={name}
          isBorder={error}
          onChange={onChange}
          onBlur={onBlur}
          {...rest}
        />
        <ArrowIcon cursor={"pointer"} />
      </Wrapper>
      <CategoryModal
        handleCheckboxChange={handleCheckboxChange}
        selectedCategory={value?.id}
        targatedCostumer={targetCustomer}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default SelectInput;

const Wrapper = styled.div`
  background-color: #ffff;
  direction: ${({ isLanguage, isDirection }) =>
    isLanguage === "ar" || (isDirection && "rtl")};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 12px 14px;
  outline: none;
  border: 1px solid ${(props) => (props.isBorder ? "red" : "#D0D5DD")}; // Check if this logic is correct
  font-family: ${({ isLanguage, isDirection }) =>
    isLanguage === "ar" || isDirection
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
  border-radius: 8px;
  margin-top: 0.4rem;
  color: #344054;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  svg {
    rotate: ${({ isLanguage }) => isLanguage === "ar" && "180deg"};
  }
`;

const StyledInputArrow = styled.input`
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 16px;
  color: #344054 !important;
  outline: none;
  width: 90%;
  margin: 0;
  font-weight: 400;
  font-family: ${({ isLanguage, isDirection }) =>
    isLanguage === "ar" || isDirection
      ? `"Noto Sans Arabic", sans-serif`
      : "'Outfit', sans-serif"};
`;
