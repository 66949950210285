import { BaseService } from "../../Api/base";
import { Error } from "../../Responses/Responses";
import { fetchProducts } from "../Product/action";
import {
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
} from "./types";
export const addProduct = (fields, nextStep = () => { }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_PRODUCT_REQUEST });
      const { data, status } = await BaseService.post("/merchants/product", {
        product: fields,
      });
      if (status === 200) {
        // dispatch(fetchProducts());
        nextStep();
      }
      dispatch({ type: ADD_PRODUCT_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message);
      dispatch({ type: ADD_PRODUCT_FAILURE, payload: error.message });
    }
  };
};
