import { BaseService } from '../../Api/base';
import { Error } from '../../Responses/Responses';
import { fetchProducts } from '../Product/action';
import { DELETE_PRODUCT_FAILURE, DELETE_PRODUCT_REQUEST, DELETE_PRODUCT_SUCCESS } from "./types";
export const deleteProduct = (value) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PRODUCT_REQUEST });
      const { data, status } = await BaseService.remove("/merchants/product", { id: value });
      if (status === 200) {
        dispatch(fetchProducts())
        // Success("Delete SuccessFully")
      }
      dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: data });
    } catch (error) {
      Error(error.message)
      dispatch({ type: DELETE_PRODUCT_FAILURE, payload: error.message });
    }
  };
};