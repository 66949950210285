import React, { useEffect, useState } from "react";
import AppRoutes from "./AppRoutes/AppRoutes";
import { getCookie } from "./Utils/storageVariable";
import i18n from "./i18n";
import "./App.css";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { createContext } from "react";

const initialValues = {
  merchant: undefined,
  merchantAdmin: undefined,
  businessHours: undefined,
  categories: undefined,
  services: undefined,
  employees: undefined
};
export const FormDataContext = createContext({
  data: initialValues,
  setData: undefined,
});
const App = () => {
  useEffect(() => {
    const lang = getCookie("language");
    i18n.changeLanguage(lang);
  }, []);

  const [data, setData] = useState({
    merchant: undefined,
  });

  return (
    <div>
      <FormDataContext.Provider
        value={{
          data,
          setData,
        }}
      >
        <AppRoutes />
      </FormDataContext.Provider>
    </div>
  );
};

export default App;
